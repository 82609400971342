import Enumerable from "linq";

export interface MeetingData {
    id: number;
    component: string;
    title: string;
    date: Date;
    time: Date;
    imgSrc: string;
    registerLink: string;
    presenters: Presenter[];
    content: string;
}
export interface Presenter {
    name: string;
    details: string;
}

export interface IMeetingDatabase {
    id: number | undefined;
    meetingType: number;
    expires: string;
    data: string;
}

export function convertMeetingData(meeting: MeetingData): IMeetingDatabase {
    let id = undefined;
    if (meeting.id >= 0) {
        id = meeting.id;
    }
    return {
        id: id,
        meetingType: meeting.component === "SinglePresenterMeeting" ? 0 : 1,
        data: JSON.stringify(meeting),
        expires: '@' + Math.round(meeting.date.getTime() / 1000)
    }
}

export function compareMeetingChanges(original: MeetingData[], edited: MeetingData[]) {
    if (original.length !== edited.length) {
        return false;
    }
    const enumerated = Enumerable.from(edited);
    if (enumerated.any(x => x.id === null)) {
        return true;
    }
    const sortedEdited = edited.sort((a, b) => a.id! - b.id!);

    for (let i = 0; i < original.length; i++) {
        const originalItem = original[i];
        const editedItem = sortedEdited[i];
        if (originalItem.id !== editedItem.id) {
            return false;
        }
        if (originalItem.title !== editedItem.title)
            return false;
        if (originalItem.component !== editedItem.component)
            return false;
        if (originalItem.date.toDateString() !== editedItem.date.toDateString())
            return false;
        if (originalItem.time.toTimeString() !== editedItem.time.toTimeString())
            return false;
        if (originalItem.registerLink !== editedItem.registerLink)
            return false;
        if (originalItem.imgSrc !== editedItem.imgSrc)
            return false;
        if (originalItem.content !== editedItem.content)
            return false;
        if (originalItem.presenters.length !== editedItem.presenters.length)
            return false;
        for (let j = 0; j < originalItem.presenters.length; j++) {
            const originalPresenter = originalItem.presenters[j];
            const editedPresenter = editedItem.presenters[j];
            if (originalPresenter.name !== editedPresenter.name)
                return false;
            if (originalPresenter.details !== editedPresenter.details)
                return false;
        }
    }
    return true;
}
