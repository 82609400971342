import { useRef } from 'react';
import {
	bundleIcon, iconFilledClassName, iconRegularClassName,
	NavigationRegular, NavigationFilled,
	PersonRegular, PersonFilled,
	Home20Regular, Home20Filled, People20Regular, People20Filled, ConferenceRoom20Regular, ConferenceRoom20Filled, PeopleTeam20Regular, PeopleTeam20Filled,
	HatGraduation20Regular, HatGraduation20Filled, BuildingFactory20Regular, BuildingFactory20Filled,
	News20Regular, News20Filled, Briefcase20Regular, Briefcase20Filled, Link20Regular, Link20Filled,
	DocumentBriefcase20Regular, DocumentBriefcase20Filled, Library20Regular, Library20Filled,
	ArrowUpload20Regular, ArrowUpload20Filled, Archive20Regular, Archive20Filled, Money20Regular, Money20Filled
} from '@fluentui/react-icons';
import React, { useEffect, FC } from 'react';
import './App.css';
import { Outlet, useLocation, useNavigate, useNavigation } from 'react-router-dom';
import LinkRouter from './LinkRouter';

import { loginAsync, logoutAsync } from './oauth/oauth';
import { isAuthorized$, userId$, registerLoginSwitch } from './oauth/oauth';
import { isAdmin$ } from './store/userStore';
import { bind } from "@react-rxjs/core"
import {
	Divider, Menu, MenuTrigger, MenuPopover, MenuList, MenuItem, MenuGroup, MenuGroupHeader, MenuDivider,
	Button, Input, Label, makeStyles, shorthands, tokens, Link, Image, MenuItemCheckbox, MenuProps,
	Field,
	Switch,
	Tooltip
} from "@fluentui/react-components";
import {
	Toaster,
	useId,
	useToastController,
	Toast,
	ToastTitle,
	ToastBody
} from "@fluentui/react-components";
import {
	Dialog,
	DialogTrigger,
	DialogSurface,
	DialogTitle,
	DialogBody,
	DialogActions,
	DialogContent
} from "@fluentui/react-components";
import { Drawer, DrawerProps } from '@fluentui/react-components/unstable';
import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbDivider
} from "@fluentui/react-breadcrumb-preview";
import OneSignal from 'react-onesignal';
import { OneSignalAppId } from './constants';
import { ToastContext } from './context/ToastContext';
import { AdminContext } from './context/AdminContext';
import {
	Hamburger,
	NavCategory,
	NavCategoryItem,
	NavDivider,
	NavDrawer,
	NavDrawerBody,
	NavDrawerHeader,
	NavDrawerProps,
	NavItem,
	NavSectionHeader,
	NavSubItem,
	NavSubItemGroup,
} from "@fluentui/react-nav-preview";


type DrawerType = Required<DrawerProps>["type"];

interface LayoutProps {
}

const PersonIcon = bundleIcon(PersonFilled, PersonRegular);

const Home = bundleIcon(Home20Filled, Home20Regular);
const People = bundleIcon(People20Filled, People20Regular);
const ConferenceRoom = bundleIcon(ConferenceRoom20Filled, ConferenceRoom20Regular);
const PeopleTeam = bundleIcon(PeopleTeam20Filled, PeopleTeam20Regular);
const HatGraduation = bundleIcon(HatGraduation20Filled, HatGraduation20Regular);
const BuildingFactory = bundleIcon(BuildingFactory20Filled, BuildingFactory20Regular);
const News = bundleIcon(News20Filled, News20Regular);
const Briefcase = bundleIcon(Briefcase20Filled, Briefcase20Regular);
const LinkIcon = bundleIcon(Link20Filled, Link20Regular);
const DocumentBriefcase = bundleIcon(DocumentBriefcase20Filled, DocumentBriefcase20Regular);
const Library = bundleIcon(Library20Filled, Library20Regular);
const ArrowUpload = bundleIcon(ArrowUpload20Filled, ArrowUpload20Regular);
const Archive = bundleIcon(Archive20Filled, Archive20Regular);
const Money = bundleIcon(Money20Filled, Money20Regular);


const drawerWidth = 240;
const bannerHeight = 40;

const breadcrumbNameMap: { [key: string]: string } = {
	'/college': 'College Sponsors',
	'/college/payment': 'College Sponsorship',
	'/industrial': 'Industrial Sponsors',
	'/industrial/payment': 'Online Payment',
	'/membership': 'Membership',
	'/membership/mailpayment': 'Mail Payment',
	'/membership/onlinepayment': 'Online Payment',
	'/meetings': 'Meetings',
	'/archives': 'Archives',
	'/listmembers': 'Membership List',
	'/newsletter': 'Newsletter',
	'/officers': 'Officers',
	'/memberInfo': 'Account Information',
	'/jobs': 'Jobs',
	'/links': 'Links',
	'/forms': 'Forms and Files',
	'/resources': 'Shared Resources',
	'/upload': 'Upload Resource',
	'/transactions': 'My Transactions',
	'/admin': 'Admin',
	'/admin/notifications': 'Notifications'
};
const ABSOLUTE_URL_REGEX = /^(?:[a-z][a-z0-9+.-]*:|\/\/)/i;
const [useIsAuthorized] = bind(isAuthorized$, isAuthorized$.value);
const [useUserId] = bind(userId$, userId$.value);
const [useIsAdmin] = bind(isAdmin$, false);

const size = 'small';

const useIconStyles = makeStyles({
	icon: {
		':hover': {
			[`& .${iconRegularClassName}`]: {
				display: 'none',
			},
			[`& .${iconFilledClassName}`]: {
				display: 'inline',
			},
		},
	},
});



const usePageStyles = makeStyles({
	root: {
		display: "flex",
		flexDirection: "row",
		// backgroundColor: "#fff",
		// height: '100vh',
		// width: '100vw',
		height: '100%',
		width: '100%',
		...shorthands.overflow('hidden', 'hidden')
	},
	nav: {

		//position: 'relative',

		zIndex: 1,
		'::before': {
			zIndex: -1,
			position: 'absolute',
			left: '0px',
			width: '100%',
			top: 0,
			mask: 'linear-gradient(to bottom, rgba(255,255,255,1.0) 0%, rgba(255,255,255,0.8) 20%, rgba(255,255,255,0.0) 100%)',
			content: "url(https://2yc3.org/images/menubkg.jpg)",
			backgroundSize: 'contain',

		}
	},
	navHeader: {
		height: '170px'
	},
	navItem: {
		backgroundColor: 'transparent',
		'::hover': {
			backgroundColor: 'var(--colorNeutralBackground4Hover)',
			opacity: 0.3
		}
	},

	// drawerRoot: {
	// 	// width: drawerWidth + 'px',
	// 	height: '100vh',
	// 	...shorthands.overflow('hidden', 'auto')
	// },
	// drawerLogoContent: {
	// 	display: 'flex',
	// 	flexDirection: 'row',
	// 	alignItems: 'center'
	// },
	mainArea: {
		flex: '1',
		display: "flex",
		flexDirection: "column",
		height: '100vh'
	},
	banner: {
		width: '100%',
		height: bannerHeight + 'px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	bannerSideLeft: {
		display: 'flex',
		flexDirection: 'row',
		...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalS),
	},
	bannerSideRight: {
		display: 'flex',
		flexDirection: 'row-reverse',
		flexWrap: 'wrap',
		maxHeight: '32px',
		rowGap: '20px',
		...shorthands.overflow('hidden'),
		...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalS),
	},
	bannerSideItem: {
		...shorthands.margin(tokens.spacingVerticalNone, tokens.spacingHorizontalM),
	},
	logoIcon: {
		height: '32px',

	},
	content: {
		paddingTop: '1em',
		height: `calc( 100vh - ${bannerHeight}px)`,
		//width: `calc(100vw - ${drawerWidth - 20}px)`,
		...shorthands.padding('10px'),
		overflow: 'auto auto',
		//...shorthands.overflow('auto', 'auto'),
		//...shorthands.margin(tokens.spacingVerticalXL, tokens.spacingHorizontalXL),
		//...shorthands.flex(1),
		flex: '1',
		gridRowGap: tokens.spacingVerticalXXL,
	},
	contentFull: {
		paddingTop: '1em',
		...shorthands.padding('10px'),
		height: `calc( 100vh - ${bannerHeight}px)`,
		//width: `calc(100vw - ${20}px)`,
		overflow: 'auto auto',
		//...shorthands.overflow('auto', 'auto'),
		//...shorthands.margin(tokens.spacingVerticalXL, tokens.spacingHorizontalXL),
		//...shorthands.flex(1),
		flex: '1',

		gridRowGap: tokens.spacingVerticalXXL,
	},
	dialogContent: {
		display: 'flex',
		flexDirection: 'column',
		rowGap: "10px",
	}
});

const Layout: FC<LayoutProps> = (props) => {

	const [isOpen, setIsOpen] = React.useState(true);
	const [loginOpen, setLoginOpen] = React.useState(false);
	const [authorizedError, setAuthorizedError] = React.useState("");
	const [type, setType] = React.useState<DrawerType>("inline");

	registerLoginSwitch(setLoginOpen);

	const authorized = useIsAuthorized();
	const userId = useUserId();
	const isAdmin = useIsAdmin();

	const navigate = useNavigate();
	const navigation = useNavigation();

	const usernameRef = useRef<HTMLInputElement>(null);
	const passwordRef = useRef<HTMLInputElement>(null);

	const [navItemValue, setNavItemValue] = React.useState<number>(1);
	const [navCategoryValue, setNavCategoryValue] = React.useState<number>(1);

	const [isAdminMode, setIsAdminMode] = React.useState<boolean>(false);

	const [checkedValues, setCheckedValues] = React.useState<Record<string, string[]>>({ userDropdown: [] });
	const onChange: MenuProps["onCheckedValueChange"] = (e, { name, checkedItems }) => {
		if (checkedItems.includes('notifyOn')) {
			OneSignal.User.PushSubscription.optIn();
		} else {
			OneSignal.User.PushSubscription.optOut();
		}
		setCheckedValues((s) => {
			return s ? { ...s, [name]: checkedItems } : { [name]: checkedItems };
		});
	};

	useEffect(() => {
		if (userId !== '') {
			OneSignal.init({
				appId: OneSignalAppId!,
				allowLocalhostAsSecureOrigin: true,
				serviceWorkerParam: { scope: '/pushScope' },
			}).then(() => {
				OneSignal.login(userId);
				console.log(OneSignal.User.PushSubscription);
				if (OneSignal.User.PushSubscription.optedIn) {
					setCheckedValues({ userDropdown: ['notifyOn'] });
				} else {
					setCheckedValues({ userDropdown: [] });
				}
			});
		} else {
			OneSignal.logout();
			setCheckedValues({ userDropdown: [] });
		}
	}, [userId]);

	const handleLogout = async () => {
		setLoginOpen(false);
		//handleClose();

		await logoutAsync();
	};


	const handleLoginSubmit = async () => {
		const username = usernameRef.current?.value;
		const password = passwordRef.current?.value;

		const success = await loginAsync(username, password);
		if (!success) {
			setAuthorizedError("Invalid username or password");
			notifyError(authorizedError);
		}
	};

	const onMediaQueryChange = React.useCallback(
		({ matches }: MediaQueryListEvent) => {
			setType(matches ? "overlay" : "inline");
			setIsOpen(matches ? false : true);
		},
		[setType]
	);

	// Merge of componentDidMount and componentDidUpdate
	useEffect(() => {
		console.log("Mobile open: " + isOpen);
	}, [isOpen]);

	React.useEffect(() => {
		const match = window.matchMedia("(max-width: 720px)");

		if (match.matches) {
			setType("overlay");
			setIsOpen(false);
		}

		match.addEventListener("change", onMediaQueryChange);

		return () => match.removeEventListener("change", onMediaQueryChange);
	}, [onMediaQueryChange]);

	const handleDrawerToggle = (open?: boolean) => {
		if (type === "inline") {
			return;
		} else {
			if (open !== undefined) {
				setIsOpen(open);
			} else {
				setIsOpen(!isOpen);
			}
		}

	};

	//const generalStyles = useGeneralStyles();
	const pageStyles = usePageStyles();
	const iconStyles = useIconStyles();

	const toasterId = useId("toaster");

	const { dispatchToast } = useToastController(toasterId);

	const notifyError = (errorMsg: string) =>
		dispatchToast(
			<Toast >
				<ToastTitle>Error</ToastTitle>
				<ToastBody >
					{errorMsg}
				</ToastBody>
			</Toast>,
			{ intent: "error" }
		);

	const notifySaveSuccess = (successMessage: string) =>
		dispatchToast(
			<Toast >
				<ToastTitle>Save Successful</ToastTitle>
				<ToastBody >
					{successMessage}
				</ToastBody>
			</Toast>,
			{ intent: "success" }
		);

	const location = useLocation();
	const pathnames = location.pathname.split('/').filter((x) => x);

	const input1Id = useId("input1");
	const input2Id = useId("input2");

	const renderHamburgerWithToolTip = () => {
		return (
			<Tooltip content="Navigation" relationship="label">
				<Hamburger onClick={() => setIsOpen(!isOpen)} />
			</Tooltip>
		);
	};

	const createNavItem = (value: number, children: any, href: string, icon?: any) => {

		function handleClick(
			event: React.MouseEvent<HTMLElement, MouseEvent>
		) {
			navigate(href);
		}

		return (
			<NavItem className={pageStyles.navItem} as='a' value={value} icon={icon} onClick={handleClick} key={value}>
				{children}
			</NavItem>
		)
	}

	useEffect(() => {
		const initialPath = location.pathname;
		if (initialPath.length > 1) {
			const navItem = navItems.find(x => x.route === initialPath.substring(1));
			if (navItem) {
				setNavItemValue(navItems.indexOf(navItem) + 1);
				// const subItems = navItems.filter(x => x.section === navItem.section);
				// setNavItemValue(subItems.indexOf(navItem) + 1);
			}
		} else if (initialPath === '/') {
			const navItem = navItems.find(x => x.route === '/');
			if (navItem) {
				const subItems = navItems.filter(x => x.section === navItem.section);
				setNavItemValue(subItems.indexOf(navItem) + 1);
			}
		}
	}, [location]);

	const navItems = [
		{ name: 'Home', route: '/', icon: <Home />, section: 0 },
		{ name: 'Membership', route: 'membership', icon: <People />, section: 0 },
		{ name: 'Meetings', route: 'meetings', icon: <ConferenceRoom />, section: 0 },
		{ name: 'Officers', route: 'officers', icon: <PeopleTeam />, section: 0 },
		{ name: 'College Sponsors', route: 'college', icon: <HatGraduation />, section: 0 },
		{ name: 'Industry Sponsors', route: 'industrial', icon: <BuildingFactory />, section: 0 },
		{ name: 'Newsletter', route: 'newsletter', icon: <News />, section: 0 },
		{ name: 'Jobs', route: 'jobs', icon: <Briefcase />, section: 0 },
		{ name: 'Links', route: 'links', icon: <LinkIcon />, section: 0 },
		{ name: 'Forms & Files', route: 'forms', icon: <DocumentBriefcase />, section: 0 },
		{ name: 'Shared Resources', route: 'resources', icon: <Library />, section: 0 },

		{ name: 'Upload Resources', route: 'upload', icon: <ArrowUpload />, section: 1 },
		{ name: 'Archives', route: 'archives', icon: <Archive />, section: 1 },
		{ name: 'My Transactions', route: 'transactions', icon: <Money />, section: 1 },

		{ name: 'Notifications', route: 'admin/notifications', icon: <Briefcase />, section: 2 },
		{ name: 'Payment Audit', route: 'admin/paymentaudit', icon: <Briefcase />, section: 2 }
	];

	return (
		<>
			<div className={pageStyles.root} >
				<AdminContext.Provider value={isAdminMode}>
					<ToastContext.Provider value={{ toasterId: toasterId, notifyError: notifyError, notifySaveSuccess: notifySaveSuccess }}>
						<NavDrawer
							open={isOpen}
							type={type}
							size={'medium'}
							selectedValue={navItemValue}
							defaultSelectedCategoryValue={1}
							onClick={() => handleDrawerToggle(false)}
							className={pageStyles.nav}
						>
							<NavDrawerHeader className={pageStyles.navHeader}>
								{renderHamburgerWithToolTip()}

							</NavDrawerHeader>
							<NavDrawerBody>
								{navItems.filter(x => x.section == 0).map((v, index) => (
									createNavItem(index + 1, v.name, v.route, v.icon)
								))}

								{authorized && (<>
									<NavDivider />
									<NavSectionHeader >Member's Area</NavSectionHeader>
									{navItems.filter(x => x.section == 1).map((v, index) => (
										createNavItem(index + 1 + navItems.filter(x => x.section === 0).length, v.name, v.route, v.icon)
									))}
								</>)}
								{isAdmin && (
									<>
										<NavDivider />
										<NavSectionHeader>Admin Area</NavSectionHeader>
										<Switch label='Admin Mode' checked={isAdminMode}
											onChange={e => { console.log(!isAdminMode); setIsAdminMode(!isAdminMode) }} />
										{navItems.filter(x => x.section == 2).map((v, index) => (
											createNavItem(index + 1 + navItems.filter(x => x.section !== 2).length, v.name, v.route, v.icon)
										))}
									</>
								)}
							</NavDrawerBody>
						</NavDrawer>


						<div className={pageStyles.mainArea}>
							<div className={pageStyles.banner} id='banner'>
								<div className={pageStyles.bannerSideLeft}>
									{!isOpen && renderHamburgerWithToolTip()}
									<Breadcrumb aria-label="navigation breadcrumb" className={pageStyles.bannerSideItem}>
										<LinkRouter as="BreadcrumbLink" href="/">2YC3</LinkRouter>

										{pathnames.map((value, index) => {
											const last: boolean = index === pathnames.length - 1;
											const to = `/${pathnames.slice(0, index + 1).join('/')}`;

											return (
												<React.Fragment key={value}>
													<BreadcrumbDivider />
													{last ? (
														<BreadcrumbItem >{breadcrumbNameMap[to]}</BreadcrumbItem>
													) : (
														<LinkRouter as="BreadcrumbLink" href={to}>{breadcrumbNameMap[to]}</LinkRouter>
													)
													}
												</React.Fragment>

											);
										})}
									</Breadcrumb>
								</div>
								<div className={pageStyles.bannerSideRight}>


									<div className={pageStyles.bannerSideItem}>
										{authorized ?

											<Menu hasCheckmarks checkedValues={checkedValues} onCheckedValueChange={onChange}>
												<MenuTrigger>
													<Button className={iconStyles.icon} icon={<PersonIcon />} >{userId}</Button>
												</MenuTrigger>
												<MenuPopover>
													<MenuList >
														<LinkRouter as="MenuItemLink" href="memberInfo">
															My account
														</LinkRouter>
														<MenuItemCheckbox value='notifyOn' name='userDropdown'>Notifications</MenuItemCheckbox>
														<MenuDivider />
														<MenuItem onClick={handleLogout}>Logout</MenuItem>
													</MenuList>
												</MenuPopover>
											</Menu>


											:

											<Dialog open={loginOpen} onOpenChange={(event, data) => setLoginOpen(data.open)}>
												<DialogTrigger disableButtonEnhancement>
													<Button>Login</Button>
												</DialogTrigger>
												<DialogSurface>
													<DialogBody>
														<DialogTitle>Login</DialogTitle>
														<DialogContent>
															<div className={pageStyles.dialogContent}>
																Enter your login and password here to gain access to the member's areas.
																<DialogTrigger disableButtonEnhancement>
																	<Link  >Forgot password?</Link>
																</DialogTrigger>
																<Label htmlFor={input1Id}>Username</Label>
																<Input
																	autoFocus
																	id={input1Id}
																	type="text"
																	ref={usernameRef}
																	required
																/>
																<Label htmlFor={input2Id}>Password</Label>
																<Input
																	autoFocus
																	id={input2Id}
																	type="password"
																	ref={passwordRef}
																	required
																/>
															</div>
														</DialogContent>
														<DialogActions>

															<DialogTrigger disableButtonEnhancement>
																<Button appearance='secondary' >Cancel</Button>
															</DialogTrigger>
															<DialogTrigger disableButtonEnhancement>
																<Button appearance='primary' onClick={handleLoginSubmit}>Submit</Button>
															</DialogTrigger>
														</DialogActions>
													</DialogBody>
												</DialogSurface>
											</Dialog>
										}
									</div>

									<Link href="https://www.acs.org" target="_blank" className={pageStyles.bannerSideItem}>
										<Image fit='contain' shape='rounded' shadow={true} className={pageStyles.logoIcon} alt="ACS logo" src='/images/acs.png' />
									</Link>

									<Link href="https://www.divched.org" target="_blank" className={pageStyles.bannerSideItem}>
										<Image fit='contain' shape='rounded' shadow={true} className={pageStyles.logoIcon} alt="DivCHED logo" src='/images/ched.png' />
									</Link>

								</div>
							</div>

							<main className={(!isOpen || type === "overlay") ? pageStyles.contentFull : pageStyles.content}
							>
								{/* <Toolbar /> */}
								<Outlet />
							</main>
						</div>
					</ToastContext.Provider>
				</AdminContext.Provider>
			</div >
			<Toaster toasterId={toasterId} />
		</>
	);
}

export default Layout;
