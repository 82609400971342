import React, { FC } from 'react';
import { bind } from '@react-rxjs/core';
import { isAuthorized$, userId$ } from '../oauth/oauth';

import { Link, Button, Text, Card, CardHeader, CardFooter, Avatar, makeStyles } from '@fluentui/react-components';
import { InteractionTag, InteractionTagPrimary } from "@fluentui/react-tags-preview";
import Rating2 from './Rating2'

import { LinkRegular as LinksIcon } from '@fluentui/react-icons';
import { VideoRegular as VideosIcon } from '@fluentui/react-icons';
import { DocumentRegular as DocumentIcon } from '@fluentui/react-icons';
import { BeakerRegular as LabsIcon } from '@fluentui/react-icons';
import { LearningAppRegular as TeachingOnlineIcon } from '@fluentui/react-icons';
import { LightbulbFilamentRegular as ActiveLearningIcon } from '@fluentui/react-icons';

import { AirplaneTakeOffRegular as IntroIcon } from '@fluentui/react-icons';
import { BookPulseRegular as GeneralIcon } from '@fluentui/react-icons';
import { BookStarRegular as GOBIcon } from '@fluentui/react-icons';
import { GasPumpRegular as OrganicIcon } from '@fluentui/react-icons';
import { MicroscopeRegular as BiochemIcon } from '@fluentui/react-icons';
import { ArrowSwapRegular as TransferIcon } from '@fluentui/react-icons';
import { BriefcaseRegular as ProfDevIcon } from '@fluentui/react-icons';
import { ScalesRegular as EquityIcon } from '@fluentui/react-icons';
import { LeafOneRegular as SustainIcon } from '@fluentui/react-icons';
import { PeopleCommunityRegular as AllInclusiveIcon } from '@fluentui/react-icons';

import { Beaker24Regular as EmptyFlaskIcon } from '@fluentui/react-icons';
import { Beaker24Filled as FilledFlaskIcon } from '@fluentui/react-icons';

import { LinkDismissRegular as BrokenLinkIcon } from '@fluentui/react-icons';
import { MoneyDismissRegular as SpamIcon } from '@fluentui/react-icons';
import { StarDismissRegular as InappropriateIcon } from '@fluentui/react-icons';

import { phpServer } from '../constants';
import { useGeneralStyles } from '../styles';

interface ResourceItemProps {
    link: string;
    id: number;
    title: string;
    description: string;
    category: string;
    series: string;
    //rating: number;
    averageRating: number;

    intro: boolean;
    gen: boolean;
    gob: boolean;
    org: boolean;
    bio: boolean;
    trans: boolean;
    pro: boolean;
    equ: boolean;
    sus: boolean;
    inter: boolean;
}

// const MediaOnlyScreenQueryForMinWidth = (breakpoint: number): string => `@media only screen and (min-width: ${breakpoint}px)`;
const usePageStyles = makeStyles({
    warnButton: {
        // [MediaOnlyScreenQueryForMinWidth(0)]:{
        //     minWidth:'32px',

        // },
        // [MediaOnlyScreenQueryForMinWidth(640)]:{

        // }
    },
    warnSpan: {
        // [MediaOnlyScreenQueryForMinWidth(0)]:{
        //     display:'none',
        // },
        // [MediaOnlyScreenQueryForMinWidth(640)]:{
        //     display:'block'
        // }
    }
});


const [useIsAuthorized] = bind(isAuthorized$, isAuthorized$.value);
const [useUserId] = bind(userId$, userId$.value);

const ResourceItem: FC<ResourceItemProps> = (props) => {

    const authorized = useIsAuthorized();
    const userId = useUserId();

    //const matchesWidth = useMediaQuery('(min-width:520px)');

    const getIcon = (category: string) => {
        switch (category) {
            case 'link':
                return <LinksIcon />
            case 'video':
                return <VideosIcon />
            case 'document':
                return <DocumentIcon />
            case 'lab':
                return <LabsIcon />
            case 'online':
                return <TeachingOnlineIcon />
            case 'active':
                return <ActiveLearningIcon />
            default:
                return <DocumentIcon />
        }
    };

    const onRatingChange = (value: number | null) => {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');

        let body: any = {};
        if (authorized) {
            body.memberID = userId;
        }
        body.resourceID = props.id;
        body.rating = value;

        fetch(phpServer + 'submitResourceRating.php', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        });
    }

    const StyledTag = (props: any) => (
        <InteractionTag style={{ margin: '2px' }} shape="circular" size="small">
            <InteractionTagPrimary icon={props.icon} >{props.label}</InteractionTagPrimary>
        </InteractionTag>
    );

    const generalStyles = useGeneralStyles();
    const pageStyles = usePageStyles();

    return (
        <>
            <Card style={{ margin: '5px' }} onClick={(e) => window.open(props.link, '_blank')}>
                <CardHeader
                    header={<Text className={generalStyles.cardTitle}>{props.title}</Text>}
                    image={<Avatar icon={getIcon(props.category)}></Avatar>}
                    description={
                        props.series ? <Link href="https://www.google.com" rel="noreferrer" target="_blank" onClick={e => e.stopPropagation()}>{props.series}</Link> : null
                    }
                />

                <div>
                    <Text className={generalStyles.body2}>{props.description}</Text>
                    <br />
                    <br/>
                    {props.intro && <StyledTag label='Intro' icon={<IntroIcon />} />}
                    {props.gen && <StyledTag label='General' icon={<GeneralIcon />} />}
                    {props.gob && <StyledTag label='GOB' icon={<GOBIcon />} />}
                    {props.org && <StyledTag label='Organic' icon={<OrganicIcon />} />}
                    {props.bio && <StyledTag label='Biochemistry' icon={<BiochemIcon />} />}
                    {props.trans && <StyledTag label='Transferability' icon={<TransferIcon />} />}
                    {props.pro && <StyledTag label='Professional Development' icon={<ProfDevIcon />} />}
                    {props.equ && <StyledTag label='Equity' icon={<EquityIcon />} />}
                    {props.sus && <StyledTag label='Sustainability' icon={<SustainIcon />} />}
                    {props.inter && <StyledTag label='Interdisciplinary' icon={<AllInclusiveIcon />} />}

                </div>
                <CardFooter>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', width:'100%'  }}>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <Text className={generalStyles.groupTitle} style={{marginRight:'10px'}}>Rating</Text>
                            <Rating2
                                fractions={1}
                                initialRating={props.averageRating}
                                fullSymbol={<FilledFlaskIcon color="red" />}
                                emptySymbol={<EmptyFlaskIcon color="red" />}
                                onChange={onRatingChange}
                            />
                        </div>
                        <Text className={generalStyles.caption} >Average: {props.averageRating}</Text>
                    
                        <div className={generalStyles.body1}>
                            <Button className={pageStyles.warnButton} size="small" appearance="subtle" icon={<BrokenLinkIcon />}><span className={pageStyles.warnSpan}>Broken Link</span></Button>
                            <Button className={pageStyles.warnButton} size="small" appearance="subtle" icon={<SpamIcon />}><span className={pageStyles.warnSpan}>Spam</span></Button>
                            <Button className={pageStyles.warnButton} size="small" appearance="subtle" icon={<InappropriateIcon />}><span className={pageStyles.warnSpan}>Inappropriate</span></Button>
                        </div>
                    </div>
                </CardFooter>



            </Card>

        </>
    );
};

export default ResourceItem;
