//export const phpServer = 'http://localhost:4000/';
//export const OneSignalAppId = '4e7dd362-6dfb-44ce-b0dd-ff8f3c6c4aa5';  // localhost:3001

// export const phpServer = 'http://beta.2yc3.org/php/';
// export const OneSignalAppId = '853c07ca-4bd5-49c3-8d68-7427b17e6006'; // 2yc3.org

export const phpServer = process.env.REACT_APP_PHPSERVER; //'https://2yc3.org/php/';
export const OneSignalAppId = process.env.REACT_APP_ONESIGNALAPPID;//'853c07ca-4bd5-49c3-8d68-7427b17e6006'; // 2yc3.org


