import React, { FC } from 'react';
import { useQuery } from '@tanstack/react-query'
import { Table, TableHeader, TableRow, TableHeaderCell, TableBody, TableCell,  Text } from '@fluentui/react-components';
import { bind } from '@react-rxjs/core';
import { isAuthorized$ } from './oauth/oauth';
import { useGeneralStyles } from './styles';


interface ArchivesProps {

}

interface ArchiveData {
	year: string;
	program: string;
	archives: string;
	location: string;
}

const [useIsAuthorized] = bind(isAuthorized$, isAuthorized$.value);

// const usePageStyles = makeStyles({
// 	// tableRow: {
// 	// 	":last-child td":{

// 	// 	}
// 	// }
// });

const Archives: FC<ArchivesProps> = () => {

	const authorized = useIsAuthorized();

	const { data: archiveJson } = useQuery({queryKey: ['archiveData'], refetchOnWindowFocus: true, queryFn: async () => {
		const res = await fetch("/data/archives.json");
		if (!res.ok) {
			throw new Error('Network response was not ok')
		}
		const archiveJson: ArchiveData[] = await res.json();

		return archiveJson;
	}});

	//const pageStyles = usePageStyles();
	const generalStyles = useGeneralStyles();

	if (!authorized) {
		return <div>Hey, you're not authorized to see this page.</div>
	}

	return (
		<>
			<div>
				<Text className={generalStyles.caption} style={{ textAlign: 'center' }}>
					A = Agenda, C = COCTYC Minutes, G = General Membership Minutes, R = Retreat, AR = Annual Report<br></br>
					N = Newsletter, P = Proceedings, AB = Agenda Book, T = Treasurer's Report, M = Membership Report
				</Text>
			</div>
			{archiveJson === undefined ? <></> :

				<Table aria-label={"Archive of meetings"}>
					<TableHeader>
						<TableRow style={{ width: '100%' }}>
							<TableHeaderCell>Year</TableHeaderCell>
							<TableHeaderCell>Program</TableHeaderCell>
							<TableHeaderCell>Archives</TableHeaderCell>
							<TableHeaderCell>Location</TableHeaderCell>
						</TableRow>
					</TableHeader>
					<TableBody>
						{archiveJson.map((v, i) => {
							return (
								<TableRow key={i}>
									<TableCell>
										{v.year}
									</TableCell>
									<TableCell ><div dangerouslySetInnerHTML={{ __html: v.program }}></div></TableCell>
									<TableCell ><div dangerouslySetInnerHTML={{ __html: v.archives }}></div></TableCell>
									<TableCell >{v.location}</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			}


		</>
	);
};

export default Archives;
