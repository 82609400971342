import React, { FC } from 'react';
import {QueryClient } from '@tanstack/react-query'
import {PersistQueryClientProvider} from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'

import './App.css';
import Layout from './Layout';
//import { useAuth } from 'react-oidc-context';
import { FluentProvider, webLightTheme } from '@fluentui/react-components';

 //'http://beta.2yc3.org/'; //

interface AppProps {

}
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

const syncStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
})


const App: FC<AppProps> = () => {
  //const auth = useAuth();

 
  return (
    <FluentProvider theme={webLightTheme}>
        <PersistQueryClientProvider client={queryClient} persistOptions={{persister: syncStoragePersister}}>
          <Layout />
        </PersistQueryClientProvider>
    </FluentProvider>
  );
}

export default App;
