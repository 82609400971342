import React, { FC } from 'react';
import { QueryClient, useQuery } from '@tanstack/react-query'
import { Link, Text } from '@fluentui/react-components';
import { Alert } from '@fluentui/react-components/unstable';
import { useGeneralStyles } from './styles';


interface LinksProps {

}
export const queryClient = new QueryClient();

interface LinksData {
	component: string;
	link: string;
	content: string;
}

const Links: FC<LinksProps> = () => {

	const { data: linksData } = useQuery({queryKey: ['linksJson'],refetchOnWindowFocus: true , queryFn: async () => {
		const res = await fetch("/data/links.json");
		if (!res.ok) {
			throw new Error('Network response was not ok')
		}
		const linksData: LinksData[] = await res.json();

		return linksData;
	}});

	const generalStyles = useGeneralStyles();


	return (
		<>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<div>
					{linksData === undefined ? (<></>) : linksData.map((v, i) => {
						switch (v.component) {
							case "DefaultLink":
								return (
									<div key={i} {...v} style={{ marginBottom: '10px' }}>
										<Link href={v.link} target='_blank' rel="noreferrer">
											<Text className={generalStyles.body2} style={{ wordBreak: 'break-word' }}>{v.content}</Text>
										</Link>
									</div>
								);
							default:
								return "NO MATCH";
						}
					})}
				</div>
				<Alert intent="info">
					<Text className={generalStyles.body2}>
						Send suggestions for links to <Link href="mailto:webmaster@2yc3.org?subject=Job%20Posting%20Request">webmaster@2yc3.org</Link>
					</Text>
				</Alert>
			</div>

		</>
	);
};

export default Links;
