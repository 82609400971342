import {  FC } from 'react';
import { QueryClient, useQuery } from '@tanstack/react-query'
import { Link, Text } from '@fluentui/react-components';
import { Alert } from '@fluentui/react-components/unstable';
import DefaultJobPosting from './jobs/DefaultJobPosting';
import { useGeneralStyles } from './styles';


interface JobsProps {

}
export const queryClient = new QueryClient();

interface JobsData {
	component: string;
	title: string;
	link: string;
	content: string;
	expires?: Date;
}

const Jobs: FC<JobsProps> = () => {

	const { data: jobsJson } = useQuery({queryKey: ['jobsJson'],refetchOnWindowFocus: true, queryFn: async () => {
		const res = await fetch("/data/jobs.json");
		if (!res.ok) {
			throw new Error('Network response was not ok')
		}
		const jobsJson: JobsData[] = await res.json();

		return jobsJson;
	}});

	const generalStyles = useGeneralStyles();

	return (
		<>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				{jobsJson === undefined ? (<></>) : jobsJson.map((v, i) => {
					if (v.expires !== undefined && new Date(v.expires) < new Date()) {
						return (<></>);
					}
					switch (v.component) {
						case "DefaultJobPosting":
							return (<DefaultJobPosting key={i} {...v} />);
						default:
							return "NO MATCH";
					}
				})}

					<Alert intent="info">
						<Text className={generalStyles.body2}>
						Please send job postings to <Link href="mailto:webmaster@2yc3.org?subject=Job%20Posting%20Request">webmaster@2yc3.org</Link>. (Sponsors only, please.)
						</Text>
					</Alert>
			</div>

		</>
	);
};

export default Jobs;
