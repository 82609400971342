import {AddSquareMultipleRegular as AllIcon} from '@fluentui/react-icons';
import {LinkRegular as LinksIcon} from '@fluentui/react-icons';
import {VideoRegular as VideosIcon} from '@fluentui/react-icons';
import {DocumentRegular as DocumentIcon} from '@fluentui/react-icons';
import {BeakerRegular as LabsIcon} from '@fluentui/react-icons';
import {LearningAppRegular as TeachingOnlineIcon} from '@fluentui/react-icons';
import {LightbulbFilamentRegular as ActiveLearningIcon} from '@fluentui/react-icons';

import {AirplaneTakeOffRegular as IntroIcon} from '@fluentui/react-icons';
import {BookPulseRegular as GeneralIcon} from '@fluentui/react-icons';
import {BookStarRegular as GOBIcon} from '@fluentui/react-icons';
import {GasPumpRegular as OrganicIcon} from '@fluentui/react-icons';
import {MicroscopeRegular as BiochemIcon} from '@fluentui/react-icons';
import {ArrowSwapRegular as TransferIcon} from '@fluentui/react-icons';
import {BriefcaseRegular as ProfDevIcon} from '@fluentui/react-icons';
import {ScalesRegular as EquityIcon} from '@fluentui/react-icons';
import {LeafOneRegular as SustainIcon} from '@fluentui/react-icons';
import {PeopleCommunityRegular as AllInclusiveIcon} from '@fluentui/react-icons';

export const categoryItems = [
    { name: 'All', key: 'all', icon: <AllIcon /> },
    { name: 'Links', key: 'link', icon: <LinksIcon /> },
    { name: 'Videos', key: 'video', icon: <VideosIcon /> },
    { name: 'Documents', key: 'document', icon: <DocumentIcon /> },
    { name: 'Labs', key: 'lab', icon: <LabsIcon /> },
    { name: 'Teaching Online', key: 'online', icon: <TeachingOnlineIcon /> },
    { name: 'Active Learning', key: 'active', icon: <ActiveLearningIcon /> }
]

export const topicItems = [
    { name: 'All', key: 'all', icon: <AllIcon /> },
    { name: 'Intro', key: 'intro', icon: <IntroIcon /> },
    { name: 'General', key: 'gen', icon: <GeneralIcon /> },
    { name: 'GOB', key: 'gob', icon: <GOBIcon /> },
    { name: 'Organic', key: 'org', icon: <OrganicIcon /> },
    { name: 'Biochemistry', key: 'bio', icon: <BiochemIcon /> },
    { name: 'Transferability', key: 'trans', icon: <TransferIcon /> },
    { name: 'Professional Development', key: 'pro', icon: <ProfDevIcon /> },
    { name: 'Equity', key: 'equ', icon: <EquityIcon /> },
    { name: 'Sustainability', key: 'sus', icon: <SustainIcon /> },
    { name: 'Interdisciplinary', key: 'inter', icon: <AllInclusiveIcon /> },
]