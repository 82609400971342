import * as React from 'react';
import { Button } from '@fluentui/react-components';
import type { IPageNumberProps } from './PageNumber.types';

export class PageNumber extends React.Component<IPageNumberProps, {}> {

  public render(): JSX.Element {
    const { ariaLabel, page, selected } = this.props;

    return (
      <Button
        shape='circular'
        key={page}
        onClick={this._onClick}
        aria-selected={selected}
        appearance={selected ? 'primary' : 'secondary'}
        aria-label={ariaLabel}
        style={{ minWidth: '32px', maxWidth: '32px' }}
      >
        {page}
      </Button>
    );
  }

  private _onClick = () => {
    this.props.onClick(this.props.page - 1);
  };
}