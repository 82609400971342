import React, { FC, useEffect } from 'react';
import { bind, } from '@react-rxjs/core';
import { accessTokenInfo$, isAuthorized$ } from '../../oauth/oauth';
import { QueryClient} from '@tanstack/react-query'
import { phpServer } from '../../constants';
import { Text, Input, Textarea, Button, MenuList, MenuItemRadio, MenuProps, TextareaProps, InputProps, Field, Card, CardPreview, CardHeader, Image } from '@fluentui/react-components';
import { useGeneralStyles } from '../../styles';
import { isAdmin$ } from '../../store/userStore';

import FileInput from '../../utility/FileInput';

interface NotificationsProps {

}

interface ISegment {
    id: string;
    name: string;
    app_id: string;
    is_active: boolean;
}

interface ISegmentsResponse {
    segments: ISegment[];
    total_count: number;
    limit: number;
    offset: number;
}

interface INotificationSettingsResponse {
    segmentsResponse: ISegmentsResponse;
}

export const queryClient = new QueryClient();

const [useIsAuthorized] = bind(isAuthorized$, isAuthorized$.value);
const [useIsAdmin] = bind(isAdmin$, false);

const Notifications: FC<NotificationsProps> = () => {


    const authorized = useIsAuthorized();
    const isAdmin = useIsAdmin();

    const generalStyles = useGeneralStyles();

    const [segments, setSegments] = React.useState<ISegment[]>([]);
    const [selectedSegment, setSelectedSegment] = React.useState<Record<string, string[]>>(
        { segments: ["Total Subscriptions"] }
    );
    const [pushHeader, setPushHeader] = React.useState<string>("Sample Header Text");
    const [pushContent, setPushContent] = React.useState<string>("Sample Content Text 🙄");
    const [imageData, setImageData] = React.useState<string | null>(null);
    const [file, setFile] = React.useState<File | null>(null);

    const onChangeSegment: MenuProps["onCheckedValueChange"] = (e, { name, checkedItems }) => {
        setSelectedSegment((s) => ({ ...s, [name]: checkedItems }));
    };

    const getNotificationSettingsAsync = async () => {
        if (accessTokenInfo$.value !== null && accessTokenInfo$.value.accessToken !== null) {
            let headers = new Headers();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            var p = new URLSearchParams();
            p.append("access_token", accessTokenInfo$.value.accessToken);

            const response = await fetch(phpServer + "getNotificationSettings.php", { method: 'POST', body: p.toString(), headers: headers });
            const json = await response.json() as INotificationSettingsResponse;
            console.log(json);
            setSegments(json.segmentsResponse.segments);
        }
    };

    useEffect(() => {
        getNotificationSettingsAsync();
    }, []);

    const sendNotificationAsync = async () => {
        if (accessTokenInfo$.value !== null && accessTokenInfo$.value.accessToken !== null) {
            let headers = new Headers();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            var p = new URLSearchParams();
            p.append("access_token", accessTokenInfo$.value.accessToken);
            p.append("contents", pushContent);
            p.append("headings", pushHeader);
            if (file != null) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                const data = await new Promise<string>((resolve, reject) =>{
                    reader.onloadend = () => {
                        if (reader.result == null){
                            reject("File read error");
                        } else{
                            resolve(reader.result.toString());
                        }
                    };
                });
                p.append('chrome_web_image', data);
            }
            const response = await fetch(phpServer + "sendNotification.php", { method: 'POST', body: p.toString(), headers: headers });
            const text = await response.text();
            console.log(text);
        }
    };

    const onHeaderChange: InputProps["onChange"] = (ev, data) => {
        // Uncontrolled inputs can be notified of changes to the value
        setPushHeader(data.value);
    };

    const onContentChange: TextareaProps["onChange"] = (ev, data) => {
        // Uncontrolled inputs can be notified of changes to the value
        setPushContent(data.value);
    };

    return (authorized && isAdmin) ? (

        <>
            <MenuList checkedValues={selectedSegment} onCheckedValueChange={onChangeSegment}>
                {segments.filter((segment) => segment.is_active).map((segment) => (
                    <MenuItemRadio value={segment.name} name="segments">{segment.name}</MenuItemRadio>
                ))}
            </MenuList>
            <Text className={generalStyles.groupTitle} >Notifications</Text>
            <br />
            <Field label="Notification Header" >
                <Input title="Notification Header" defaultValue={pushHeader} onChange={onHeaderChange} />
            </Field>
            <Field label="Notification Content">
                <Textarea title="Notification Content" defaultValue={pushContent} onChange={onContentChange} />
            </Field>
            <Field label="Optional Image">
                <FileInput accept="image/*" onChange={(e)=>{
                    console.log("TRIGGER");
                    if (e.target.files && e.target.files.length > 0) {
                        setImageData(URL.createObjectURL(e.target.files[0]));
                        setFile(e.target.files[0]);
                    }
                }}/>
            </Field>
            {imageData != null && (
                <Image style={{width:"300px", height:"300px"}} fit="contain" src={imageData} />
            )}
            
            <br />
            <br />
            <Text>Preview</Text>
            <Card style={{maxWidth: '400px'}}>
                {imageData != null && (
                <CardPreview>
                    <img style={{height:'200px', objectFit:'cover'}}
                        src={imageData}
                        alt="Presentation Preview"
                    />
                </CardPreview>
                )}
                <CardHeader
                    header={<Text weight="semibold">{pushHeader}</Text>}
                />
                <Text>{pushContent}</Text>
            </Card>

            <Button onClick={sendNotificationAsync} appearance='primary'>Send</Button>


        </>
    ) : (
        <>
            You don't have permissions to view this page.
        </>
    );
};

export default Notifications;
