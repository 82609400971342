import { FC, useState, useRef, useLayoutEffect } from 'react';
import {
    Card, CardPreview, CardFooter, Text,
    Button, Label, useId, Radio, RadioGroup, Switch, Spinner, Image
} from '@fluentui/react-components';
import { bind } from '@react-rxjs/core';
import { isAuthorized$, setLoginOpen } from './oauth/oauth';
import MemberInfo from './MemberInfo';
import { userStore, IMember } from './store/userStore';
import { useGeneralStyles } from './styles';

interface MainProps {
}

const [useIsAuthorized] = bind(isAuthorized$, isAuthorized$.value);


const Membership: FC<MainProps> = () => {

    const generalStyles = useGeneralStyles();

    const authorized = useIsAuthorized();
    const [renew, setRenewEarly] = useState<boolean>(false);

    const [membershipCost, setMembershipCost] = useState<number>(25);
    const [checkPayment, setCheckPayment] = useState<boolean>(false);

    const radio1 = useRef<HTMLInputElement>(null);
    const radio2 = useRef<HTMLInputElement>(null);
    const radio3 = useRef<HTMLInputElement>(null);
    const radio4 = useRef<HTMLInputElement>(null);

    const renewLengthOptionsId = useId("label");
    const paymentOptionsId = useId("label");

    const [userState, setUserState] = useState<IMember | null>(userStore.currentState);
    //const queryClient = useQueryClient();
    //queryClient.
    //const userData = queryClient.getQueryData<IMember | null>('userData');
    useLayoutEffect(() => {
        userStore.subscribe((x: (IMember | null)) => {
            setUserState(x);

        });
    });


    const renewEarlyHandler = () => {
        setRenewEarly(!renew);
    };

    return (
        <div >
            {authorized ?
                <>
                    <div >
                        <Card style={{ marginBottom: '20px' }}>
                            {userState ? <>
                                {userState.expires >= new Date().getFullYear() ?
                                    <>
                                        <CardPreview>
                                            <Image src="/images/studentStaring.webp"
                                                style={{ height: '200px' }}
                                                fit="cover"
                                                alt="Student manipulating diamond chemical structure model." />

                                        </CardPreview>
                                        <Text className={generalStyles.secondary}   >
                                            Your membership is current and will expire at the end of {userState.expires}.
                                        </Text>
                                        <CardFooter>
                                            <form>
                                                <Switch checked={renew} onChange={renewEarlyHandler} label='Renew early' />
                                                {renew ?
                                                    <>
                                                        <div style={{ margin: '5px' }}>
                                                            <Label id={renewLengthOptionsId}>Membership Time</Label>
                                                            <RadioGroup aria-labelledby={renewLengthOptionsId} aria-disabled={!renew}>
                                                                <Radio disabled={!renew} value='25' label='1 year membership ($25)' ref={radio1}
                                                                    checked={membershipCost === 25} onChange={event => setMembershipCost(25)} />
                                                                <Radio disabled={!renew} value='100' label='5 year membership ($100)' ref={radio2}
                                                                    checked={membershipCost === 100} onChange={event => setMembershipCost(100)} />
                                                            </RadioGroup>
                                                            <Label id={paymentOptionsId}>Payment Options</Label>
                                                            <RadioGroup aria-disabled={!renew} aria-labelledby={paymentOptionsId}>
                                                                <Radio disabled={!renew} value='online' label='Online (Paypal)' ref={radio3}
                                                                    checked={checkPayment === false} onChange={event => setCheckPayment(false)} />
                                                                <Radio disabled={!renew} value='check' label='By check' ref={radio4}
                                                                    checked={checkPayment === true} onChange={event => setCheckPayment(true)} />
                                                            </RadioGroup>
                                                            <Text>Payment instructions will appear after submitting the following form.</Text>
                                                        </div>
                                                    </>
                                                    : <></>}
                                            </form>
                                        </CardFooter>
                                    </>
                                    :
                                    <>
                                        <CardPreview>
                                            <Image fit="cover" style={{ height: '200px' }} src="/images/labspill.jpg" alt="Lab spill." />
                                            <Text className={generalStyles.secondary} style={{ color: "red" }}  >
                                                Your membership expired at the end of {userState ? userState.expires : <Spinner />}. Please renew!
                                            </Text>
                                        </CardPreview>
                                        <CardFooter>
                                            <form>
                                                <div style={{ margin: '5px' }}>
                                                    <Label id={renewLengthOptionsId}>Membership Time</Label>
                                                    <RadioGroup aria-labelledby={renewLengthOptionsId} >
                                                        <Radio value='25' label='1 year membership ($25)' ref={radio1}
                                                            checked={membershipCost === 25} onChange={event => setMembershipCost(25)} />
                                                        <Radio value='100' label='5 year membership ($100)' ref={radio2}
                                                            checked={membershipCost === 100} onChange={event => setMembershipCost(100)} />
                                                    </RadioGroup>
                                                    <Label id={paymentOptionsId}>Payment Options</Label>
                                                    <RadioGroup aria-labelledby={paymentOptionsId}>
                                                        <Radio value='online' label='Online (Paypal)' ref={radio3}
                                                            checked={checkPayment === false} onChange={event => setCheckPayment(false)} />
                                                        <Radio value='check' label='By check' ref={radio4}
                                                            checked={checkPayment === true} onChange={event => setCheckPayment(true)} />
                                                    </RadioGroup>
                                                    <Text>Payment instructions will appear after submitting the following form.</Text>
                                                </div>
                                            </form>

                                        </CardFooter>
                                    </>
                                }
                            </> : <Spinner />}


                        </Card>

                        <Text className={generalStyles.groupTitle}>Edit your personal information</Text>

                    </div>
                </>
                :
                <>
                    <Card style={{ marginBottom: '20px' }}>
                        {/* Public domain stock photo: https://www.rawpixel.com/image/103824/science-class */}
                        <CardPreview>
                            <Image src="/images/studentStaring.webp" alt="Student manipulating diamond chemical structure model."
                                style={{ height: '200px' }}
                                fit="cover" />
                            <Text className={generalStyles.groupTitle} style={{ margin: '20px' }}>
                                Become a Member!
                            </Text>
                            <div style={{ margin: '5px' }}>
                                <Label id={renewLengthOptionsId}>Membership Time</Label>
                                <RadioGroup aria-labelledby={renewLengthOptionsId} >
                                    <Radio value='25' label='1 year membership ($25)' ref={radio1}
                                        checked={membershipCost === 25} onChange={event => setMembershipCost(25)} />
                                    <Radio value='100' label='5 year membership ($100)' ref={radio2}
                                        checked={membershipCost === 100} onChange={event => setMembershipCost(100)} />
                                </RadioGroup>
                                <Label id={paymentOptionsId}>Payment Options</Label>
                                <RadioGroup aria-labelledby={paymentOptionsId}>
                                    <Radio value='online' label='Online (Paypal)' ref={radio3}
                                        checked={checkPayment === false} onChange={event => setCheckPayment(false)} />
                                    <Radio value='check' label='By check' ref={radio4}
                                        checked={checkPayment === true} onChange={event => setCheckPayment(true)} />
                                </RadioGroup>
                                <Text>Payment instructions will appear after submitting the following form.</Text>
                            </div>
                        </CardPreview>
                        <CardFooter>
                            <Button onClick={() => { setLoginOpen ? setLoginOpen(true) : console.log("It's not registered.") }}>Already a member?  Login...</Button>
                        </CardFooter>
                    </Card>
                    <Text className={generalStyles.groupTitle}>Please enter your information</Text>

                </>}


            <MemberInfo
                payMethod={(renew || !authorized || (userState && userState.expires < new Date().getFullYear())) ? (checkPayment === false ? "online" : "check") : ""}
                membershipCost={membershipCost} />
        </div>
    );
};

export default Membership;
