import './constants';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import CollegeSponsors from './CollegeSponsors';
import {Meetings} from './meetings/Meetings';
import Archives from './Archives';
import Main from './Main';
import MemberList from './MemberList';
import CollegeSponsorshipPayment from './CollegeSponsorshipPayment';
import Newsletter from './Newsletter';
import Officers from './Officers';
import MemberInfo from './MemberInfo';
import Membership from './Membership';
import IndustrySponsors from './IndustrySponsors';
import IndustrySponsorPayment from './IndustrySponsorPayment';
import Jobs from './Jobs';
import Links from './Links';
import FormsFiles from './FormsFiles';
import SharedResources from './resource/SharedResources';
import UploadResource from './resource/UploadResource';
import Transactions from './Transactions';
import Admin from './admin/Admin';
import Notifications from './admin/notification/Notifications';
import PaymentAudit from './admin/members/PaymentAudit';
import MailPayment from './MailPayment';
import OnlinePayment from './OnlinePayment';
import ErrorElement from './ErrorElement';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorElement />,
    children: [
      {
        index: true,
        element: <Main />,
      },
      {
        path: "membership",
        element: <Membership />,
      },
      {
        path: "meetings",
        element: <Meetings />,
      },
      {
        path: "officers",
        element: <Officers />,
      },
      {
        path: "college",
        element: <CollegeSponsors />,
      },
      {
        path: "industrial",
        element: <IndustrySponsors />,
      },
      {
        path: "industrial/payment",
        element: <IndustrySponsorPayment />,
      },
      {
        path: "archives",
        element: <Archives />,
      },
      {
        path: "listmembers",
        element: <MemberList />,
      },
      {
        path: "college/payment",
        element: <CollegeSponsorshipPayment />,
      },
      {
        path: "newsletter",
        element: <Newsletter />
      },
      {
        path: "jobs",
        element: <Jobs />
      },
      {
        path: "links",
        element: <Links />
      },
      {
        path: "memberInfo",
        element: <MemberInfo />
      },
      {
        path: "forms",
        element: <FormsFiles />
      },
      {
        path: "resources",
        element: <SharedResources />
      },
      {
        path: "upload",
        element: <UploadResource />
      },
      {
        path: "transactions",
        element: <Transactions />
      },
      {
        path: "admin",
        element: <Admin />
      },
      {
        path: "admin/notifications",
        element: <Notifications />
      },
      {
        path: "admin/paymentaudit",
        element: <PaymentAudit />
      },
      {
        path: "membership/mailpayment",
        element: <MailPayment />
      }, 
      {
        path: "membership/onlinepayment",
        element: <OnlinePayment />
      }
    ],
  }

]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <style>{`html {overflow:hidden}`}</style>
    {/* <AuthProvider {...oidcConfig}> */}
    <RouterProvider router={router} />
    {/* </AuthProvider> */}
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
