import React, { FC } from 'react';
import { useQuery} from '@tanstack/react-query'
import { phpServer } from './constants';
import { Table, TableHeader, TableBody, TableRow, TableHeaderCell, TableCell, Card, Text } from '@fluentui/react-components';

interface MainProps {

}

interface IMemberList {
	count: number;
	year: number;
	list: Member[];
}
interface Member {
	first: string;
	last: string;
	institution: string;
}

const MemberList: FC<MainProps> = () => {

	//const authorized = useIsAuthorized();

	const { data: membersJson } = useQuery({queryKey: ['membersListData'],refetchOnWindowFocus: true, queryFn: async () => {
		const res = await fetch(phpServer + "listMembers.php");
		if (!res.ok) {
			throw new Error('Network response was not ok')
		}
		const membersJson: IMemberList = await res.json();
		console.log(membersJson);
		return membersJson;
	}});

	// if (!authorized) {
	// 	return <div>Hey, you're not authorized to see this page.</div>
	// }
	// const columns: GridColDef[] = [
	// 	// {field: 'id', valueGetter: (params: GridValueGetterParams)=>
	// 	// 	`${params.row.first} ${params.row.last} ${params.row.institutions}`
	// 	// },
	// 	{field: 'first', headerName:'First Name'},
	// 	{field: 'last', headerName: 'Last Name'},
	// 	{field: 'institution', headerName: 'Institution'}
	// ];

	return (
		<>
			{membersJson !== undefined && membersJson != null ?
				<div>
					<Card>
						<Text style={{ textAlign: 'center' }}>
							The following individuals are members of the 2YC3 and have agreed to have their membership information posted online.
							There are a few additional 2YC3 members that opted out of posting their name online.

						</Text>
					</Card>
					<Text
						id="tableTitle"
						style={{ marginTop: '10px' }}
					>
						Year {membersJson.year}: {membersJson.count} members currently in the database
					</Text>
					{/* <div style={{ height: 400, width: '100%' }}>
					<DataGrid 
						columns={columns} 
						rows={membersJson.list}
						getRowId={(params)=> `${params.first} ${params.last} ${params.institutions}`} />
					</div> */}
					<Table aria-label={"Members from " + membersJson.year}>
						<TableHeader>
							<TableRow>
								<TableHeaderCell>Name</TableHeaderCell>
								<TableHeaderCell align="left">Institution</TableHeaderCell>
							</TableRow>
						</TableHeader>
						<TableBody>
							{membersJson.list.map((v: Member) => {
								return (
									<TableRow key={v.first + v.last + v.institution}>
										<TableCell >
											{v.first} {v.last}
										</TableCell>
										<TableCell align='left'>{v.institution}</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>

				</div>
				: <></>
			}

		</>
	);
};

export default MemberList;
