import React, { FC } from 'react';
import { useQuery } from '@tanstack/react-query'
import { Spinner } from '@fluentui/react-components';
import { PaginationBase as Pagination } from '../pagination/Pagination.base';
import { InteractionTag, InteractionTagPrimary } from "@fluentui/react-tags-preview";
import { phpServer } from '../constants';
import ResourceItem from './ResourceItem';
import { categoryItems, topicItems } from './resources';

interface MeetingsProps {
}

const SharedResources: FC<MeetingsProps> = () => {

    const [category, setCategory] = React.useState('all');
    const [topic, setTopic] = React.useState('all');
    const [page, setPage] = React.useState(0);
    const [pageCount, setPageCount] = React.useState(0);
    //const [itemsPerPage, setItemsPerPage] = React.useState(10);
    const itemsPerPage = 10;
    const [isQuerying, setIsQuerying] = React.useState(false);

    const { data: resourcesJson } = useQuery({queryKey: ['resourcesData', page, itemsPerPage, topic, category],refetchOnWindowFocus: false ,queryFn: async () => {
            setIsQuerying(true);
            var p = new URLSearchParams();
            let headers = new Headers();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            let query = '?page=' + (page+1);
            query += '&items=' + itemsPerPage;
            query += '&category=' + category;
            query += '&topic=' + topic;

            const res = await fetch(phpServer + 'getResources.php' + query, { method: 'POST', body: p.toString(), headers: headers });
            if (!res.ok) {
                setIsQuerying(false);
                throw new Error('Network response was not ok')
            }
            const resources = await res.json();
            if (pageCount !== Math.ceil(resources[0] / itemsPerPage)) {
                setPageCount(Math.ceil(resources[0] / itemsPerPage));
            }
            setIsQuerying(false);
            return resources[1];
        //}
    }});

    const handleContentTypeChange = (e: React.MouseEvent, v: string | null) => {
        if (v !== null) {
            setCategory(v);
            setPage(0);
            setPageCount(0);
        }
    };
    const handleSeriesChange = (e: React.MouseEvent, v: string | null) => {
        if (v !== null) {
            setTopic(v);
            setPage(0);
            setPageCount(0);
        }
    };

    const handlePageChange = async (value: number) => {
        setPage(value);
    };

    return (
        <>
            {/* Stops page from reflowing when scrollbar appears/disappears */}
            {/* <style>{`
                html {
                overflow-y: scroll;
                `}
            </style> */}
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    listStyle: 'none',
                    padding: '5px',
                    margin: '10px',
                }} >
                    {categoryItems.map((item) => (

                        <InteractionTag style={{margin: '2px'}} key={item.key} shape='circular' size='small' appearance={category === item.key ? 'brand' : 'filled'}>
                            <InteractionTagPrimary onClick={e => handleContentTypeChange(e, item.key)}
                                icon={item.icon}>
                                {item.name}
                            </InteractionTagPrimary>
                        </InteractionTag>


                    ))}
                </div>

                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    listStyle: 'none',
                    padding: '5px',
                    margin: '0px',
                }}>
                    {topicItems.map((item) => (
                        <InteractionTag key={item.key} style={{margin: '2px'}}  shape='circular' size='small' appearance={topic === item.key ? 'brand' : 'filled'}>
                            <InteractionTagPrimary onClick={e => handleSeriesChange(e, item.key)}
                                icon={item.icon}>
                                {item.name}
                            </InteractionTagPrimary>
                        </InteractionTag>
                    ))}
                </div>
                <div style={{alignSelf: 'end'}}>
                <Pagination pageCount={pageCount} selectedPageIndex={page} onPageChange={handlePageChange} 
                        format={'buttons'}                        
                        previousPageAriaLabel={'previous page'}
                        nextPageAriaLabel={'next page'}
                        firstPageAriaLabel={'first page'}
                        lastPageAriaLabel={'last page'}
                        pageAriaLabel={'page'}
                        selectedAriaLabel={'selected'}
                        />
                        </div>
                {isQuerying ? <Spinner style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 3 }} /> :
                    <>
                        

                        {resourcesJson && resourcesJson.map((resource: any) => (
                            <ResourceItem {...resource} />
                        ))}

                        
                    </>
                }
                <div style={{alignSelf: 'end'}}>
                <Pagination  pageCount={pageCount} selectedPageIndex={page} onPageChange={handlePageChange} 
                        format={'buttons'}
                        previousPageAriaLabel={'previous page'}
                        nextPageAriaLabel={'next page'}
                        firstPageAriaLabel={'first page'}
                        lastPageAriaLabel={'last page'}
                        pageAriaLabel={'page'}
                        selectedAriaLabel={'selected'}/>
                        </div>

            </div>

        </>
    );
};

export default SharedResources;
