import React, { FC } from 'react';
import { useQuery } from '@tanstack/react-query'
import { Card, Text, Accordion, AccordionItem, AccordionHeader, AccordionPanel, Link, makeStyles, shorthands, CardHeader, CardPreview, Image, Input } from '@fluentui/react-components';
import { useGeneralStyles } from './styles';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import LinkRouter from './LinkRouter';
import FilePicker from './FilePicker';

interface MainProps {

}

const usePageStyles = makeStyles({
	items: {
		//...shorthands.margin('10px')
	},
	cards: {
		...shorthands.margin('10px', '0px', '10px', '0px')
	}
});

//const [useEmail] = bind(email$, '');

const Main: FC<MainProps> = () => {

	const pageStyles = usePageStyles();
	//const email = useEmail();

	const { data: carouselJson } = useQuery({queryKey: ['carouselData'], refetchOnWindowFocus:true, queryFn: async () => {
		const res = await fetch("/data/carousel.json");
		if (!res.ok) {
			throw new Error('Network response was not ok')
		}
		const carouselJson: string[] = await res.json();

		return carouselJson;
	}});


	const styles = useGeneralStyles();

	return (
		<>
			<div>
				<div className={pageStyles.items}>
					<Text className={styles.subtitle1}>Dedicated to Maintaining and Improving the Quality of Chemistry Offerings in the Two-Year Colleges</Text>
				</div>
				
				<Card className={pageStyles.cards}>

					<Text className={styles.body2}>
						The 2YC3 is currently seeking new members. Join <LinkRouter to="/listmembers"> hundreds of your peers</LinkRouter> from
						around the country by <LinkRouter href="/membership">becoming a member or renewing your 2YC3 membership</LinkRouter> today!
						Secure electronic payments are now accepted for your membership dues and college sponsorship dues.
					</Text>
					<br />
					<Text className={styles.body2}>
						We encourage you to attend or present at an <LinkRouter to="/meetings">upcoming meeting</LinkRouter>.
						Contact <Link href="mailto:futuresites@2yc3.org">futuresites@2yc3.org</Link>, for more information.
					</Text>

				</Card>

				<Card>
					<CardHeader header={"Social Media"} />
					{/* <Text className={styles.body2}>Testing</Text>
					<Button onClick={()=>showNotification()}>Notify me</Button> */}
					{/* <Button onClick={()=>promptSubscribe()}>Subscription</Button> */}
					<CardPreview>
						<div style={{ display: 'flex', flexDirection: 'row', padding: '10px', columnGap: '10px' }}>
							<Link href="https://facebook.com/pages/2YC3/107721355914597?ref=ts" target="_blank">
								<Image src="https://2yc3.org/mpics/fb.jpg" alt="Facebook" />
							</Link>
							<Link href="https://twitter.com/2yc3" target="_blank">
								<Image src="https://2yc3.org/mpics/tw.jpg" alt="Twitter" />
							</Link>
						</div>
					</CardPreview>
					{/* <div className={'onesignal-customlink-container'}></div> */}
				</Card>

				{carouselJson === undefined || carouselJson == null ? <></> :

					<Carousel className={pageStyles.items} autoPlay>
						{carouselJson.map((v, i) =>
							<img key={v} style={{ objectFit: 'cover' }} alt="Member of 2YC3" width="100%" src={v} />
						)}
					</Carousel>
					// <Carousel sx={{ margin: "10px 0 10px 0" }}>
					// 	{carouselJson.map(v =>
					// 		<Card key={v}>

					// 			<CardMedia
					// 				image={v}
					// 				component="img"
					// 				sx={{ height: { imgHeight }, width: '100%', position: 'relative', objectFit: 'cover' }} />

					// 		</Card>
					// 	)}
					// </Carousel>

				}
			</div>
			<Card className={pageStyles.cards}>

				<Text className={styles.subtitle1}>2YC3 Vision</Text>
				<Text className={styles.body2}>
					The Two Year College Chemistry Consortium provides a forum for chemistry educators to enhance student learning
					through our professional development conferences.
				</Text>
			</Card>
			<Card className={pageStyles.cards}>
				<Accordion collapsible>
					<AccordionItem value="1">
						<AccordionHeader>
							<Text className={styles.subtitle1}>2YC3 Mission</Text>
						</AccordionHeader>

						<AccordionPanel>
							<Text className={styles.body2}>The committee is charged with providing:</Text>
							<Text className={styles.body2} as={'span'}>
								<ul>
									<li>A national forum for the interchange of ideas, resources, guidelines, and information
										pertaining to two-year college chemistry programs</li>
									<li>A network of chemical education colleagues for improving two-year college chemistry teaching</li>
									<li>Opportunities for professional development and growth among members</li>
									<li>A mechanism to network two-year college chemistry teachers with all other categories of chemical educators</li>
									<li>An entry point for 2YC3 members to become involved in DivCHED activities. The committee shall coordinate
										activities in two-year college chemistry programs with related organizations and conduct meetings
										accessible to two-year college chemistry teachers</li>
								</ul>
							</Text>
						</AccordionPanel>
					</AccordionItem>

				</Accordion>
			</Card>
			<Card className={pageStyles.cards}>
				<Text className={styles.subtitle1}>Committee on Chemistry in the Two-Year Colleges</Text>
				<Text className={styles.body2}>
					Abbreviated as COCTYC, this committee is designated as the executive committee governing the 
					Two-Year College Chemistry Consortium and is charged with responsibility for 2YC3 conferences and its own finances and communications.
				</Text>


			</Card>
		</>
	);
};

export default Main;
