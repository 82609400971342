import { FC } from 'react';
import { QueryClient } from '@tanstack/react-query'
import { Card, CardHeader, CardFooter, Button, Text } from '@fluentui/react-components';
import { useGeneralStyles } from '../styles';

interface DefaultJobPostingProps {
    title: string;
    link: string;
    content: string;
    expires?: Date;
}
export const queryClient = new QueryClient();

const DefaultJobPosting: FC<DefaultJobPostingProps> = (props) => {

    const generalStyles = useGeneralStyles();

    return (
        <>
            <Card style={{ marginBottom: '10px'}}>
                <CardHeader header={<Text className={generalStyles.subtitle1}>
                    {props.title}
                </Text>} />

                <div>

                    {(props.content !== "") ?
                        <Text className={generalStyles.body2} color="text.primary">
                            <div dangerouslySetInnerHTML={{__html: props.content}}></div>
                        </Text>
                        : <></>
                    }
                    {props.expires ?
                    (
                        <>
                        <br/>
                        <Text>Expires: {props.expires.toString()}</Text>
                        </>
                    ): <></>}
                </div>
                <CardFooter style={{alignSelf: 'center'}}>
                    <Button as='a' size="large" appearance='primary' href={props.link} target="_blank">
                        Apply Here
                    </Button>
                </CardFooter>

            </Card>


        </>
    );
};

export default DefaultJobPosting;
