import React, { useState, FC, useLayoutEffect } from 'react';
import { phpServer } from './constants';
import {
    useId, Checkbox, Input, Label, Button, Text, Select,
    Dialog, DialogTrigger, DialogSurface, DialogTitle, DialogBody, DialogActions, DialogContent
} from '@fluentui/react-components';
import { useForm, Controller } from "react-hook-form";
import { statesArray } from './states';
import { IMember, userStore } from './store/userStore';
import { useGeneralStyles } from './styles';

interface IndustrySponsorsProps {

}


interface IIndustryInfo {
    first: string;
    last: string;
    institution: string;
    addr1: string;
    addr2: string;
    city: string;
    st: string;
    zip: string;
    phone: string;
    email: string;
    annualRenewal: boolean;
    quarterAd: boolean;
    halfAd: boolean;
    fullAd: boolean;
    reason: string;
    otherAmount: number | undefined;
}

interface IIndustrySubmission {
    username1: string;
    first: string;
    last: string;
    institution: string;
    addr1: string;
    addr2: string;
    city: string;
    st: string;
    zip1: number;
    zip2: number;
    phone1: number;
    phone2: number;
    phone3: number;
    phone4: number;
    email: string;
    annual: number;
    quarter: number;
    half: number;
    full: number;
    other: string;
    donation: number;
}

const IndustrySponsorPayment: FC<IndustrySponsorsProps> = () => {

    const [userState, setUserState] = useState<IMember | null>(userStore.currentState);
    useLayoutEffect(() => {
        userStore.subscribe((x: (IMember | null)) => {
            setUserState(x);
        });
    });

    const { handleSubmit, control, formState: { errors } } = useForm<IIndustryInfo>({
        defaultValues: {
            first: "",
            last: "",
            institution: "",
            addr1: "",
            addr2: "",
            city: "",
            st: "",
            zip: "",
            phone: "",
            email: "",
            annualRenewal: false,
            quarterAd: false,
            halfAd: false,
            fullAd: false,
            reason: "",
            otherAmount: undefined,
        }
    });

    const onSubmit = async (values: IIndustryInfo) => {
        const submission: Partial<IIndustrySubmission> = {};

        if (userState) {
            submission.username1 = userState.username1;
        } else {
            submission.username1 = "";
        }

        submission.first = values.first;
        submission.last = values.last;
        submission.institution = values.institution;
        submission.addr1 = values.addr1;
        submission.addr2 = values.addr2;
        submission.city = values.city;
        submission.st = values.st;
        const zipSplit = values.zip.split(/[-.,\s]/);
        submission.zip1 = +zipSplit[0];
        submission.zip2 = zipSplit.length > 1 ? +zipSplit[1] : 0;

        const phoneSplit = values.phone.split(/[-.,\sx]+/);
        submission.phone1 = +phoneSplit[0];
        submission.phone2 = phoneSplit.length > 1 ? +phoneSplit[1] : 0;
        submission.phone3 = phoneSplit.length > 2 ? +phoneSplit[2] : 0;
        submission.phone4 = phoneSplit.length > 3 ? +phoneSplit[3] : 0;

        submission.email = values.email;
        submission.annual = values.annualRenewal ? 200 : 0;
        submission.quarter = values.quarterAd ? 300 : 0;
        submission.half = values.halfAd ? 550 : 0;
        submission.full = values.fullAd ? 1050 : 0;
        submission.other = values.reason;
        submission.donation = values.otherAmount !== undefined ? values.otherAmount : 0;

        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        const response = await fetch(phpServer + "submitIndustrySponsorship.php", { method: 'post', body: JSON.stringify(submission), headers: headers });
        console.log(response);
        const result = await response.json();
        if (!result.error) {
            window.open(result.response, "_blank")
            console.log(result);
        }
    };

    const contactPersonId = useId('div');
    const firstId = useId('input');
    const lastId = useId('input');
    const companyId = useId('input');
    const addr1Id = useId('input');
    const addr2Id = useId('input');
    const cityId = useId('input');
    const stateId = useId('select');
    const zipId = useId('input');
    const zipErrorId = useId('text');
    const phoneId = useId('input');
    const phoneErrorId = useId('text');
    const emailId = useId('input');
    const emailErrorId = useId('text');

    const sponsorshipId = useId('checkbox');
    const newsletterId = useId('div');
    const otherId = useId('div');
    const otherExplanationId = useId('input');
    const otherAmountId = useId('input');

    const generalStyles = useGeneralStyles();

    return (
        <>



            <form onSubmit={handleSubmit(onSubmit)}
                style={{ display: 'flex', flexDirection: 'column' }}
                method="POST">

                <Label className={generalStyles.groupTitle} htmlFor={contactPersonId} >Contact & Details</Label>

                <div style={{ display: 'flex', flexDirection: 'column' }} id={contactPersonId} >

                    <Label htmlFor={firstId} required>First Name</Label>
                    <Controller name="first"
                        control={control}
                        rules={{
                            required: true
                        }}
                        render={({ field }) =>
                            <Input
                                id={firstId}
                                {...field}
                                required
                            />
                        }
                    />
                    <Label htmlFor={lastId} required>Last Name</Label>
                    <Controller name="last"
                        control={control}
                        rules={{
                            required: true
                        }}
                        render={({ field }) =>
                            <Input id={lastId}
                                {...field}
                                required
                            />
                        }
                    />
                    <Label required htmlFor={companyId}>Company</Label>
                    <Controller name="institution"
                        control={control}
                        rules={{
                            required: true
                        }}
                        render={({ field }) =>
                            <Input id={companyId}
                                {...field}
                                required
                            />
                        }
                    />
                    <Label required htmlFor={addr1Id}>Address 1</Label>
                    <Controller name="addr1"
                        control={control}
                        rules={{
                            required: true
                        }}
                        render={({ field }) =>
                            <Input id={addr1Id}
                                {...field}
                                required
                            />
                        }
                    />
                    <Label htmlFor={addr2Id}>Address 2</Label>
                    <Controller name="addr2"
                        control={control}
                        rules={{
                            required: false
                        }}
                        render={({ field }) =>
                            <Input id={addr2Id}
                                {...field}
                            />
                        }
                    />
                    <Label required htmlFor={cityId}>City</Label>
                    <Controller name="city"
                        control={control}
                        rules={{
                            required: true
                        }}
                        render={({ field }) =>
                            <Input id={cityId}
                                {...field}
                                required
                            />
                        }
                    />
                    <div style={{ display: 'flex', flexDirection: 'row', columnGap: '0.5em', width: '100%' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5em' }}>
                            <Label htmlFor={stateId}>State</Label>
                            <Controller name="st"
                                control={control}
                                rules={{
                                    required: true
                                }}
                                render={({ field }) =>
                                    <Select
                                        id={stateId}
                                        {...field}
                                        required
                                    >
                                        {statesArray.map((v, i) => {
                                            return (
                                                <option key={i} value={v.abbr}>{v.name}</option>
                                            );
                                        })}
                                    </Select>
                                }
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5em' }}>
                            <Label required htmlFor={zipId}>Zip</Label>
                            <Controller name="zip"
                                control={control}
                                defaultValue={''}
                                rules={{
                                    required: true
                                }}
                                render={({ field }) =>
                                    <Input {...field}
                                        id={zipId}
                                        aria-describedby={zipErrorId}
                                        required
                                    />
                                }
                            />
                            {errors.zip && (
                                <Text id={zipErrorId}>{errors.zip && errors.zip.message}</Text>
                            )
                            }
                        </div>
                    </div>

                    <Label htmlFor={phoneId}>Phone Number</Label>
                    <Controller name="phone"
                        control={control}
                        defaultValue={''}
                        rules={{
                            required: false,
                            pattern: {
                                value: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})(.*?)?/i,
                                message: "Use 123-456-7890 format. Extensions OK."
                            }
                        }}
                        render={({ field }) =>
                            <Input {...field} id={phoneId}

                            />}
                    />
                    {errors.phone && (
                        <Text id={phoneErrorId}>{errors.phone && errors.phone.message}</Text>
                    )}

                    <Label required htmlFor={emailId}>Email</Label>
                    <Controller name="email"
                        control={control}
                        rules={{
                            required: true,
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "invalid email address"
                            }
                        }}
                        render={({ field }) =>
                            <Input {...field}
                                required
                            />}
                    />
                    {errors.email && (
                        <Text id={emailErrorId}>{errors.email && errors.email.message}</Text>
                    )}
                </div>

                <Label className={generalStyles.groupTitle} htmlFor={sponsorshipId}>Sponsorship</Label>
                <Controller name="annualRenewal"
                    control={control}
                    render={({ field: { onChange, value } }) =>
                        <Checkbox checked={value} onChange={onChange} id={sponsorshipId}
                            label="Annual renewal - $200 (covers exhibits at all 2YC3 conferences for a year - typically 2 per year)"
                        />}
                />


                <Label className={generalStyles.groupTitle} htmlFor={newsletterId}>Newsletter Advertising</Label>
                <div id={newsletterId}>
                    <Controller name="quarterAd"
                        control={control}
                        render={({ field: { onChange, value } }) =>
                            <Checkbox checked={value} onChange={onChange}
                                label="Quarter of a page - $300"
                            />}
                    />
                    <Controller name="halfAd"
                        control={control}
                        render={({ field: { onChange, value } }) =>
                            <Checkbox checked={value} onChange={onChange}
                                label="Half of a page - $550"
                            />}
                    />
                    <Controller name="fullAd"
                        control={control}
                        render={({ field: { onChange, value } }) =>
                            <Checkbox checked={value} onChange={onChange}
                                label="Full page - $1050"
                            />}
                    />
                </div>


                <Label className={generalStyles.groupTitle} htmlFor={otherId}>Other Support (Optional)</Label>
                <div id={otherId} style={{ flexDirection: 'column', display: 'flex' }}>
                    <Label htmlFor={otherExplanationId}>Explanation for other support</Label>
                    <Controller name="reason"
                        control={control}
                        render={({ field }) =>
                            <Input {...field}
                            />}
                    />
                    <Label htmlFor={otherAmountId}>Amount for other support</Label>
                    <Controller name="otherAmount"
                        control={control}
                        render={({ field }) =>
                            <Input value={field.value?.toString()}
                                contentBefore={<Text size={400}>$</Text>}
                                id={otherAmountId}
                                onChange={field.onChange} />
                        }
                    />
                </div>

                <Dialog>
                    <DialogTrigger disableButtonEnhancement>
                        <Button style={{ marginTop: '20px', marginBottom: '20px' }} type="submit" appearance="primary">Submit</Button>
                    </DialogTrigger>
                    <DialogSurface>
                        <DialogBody>
                            <DialogTitle>Thank you!</DialogTitle>
                            <DialogContent>
                                <Text className={generalStyles.body2}>You have been forwarded (via a new tab) to a Paypal page to complete your transaction.  2YC3 will contact you soon with further details.
                                </Text>
                            </DialogContent>
                            <DialogActions>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button appearance="primary">Dismiss</Button>
                                </DialogTrigger>
                            </DialogActions>
                        </DialogBody>
                    </DialogSurface>
                </Dialog>

            </form>





        </>
    );
};

export default IndustrySponsorPayment;
