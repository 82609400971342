import React, { FC, useEffect } from 'react';
import { Text,Button } from '@fluentui/react-components';


interface FileInputProps {
    appearance?: 'primary' | 'secondary' | 'outline' | 'subtle' | 'transparent';
    children?: string;
    accept?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}


const FileInput: FC<FileInputProps> = (props) => {

    
    const [filename, setFileName] = React.useState<string>("No file chosen");
    

    const input = React.useRef<HTMLInputElement>(null);

    useEffect(() => {
        //getNotificationSettingsAsync();
    }, []);

    const buttonClick = () => {
        input.current?.click();
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.files);
        if (e.target.files && e.target.files.length > 0) {
            setFileName(e.target.files[0].name);
            props.onChange?.(e);
            //setImageData(URL.createObjectURL(e.target.files[0]));
            // const reader = new FileReader();
            // reader.readAsDataURL(e.target.files[0]);
            // reader.onloadend = () => {
            //     setImageData(reader.result as string);
            // };
        }
    };


    return (
        <>
        <div style={{minWidth:'260px'}}>
            <input type='file' accept={props.accept || "*.*"} ref={input} style={{display:'none'}} onChange={handleInputChange} />
            <Button onClick={buttonClick} style={{marginRight:'5px'}} appearance={props.appearance || 'secondary'} >{props.children || 'Choose File'}</Button>
            <Text >{filename}</Text>
        </div>
        </>
        );
};

export default FileInput;
