import {makeStyles, typographyStyles, tokens } from '@fluentui/react-components';

export const useGeneralStyles = makeStyles({
	subtitle1: typographyStyles.subtitle1,
    body1: typographyStyles.body1,
    body2: typographyStyles.body2,
    menuTitle: typographyStyles.title1,
    secondary: typographyStyles.body1Strong,
    
    groupTitle: typographyStyles.subtitle1,
    cardTitle: typographyStyles.subtitle2,
    caption: typographyStyles.caption1,

    red: { color: tokens.colorStatusDangerForeground1},

    error: {
        color: tokens.colorStatusDangerForeground1,
        fontFamily: tokens.fontFamilyBase,
        fontSize: tokens.fontSizeBase200,
        fontWeight: tokens.fontWeightSemibold,
        lineHeight: tokens.lineHeightBase200
    }

});