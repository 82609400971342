import { FC } from 'react';
import { QueryClient, useQuery } from '@tanstack/react-query'
import { phpServer } from './constants';
import { Link, Text, Card, Spinner } from '@fluentui/react-components';
import LinkRouter from './LinkRouter';
import { useGeneralStyles } from './styles';

interface IndustrySponsorsProps {

}
export const queryClient = new QueryClient();

const IndustrySponsors: FC<IndustrySponsorsProps> = () => {
    const { data: industrialData } = useQuery({queryKey: ['industrialData'], refetchOnWindowFocus: false, queryFn: async () => {
        const res = await fetch(phpServer + 'getIndustrySponsors.php');
        if (!res.ok) {
            throw new Error('Network response was not ok')
        }
        const d = await res.json();
        console.log(d);
        return d;
    }});

    const generalStyles = useGeneralStyles();
 
    return (
        <>

            <div style={{marginBottom:'20px'}}>
                <Text className={generalStyles.body2} >
                     Become a sponsor or pay for advertisement: <LinkRouter as="Link" href="/industrial/payment">Industrial Sponsor Online Payment</LinkRouter><br />
                    
                </Text>
            </div>
            {industrialData != null ?
                <>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', wordWrap:'break-word' }}>
                        {industrialData.length > 0 ? industrialData.map((v: any, i: number) =>
                            <Card style={{margin:'5px', maxWidth:'200px'}} key={i}>
                                <div>
                                    <Link className={generalStyles.cardTitle} href={v.email} >
                                        {v.company}
                                    </Link>
                                    <br/>
                                    <Text className={generalStyles.body2} color="text.secondary">
                                        {v.city}
                                    </Text>
                                    <br/>
                                    <Text className={generalStyles.body2} color="text.secondary">
                                        {v.contact}
                                    </Text>
                                    <br/>
                                    <Link href={"mailto:" + v.email} className={generalStyles.caption} color="text.secondary">
                                        {v.email}
                                    </Link>

                                </div>
                            </Card>
                        ) 
                        : 
                        <>
                        <Text>We currently do not have any industry sponsors.</Text>
                        </>}
                    </div>
                </>
                :
                <>
                    <div>
                    <Spinner />
                    </div>
                </>
            }



        </>
    );
};

export default IndustrySponsors;
