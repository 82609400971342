import React, { FC } from 'react';
import { bind, } from '@react-rxjs/core';
import { accessTokenInfo$, isAuthorized$ } from '../../oauth/oauth';
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { phpServer } from '../../constants';
import { PaginationBase as Pagination } from '../../pagination/Pagination.base';
import {
    DataGrid, DataGridRow, DataGridHeader, DataGridBody, DataGridHeaderCell, DataGridCell,
    TableColumnDefinition, createTableColumn, TableCellLayout, Text, Button, Spinner, Tooltip, TableColumnId, DataGridCellFocusMode,
    Label, useId, InputProps
} from '@fluentui/react-components';
import { SearchBox } from "@fluentui/react-search-preview";
import type { SearchBoxProps } from "@fluentui/react-search-preview";
import { useGeneralStyles } from '../../styles';
import { CheckmarkRegular, DeleteRegular, ArrowUndoRegular } from '@fluentui/react-icons';



interface PaymentAuditProps {

}

const [useIsAuthorized] = bind(isAuthorized$, isAuthorized$.value);


const PaymentAudit: FC<PaymentAuditProps> = () => {
    const authorized = useIsAuthorized();
    const queryClient = useQueryClient();
    const [page, setPage] = React.useState(0);
    const [pageCount, setPageCount] = React.useState(0);
    const itemsPerPage = 20;
    const [selected, setSelected] = React.useState(new Set<number>());
    const [updating, setUpdating] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [searchValue, setSearchValue] = React.useState("");

    const { data: transactionsData, isFetching } = useQuery({queryKey: ['membersData', page, searchTerm], refetchOnWindowFocus:false, queryFn: async () => {
        if (accessTokenInfo$.value != null && accessTokenInfo$.value.accessToken != null) {
            //const userName = await firstValueFrom(userId$.pipe(filter(userId => userId != null && userId !== '')));
            let url = phpServer + `getTransactionsAll.php?page=${page + 1}&items=20&access_token=` + accessTokenInfo$.value.accessToken;
            if (searchTerm.length > 0) {
                url = phpServer + `getTransactionsAll.php?searchTerm=${searchTerm}&page=${page + 1}&items=20&access_token=` + accessTokenInfo$.value.accessToken;
            }
            const res = await fetch(url);
            if (!res.ok) {
                throw new Error('Network response was not ok')
            }
            const d = await res.json();
            if (d.error) {
                console.log(d.error);
                return [];
            } else {
                setPageCount(Math.ceil(d.total / itemsPerPage));
                console.log(d.response);
                return d.response;
            }
        }
    }});

    const handlePageChange = async (value: number) => {
        setPage(value);
    };

    const getActionButtons = (item: any): JSX.Element => {
        if (item.status === 'Pending') {
            return (
                <div style={{ display: 'flex' }}>
                    <Tooltip content="Cancel Transaction" relationship='label'>
                        <Button onClick={(e:any) => cancelPressed(e, item)} icon={<DeleteRegular />}></Button>
                    </Tooltip>
                    <Tooltip content="Confirm Transaction" relationship='label'>
                        <Button onClick={(e:any) => checkmarkPressed(e, item)} icon={<CheckmarkRegular />}></Button>
                    </Tooltip>
                </div>);
        } else {
            return (
                <div style={{ display: 'flex' }}>
                    <Tooltip content="Return to pending status" relationship='label'>
                        <Button onClick={(e:any) => undoPressed(e, item)} icon={<ArrowUndoRegular />}></Button>
                    </Tooltip>
                </div>
            );
        }
    }

    const selectionChanged = (_e: React.MouseEvent | React.KeyboardEvent, data: any) => {
        console.log(data.selectedItems);
        setSelected(data.selectedItems);
    };

    const cancelAllPressed = async () => {
        setUpdating(true);
        let promiseArray: any[] = [];
        selected.forEach(index => {
            let promise = updateItemAsync('Removed by admin', transactionsData[index]);
            promiseArray.push(promise);
        });
        setSelected(new Set<number>());
        await Promise.all(promiseArray);
        queryClient.invalidateQueries({queryKey: ['membersData']});
        setUpdating(false);
    };

    const approveAllPressed = async () => {
        setUpdating(true);
        let promiseArray: any[] = [];
        selected.forEach(index => {
            let promise = updateItemAsync('Confirmed', transactionsData[index]);
            promiseArray.push(promise);
        });
        setSelected(new Set<number>());
        await Promise.all(promiseArray);
        queryClient.invalidateQueries({queryKey: ['membersData']});
        setUpdating(false);
    };

    const checkmarkPressed = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: any) => {
        e.stopPropagation();
        setUpdating(true);
        let result = await updateItemAsync("Confirmed", item);
        if (result) {
            //update item in list
            queryClient.invalidateQueries({queryKey: ['membersData']});

        }
        setUpdating(false);
    };

    const cancelPressed = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: any) => {
        e.stopPropagation();
        setUpdating(true);
        let result = await updateItemAsync("Removed by admin", item);
        if (result) {
            //update item in list
            queryClient.invalidateQueries({queryKey: ['membersData']});
        }
        setUpdating(false);
    };

    const undoPressed = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: any) => {
        e.stopPropagation();
        setUpdating(true);
        let result = await updateItemAsync('Pending', item);
        if (result) {
            //update item in list
            queryClient.invalidateQueries({queryKey: ['membersData']});
        }
        setUpdating(false);
    };

    const updateItemAsync = async (status: string, item: any) => {
        if (accessTokenInfo$.value != null && accessTokenInfo$.value.accessToken != null) {
            //const userName = await firstValueFrom(userId$.pipe(filter(userId => userId != null && userId !== '')));
            const res = await fetch(phpServer + `updateTransaction.php?status=${encodeURIComponent(status)}&id=${item.id}&access_token=` + accessTokenInfo$.value.accessToken);
            if (res.ok) {
                return true;
            }
        }
        return false;
    }


    const columns: TableColumnDefinition<string>[] = [
        createTableColumn<any>({
            columnId: 'id',
            renderHeaderCell: () => "id",
            renderCell: (item) => (<TableCellLayout style={{ overflowX: 'hidden', width: '50px' }} >{item.id}</TableCellLayout>),
            compare: (a, b) => a.item.localeCompare(b.item)
        }),
        createTableColumn<any>({
            columnId: 'first',
            renderHeaderCell: () => "First",
            renderCell: (item) => (<TableCellLayout style={{ overflowX: 'hidden' }} >{item.first}</TableCellLayout>),
            compare: (a, b) => a.item.localeCompare(b.item)
        }),
        createTableColumn<any>({
            columnId: 'last',
            renderHeaderCell: () => "Last",
            renderCell: (item) => (<TableCellLayout style={{ overflowX: 'hidden' }} >{item.last}</TableCellLayout>),
            compare: (a, b) => a.item.localeCompare(b.item)
        }),
        createTableColumn<any>({
            columnId: 'item',
            renderHeaderCell: () => "Item",
            renderCell: (item) => (<TableCellLayout style={{ overflowX: 'hidden' }} >{item.item}</TableCellLayout>),
            compare: (a, b) => a.item.localeCompare(b.item)
        }),
        createTableColumn<any>({
            columnId: 'total',
            renderHeaderCell: () => "Total",
            renderCell: (item) => (<TableCellLayout>${item.total}</TableCellLayout>),
            compare: (a, b) => a.total > b.total ? 1 : (a.total < b.total ? -1 : 0)
        }),
        createTableColumn<any>({
            columnId: 'payby',
            renderHeaderCell: () => "Pay by",
            renderCell: (item) => (<TableCellLayout>{item.payby}</TableCellLayout>),
            compare: (a, b) => a.payby.localeCompare(b.payby)
        }),
        createTableColumn<any>({
            columnId: 'status',
            renderHeaderCell: () => "Status",
            renderCell: (item) => (<TableCellLayout>{item.status}</TableCellLayout>),
            compare: (a, b) => a.status.localeCompare(b.status)
        }),
        createTableColumn<any>({
            columnId: 'timestamp',
            renderHeaderCell: () => "Timestamp",
            renderCell: (item) => (<TableCellLayout>{item.timestamp}</TableCellLayout>),
            compare: (a, b) => a.timestamp.localeCompare(b.timestamp)
        }),
        createTableColumn<any>({
            columnId: 'action',
            renderHeaderCell: () => "Action",
            renderCell: (item) => (<TableCellLayout>
                {getActionButtons(item)}
            </TableCellLayout>)
        }),

    ];

    const getCellFocusMode = (columnId: TableColumnId): DataGridCellFocusMode => {
        switch (columnId) {
            case "action":
                return "group";
            default:
                return "cell";
        }
    };

    const searchId = useId("searchbox");

    const onSearchChange: SearchBoxProps["onChange"] = (_ev: any, data: any) => {
        //if (data.value.length <= 20) {
        setSearchValue(data.value);
        if (data.value.trim().length === 0 && searchTerm.length > 0) {
            setSearchTerm("");
        }
        //setValid(true);
        //} else {
        //setValid(false);
        //}
    };
    const onSearchKeyDown: InputProps["onKeyDown"] = (ev: any) => {
        if (ev.key === "Enter" && searchValue.trim().length > 0) {
            //do search
            console.log(searchValue.trim());
            setSearchTerm(searchValue.trim().toLocaleLowerCase());

        }
    };

    const generalStyles = useGeneralStyles();

    return (
        <>
            {authorized ?
                <div style={{ marginBottom: '20px', display: 'relative' }}>
                    <Text className={generalStyles.groupTitle}>Transactions</Text>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', gap: '10px', marginBottom: '10px' }}>
                        <Button onClick={cancelAllPressed} disabled={selected.size > 0 ? false : true}>Cancel Selected</Button>
                        <Button onClick={approveAllPressed} disabled={selected.size > 0 ? false : true}>Confirm Selected</Button>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Label htmlFor={searchId}>Search</Label>
                            {/* <Input id={searchId} contentBefore={<SearchRegular />} placeholder='name...' 
                                value={searchValue} onChange={onSearchChange} /> */}
                            <SearchBox id={searchId} onKeyDown={onSearchKeyDown} placeholder='name...'
                                value={searchValue} onChange={onSearchChange} />
                        </div>
                    </div>
                    <Pagination pageCount={pageCount} selectedPageIndex={page} onPageChange={handlePageChange}
                        format={'buttons'}
                        previousPageAriaLabel={'previous page'}
                        nextPageAriaLabel={'next page'}
                        firstPageAriaLabel={'first page'}
                        lastPageAriaLabel={'last page'}
                        pageAriaLabel={'page'}
                        selectedAriaLabel={'selected'} />
                    <div style={{ position: 'relative' }}>
                        {transactionsData !== undefined &&
                            (<>

                                <DataGrid
                                    items={transactionsData}
                                    columns={columns}
                                    sortable
                                    selectionMode="multiselect"
                                    size='small'
                                    onSelectionChange={selectionChanged}
                                    selectedItems={selected}
                                    focusMode="composite"
                                    subtleSelection={true}
                                    style={{ minWidth: '640px' }}
                                >
                                    <DataGridHeader>
                                        <DataGridRow selectionCell={{ "aria-label": "Select all rows" }}>
                                            {({ renderHeaderCell }) => (
                                                <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                                            )}
                                        </DataGridRow>
                                    </DataGridHeader>
                                    <DataGridBody<any>>
                                        {({ item, rowId }) => (
                                            <DataGridRow<any>
                                                key={rowId}
                                                selectionCell={{ "aria-label": "Select row" }}
                                            >
                                                {({ renderCell, columnId }) => (
                                                    <DataGridCell focusMode={getCellFocusMode(columnId)}>
                                                        {renderCell(item)}
                                                    </DataGridCell>
                                                )}
                                            </DataGridRow>
                                        )}
                                    </DataGridBody>
                                </DataGrid>
                            </>)
                        }

                    </div>
                    {(isFetching || updating) &&
                        <div style={{ background: `var(--colorBackgroundOverlay)`, position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
                            <Spinner style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }} label="Loading..." size="large" />
                        </div>
                    }
                </div>
                :
                <Text className={generalStyles.groupTitle} >You are not authorized to view this page.</Text>
            }

        </>
    );
};

export default PaymentAudit;
