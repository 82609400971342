import { createContext } from 'react';

interface IToastContext{
    toasterId: string;
    notifyError: (errorMsg: string) => void;
    notifySaveSuccess: (successMessage: string) =>void;
}

export const ToastContext = createContext<IToastContext>({
    toasterId: '',
    notifyError: ()=>{},
    notifySaveSuccess: ()=>{} 
});