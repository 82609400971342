import { FC, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import {
	Text, Checkbox, Select,
	Button, Input, Label, useId, Switch,
	Toaster, useToastController, Toast, ToastTitle, ToastBody, 
} from '@fluentui/react-components';
import { bind } from '@react-rxjs/core';
import { statesArray } from './states';
import { useForm, Controller } from "react-hook-form";
import { accessTokenInfo$, isAuthorized$ } from './oauth/oauth';
import { phpServer } from './constants';
import { IMember, userStore } from './store/userStore';
import { useGeneralStyles } from './styles';

interface MainProps {
	payMethod?: string;
	membershipCost?: number;
}


interface IMembershipInfo {
	acs: boolean,
	divc: boolean;
	teacher: string;
	first: string;
	last: string;
	institution: string;
	addr1: string;
	addr2: string;
	city: string;
	st: string;
	zip: string;
	phone: string;
	email: string;
	username1: string;
	psw: string;
	password_confirmation: string;
	weblist: boolean;
	emailconsent: boolean
}


const [useIsAuthorized] = bind(isAuthorized$, isAuthorized$.value);

const MemberInfo: FC<MainProps> = (props) => {

	const { handleSubmit, control, getValues, setValue, watch, formState: { errors } } = useForm<IMembershipInfo>({
		defaultValues: {
			acs: false,
			divc: false,
			teacher: "2-year college - Full-time",
			first: "",
			last: "",
			institution: "",
			addr1: "",
			addr2: "",
			city: "",
			st: "",
			zip: "",
			phone: "",
			email: "",
			username1: "",
			psw: "",
			password_confirmation: "",
			weblist: false,
			emailconsent: false
		}
	});

	const navigate = useNavigate();

	const watchPrivacy = watch("weblist");
	const watchEmailConsent = watch("emailconsent");
	useEffect(() => {
		watchPrivacy ? setprivacyLabel("Yes, include my name.") : setprivacyLabel("No, do NOT include my name.");
		watchEmailConsent ? setemailConsentLabel("Yes, send me emails.") : setemailConsentLabel("No, do NOT send me emails.");

	}, [watchPrivacy, watchEmailConsent]);

	const authorized = useIsAuthorized();
	const [privacyLabel, setprivacyLabel] = useState<string>("No, do NOT include my name.");
	const [emailConsentLabel, setemailConsentLabel] = useState<string>("No, do NOT send me emails.");
	const [loginError, setLoginError] = useState<string>("");

	const generalStyles = useGeneralStyles();

	const teachingStatusSelect = useId('select');
	const firstInputId = useId('input');
	const lastInputId = useId('input');
	const institutionInputId = useId('input');
	const addr1InputId = useId('input');
	const addr2InputId = useId('input');
	const cityInputId = useId('input');
	const stateSelect = useId('select');
	const zipInputId = useId('input');
	const zipErrorId = useId('error-message');
	const phoneInputId = useId('input');
	const phoneErrorId = useId('error-message');
	const emailInputId = useId('input');
	const emailErrorId = useId('error-message');
	const loginInputId = useId('input');
	const loginErrorId = useId('text');
	const passwordInputId = useId('input');
	const passwordConfirmationInputId = useId('input');

	const toasterId = useId("toaster");
	const { dispatchToast } = useToastController(toasterId);
	const notify = (type: "error" | "success") => {
		if (type === 'error') {
			dispatchToast(
				<Toast>
					<ToastTitle>Error</ToastTitle>
					<ToastBody>There was a problem.  Please, check the form for errors.</ToastBody>
				</Toast>,
				{ intent: 'error' }
			);
		} else {
			dispatchToast(
				<Toast>
					<ToastTitle>Success</ToastTitle>
				</Toast>,
				{ intent: 'success' }
			);
		}
	};

	const onSubmit = async (values: any) => {
		console.log(values);
		setLoginError('');
		values.acs = (values.acs as boolean ? 'ACS' : '');
		values.divc = (values.divc as boolean ? 'DivCHED' : '');

		const phoneSplit = values.phone.split(/[-.,\sx]+/);
		values.phone1 = phoneSplit[0];
		values.phone2 = phoneSplit.length > 1 ? phoneSplit[1] : '0';
		values.phone3 = phoneSplit.length > 2 ? phoneSplit[2] : '0';
		values.phone4 = phoneSplit.length > 3 ? phoneSplit[3] : '0';

		const zipSplit = values.zip.split(/[-.,\s]/);
		values.zip1 = zipSplit[0];
		values.zip2 = zipSplit.length > 1 ? zipSplit[1] : '0';

		values.emailconsent = (values.emailconsent as boolean ? 'yes' : '');
		values.weblist = (values.weblist as boolean ? 'yes' : '');

		if (userState?.id !== undefined){
			values.id = userState?.id;
		}

		values.payby = props.payMethod;
		values.amount = props.membershipCost;
		var p = new URLSearchParams();
		if (accessTokenInfo$.value != null && accessTokenInfo$.value.accessToken != null) {
			console.log("Access token: " + accessTokenInfo$.value.accessToken);
            p.append("access_token",accessTokenInfo$.value.accessToken);
		}

		//p.append("access_token", accessTokenInfo$.value.accessToken);
		let headers = new Headers();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		for(const v in values){
			p.append(v, values[v]);
		}
		
		//headers.append('Content-Type', 'application/json');
		const response = await fetch(phpServer + "submitMemberInfo.php", { method: 'post', body: p.toString(), headers: headers });
		console.log(response);
		const result = await response.json();
		if (result.error) {
			if (result.error.includes("Duplicate")) {
				setLoginError('This username is already taken.');
			}
			notify('error');
		} else {
			notify('success');
			if (props.payMethod !== undefined && props.payMethod !== null && props.payMethod !== "") {
				if (props.payMethod	=== "online") {
					window.open(result.response, "_blank")?.focus();
				} else {
					navigate("/membership/mailpayment?amount=" + props.membershipCost);
				}

			}
		}
		console.log(result);
	};

	const [userState, setUserState] = useState<IMember | null>(userStore.currentState);
	//const queryClient = useQueryClient();
	//queryClient.
	//const userData = queryClient.getQueryData<IMember | null>('userData');
	useEffect(() => {
		userStore.subscribe((user: (IMember | null)) => {
			setUserState(user);
			if (user != null) {
				setValue("acs", user.acs === 'yes');
				setValue("divc", user.divc === "yes");
				setValue("first", user.first);
				setValue("last", user.last);
				setValue("institution", user.institution);
				setValue("addr1", user.addr1);
				setValue("addr2", user.addr2);
				setValue("city", user.city);
				setValue("st", user.st === null ? '' : user.st);
				setValue("zip", user.zip1 + (user.zip2 !== 0 ? "-" + user.zip2 : ""));
				setValue("phone", user.phone1 + "-" + user.phone2 + "-" + user.phone3 + (user.phone4 !== 0 ? (" x" + user.phone4) : ""));
				setValue("email", user.email);
				setValue("weblist", user.weblist === "yes");
				setValue("emailconsent", user.emailconsent === "yes");
				setValue("username1", user.username1);
				setValue("psw", user.psw);
				setValue("password_confirmation", user.psw);
			}
		});
	}, [setValue]);


	return (
		<>
			{authorized ? (
				<>

					{/* {expires !== undefined && expires < new Date().getFullYear() ? 
					(<>
						<Typography variant="body2" sx={{color:"red"}}>Your membership is valid until December of {expires}.</Typography>
						<Typography variant="h5">Your Membership Information</Typography>
					</>)
					:
					(<>
						<Typography variant="body2" sx={{color:"red"}}>Your membership has expired!</Typography>
						<Typography variant="h5">Membership Renewal</Typography>
					</>)
					}
				 */}

				</>
			)
				: <></>
				// (<Typography variant="h5">Welcome! Please, enter your information.</Typography>)
			}

			<div style={{  display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>

				<form onSubmit={handleSubmit(onSubmit)}
					method="POST">

					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<Label>Current Memberships:</Label>
						<Controller name="acs"
							control={control}
							render={({ field: { onChange, value } }) =>
								<Checkbox checked={value} onChange={onChange} label='ACS' />
							}
						/>



						<Controller name="divc"
							control={control}
							render={({ field: { onChange, value } }) =>
								<Checkbox checked={value} onChange={onChange} label='DivCHED' />
							}
						/>

					</div>
					<div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5em' }}>

						<Label htmlFor={teachingStatusSelect}>Teaching Status</Label>
						<Controller name="teacher"
							control={control}
							render={({ field }) =>
								<Select {...field}
									id={teachingStatusSelect}>
									<option value={"2-year college - Full-time"}>2-year college - Full-time</option>
									<option value={"2-year college - Adjunct"}>2-year college - Adjunct</option>
									<option value={"4-year college"}>4-year college</option>
									<option value={"High School"}>High School</option>
									<option value={"Other"}>Other</option>
									<option value={"Do not teach"}>Do not teach</option>
								</Select>
							}
						/>

						<Label required htmlFor={firstInputId}>First Name</Label>
						<Controller name="first"
							control={control}
							rules={{
								required: true
							}}
							render={({ field }) =>
								<Input id={firstInputId}
									{...field}
									required
								/>
							}
						/>

						<Label required htmlFor={lastInputId}>Last Name</Label>
						<Controller name="last"
							control={control}
							rules={{
								required: true
							}}
							render={({ field }) =>
								<Input id={lastInputId}
									{...field}
									required
								/>
							}
						/>

						<Label required htmlFor={institutionInputId}>Institution</Label>
						<Controller name="institution"
							control={control}
							rules={{
								required: true
							}}
							render={({ field }) =>
								<Input id={institutionInputId}
									{...field}
									required
								/>
							}
						/>

						<Label required htmlFor={addr1InputId}>Address 1</Label>
						<Controller name="addr1"
							control={control}
							rules={{
								required: true
							}}
							render={({ field }) =>
								<Input id={addr1InputId}
									{...field}
									required
								/>
							}
						/>

						<Label htmlFor={addr1InputId}>Address 2</Label>
						<Controller name="addr2"
							control={control}
							rules={{
								required: false
							}}
							render={({ field }) =>
								<Input id={addr2InputId}
									{...field}
								/>
							}
						/>

						<Label required htmlFor={cityInputId}>City</Label>
						<Controller name="city"
							control={control}
							rules={{
								required: true
							}}
							render={({ field }) =>
								<Input id={cityInputId}
									{...field}
									required
								/>
							}
						/>
						<div style={{ display: 'flex', flexDirection: 'row', columnGap: '0.5em' }}>
							<div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5em' }}>
								<Label required htmlFor={stateSelect}>State</Label>
								<Controller name="st"
									control={control}
									rules={{
										required: true
									}}
									render={({ field }) =>
										<Select
											id={stateSelect}
											{...field}
											required
										>
											{statesArray.map((v, i) => {
												return (
													<option key={i} value={v.abbr}>{v.name}</option>
												);
											})}
										</Select>
									}
								/>
							</div>
							<div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5em' }}>
								<Label required htmlFor={zipInputId}>Zip</Label>
								<Controller name="zip"
									control={control}
									defaultValue={''}
									rules={{
										required: true
									}}
									render={({ field }) =>
										<Input {...field}
											id={zipInputId}
											aria-describedby={zipErrorId}
											required
										/>
									}
								/>
								{errors.zip && (
									<Text id={zipErrorId}>{errors.zip && errors.zip.message}</Text>
								)
								}
							</div>
						</div>

						<Label htmlFor={phoneInputId}>Phone</Label>
						<Controller name="phone"
							control={control}
							defaultValue={''}
							rules={{
								required: false,
								pattern: {
									value: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})(.*?)?/i,
									message: "Use 123-456-7890 format. Extensions OK."
								}
							}}
							render={({ field }) =>
								<Input {...field}
									id={phoneInputId}
									aria-describedby={phoneErrorId}
								/>}
						/>
						{errors.phone && (
							<Text id={phoneErrorId}>{errors.phone && errors.phone.message}</Text>
						)}

						<Label required htmlFor={emailInputId}>Email</Label>
						<Controller name="email"
							control={control}
							rules={{
								required: true,
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
									message: "invalid email address"
								}
							}}
							render={({ field }) =>
								<Input {...field}
									id={emailInputId}
									aria-labelledby={emailErrorId}
									required
								/>}
						/>
						{errors.email && (
							<Text id={emailErrorId}>{errors.email && errors.email.message}</Text>
						)}
					</div>

					<div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5em' }}>
						<Text >Login Information</Text>

						<Label required htmlFor={loginInputId}>Username</Label>
						<Controller name="username1"
							control={control}
							rules={{
								required: true
							}}
							render={({ field }) =>
								<Input

									{...field}
									id={loginInputId}
									aria-describedby={loginErrorId}
									required
								/>
							}
						/>
						{loginError !== "" && (
							<Text id={loginErrorId} className={generalStyles.error}>{loginError}</Text>
						)}
						<Label required htmlFor={passwordInputId}>Password</Label>
						<Controller name="psw"
							control={control}
							rules={{
								required: true
							}}
							render={({ field }) =>
								<Input
									{...field}
									id={passwordInputId}
									type="password"
									required
								/>
							}
						/>
						{!authorized && (
							<>
								<Label required htmlFor={passwordConfirmationInputId}>Confirm Password</Label>
								<Controller name="password_confirmation"
									control={control}
									rules={{
										required: true,
										validate: {
											passwordEqual: value => (value === getValues().psw) || 'Passwords do NOT match.',
										}
									}}
									render={({ field }) =>
										<Input
											{...field}
											id={passwordConfirmationInputId}
											type="password"
											required
										/>
									}
								/>
								{errors.password_confirmation && (
									<Text>{errors.password_confirmation && errors.password_confirmation.message as string}</Text>
								)}
							</>)}
					</div>

					<div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5em' }}>
						<Text className={generalStyles.groupTitle}>Privacy Policy</Text>
						<Text >The 2YC3 lists current dues-paying members on the 2YC3 website.<br />
							The list includes names, institutional affiliation, and membership expiration date only.<br />
							Email addresses and phone numbers are NOT listed.
						</Text>


						<Controller name="weblist"
							control={control}
							defaultValue={false}
							render={({ field }) =>
								<Switch
									checked={field.value}
									onChange={field.onChange}
									label={privacyLabel}
								// onChange={(e, data) => {
								// 	field.value =  data.checked;
								// 	//setValue("weblist", data.checked);
								// 	return data.checked ? setprivacyLabel("Yes, include my name.") : setprivacyLabel("No, do NOT include my name.")
								// }} />
								/>
							}
						/>

					</div>

					<div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5em' }}>
						<Text className={generalStyles.groupTitle}>Email Use Policy</Text>
						<Text>Giving 2YC3 consent for the use of your email address will allow us to send you relevant information via email.<br />
							Your email address will not be distributed to third parties.
						</Text>

						<Controller name="emailconsent"
							control={control}
							defaultValue={false}
							render={({ field }) =>
								<Switch
									label={emailConsentLabel}
									checked={field.value}
									onChange={field.onChange}
								// onChange={(e, data) => {
								// 	setValue("emailconsent", (e.target as HTMLInputElement).checked);
								// 	return (e.target as HTMLInputElement).checked ? setemailConsentLabel("Yes, I would like to receive emails from 2YC3.") : setemailConsentLabel("No, do NOT send me emails.")
								// }} />
								/>
							}
						/>


					</div>

					<Button type="submit" appearance='primary'>Submit</Button>
					<Toaster toasterId={toasterId} />
				</form>
				
			</div>

		</>);
};

export default MemberInfo;
