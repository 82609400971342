import React, { FC, useRef } from 'react';
import { QueryClient } from '@tanstack/react-query'
import { Button, Image, Card, Body1Strong, Subtitle1, CardHeader, Text, CardFooter, Input, Label, useId, makeStyles, Textarea } from '@fluentui/react-components';
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { TimePicker } from "@fluentui/react-timepicker-compat";
import { Presenter } from './meetingUtils';
import { useGeneralStyles } from '../styles';
import { MeetingData } from './meetingUtils';
import { DeleteRegular } from '@fluentui/react-icons';
import FilePicker from '../FilePicker';
import { phpServer } from '../constants';

interface SinglePresenterMeetingProps {
	id: number;
	component: string;
	title: string;
	date: Date;
	time: Date;
	imgSrc: string;
	registerLink: string;
	presenters: Presenter[];
	content: string;
	isEditing?: boolean;
	onChange?: (props: MeetingData) => void;
	onDeleteMeeting?: (props: MeetingData) => void;
	uploadImageAsync?: (file: File) => Promise<string | null>;
}
export const queryClient = new QueryClient();

const useStyles = makeStyles({
	header: {
		// Stack the label above the field
		display: "flex",
		flexDirection: "column",
		// Use 2px gap below the label (per the design system)
		gap: "2px",
		// Prevent the example from taking the full width of the page (optional)
		width: "100%",
	},
	card: {
		marginTop: '5px',
		marginBottom: '5px',
		marginLeft: '2px',
		marginRight: '2px',
		alignItems: 'center',
		//width: 'calc(100% - 4px)'
	}
});

function PropsToMeetingData(props: SinglePresenterMeetingProps): MeetingData {
	return {
		id: props.id,
		component: props.component,
		title: props.title,
		date: props.date,
		time: props.time,
		imgSrc: props.imgSrc,
		registerLink: props.registerLink,
		presenters: props.presenters,
		content: props.content
	}
}

function formatDateTime(date: Date, time: Date): string {
	let output = date.toDateString();
	output += ' at ';
	output += time.toLocaleTimeString('en-US', { timeZone: 'America/Los_Angeles', hour: 'numeric', minute: '2-digit' }).split(' ').join('').toLocaleLowerCase() + ' Pacific / ';
	output += time.toLocaleTimeString('en-US', { timeZone: 'America/Denver', hour: 'numeric', minute: '2-digit' }).split(' ').join('').toLocaleLowerCase() + ' Mountain / ';
	output += time.toLocaleTimeString('en-US', { timeZone: 'America/Chicago', hour: 'numeric', minute: '2-digit' }).split(' ').join('').toLocaleLowerCase() + ' Central / ';
	output += time.toLocaleTimeString('en-US', { timeZone: 'America/New_York', hour: 'numeric', minute: '2-digit' }).split(' ').join('').toLocaleLowerCase() + ' Eastern';
	return output;
}

const SinglePresenterMeeting: FC<SinglePresenterMeetingProps> = (props) => {

	const generalStyles = useGeneralStyles();
	const styles = useStyles();

	const titleInputId = useId("titleInputId");
	const dateInputId = useId("dateInputId");
	const timeInputId = useId("timeInputId");
	const registerLinkInputId = useId("registerLinkInputId");
	const imagePickerId = useId("imagePickerId");
	const imgSrcInputId = useId("imgSrcInputId");
	const imgSrcRef = useRef<HTMLInputElement>(null);

	const nameInputId = useId("nameInputId");
	const detailsInputId = useId("detailsInputId");

	const abstractInputId = useId("abstractInputId");
	// useEffect(()=>{
	// 	console.log("EFFECT");
	// 	console.log(props.time);
	// });



	return (
		<>
			{props.isEditing ?
				<>
					<Card className={styles.card}>
						<div>
							<Button appearance='secondary' style={{ color: 'red' }} icon={<DeleteRegular />}
								onClick={e => {
									if (props.onDeleteMeeting) {
										props.onDeleteMeeting(PropsToMeetingData(props));
									}
								}}>Delete</Button>
						</div>
						<div className={styles.header}>
							<Label htmlFor={titleInputId} size="large">Title</Label>
							<Input id={titleInputId} defaultValue={props.title} size='large' style={{ maxWidth: 'none', width: '100%' }}
								onChange={(e) => {
									const propCopy: MeetingData = PropsToMeetingData(props);
									propCopy.title = e.currentTarget.value;
									if (props.onChange) {
										props.onChange(propCopy);
									}
								}}></Input>
						</div>
						{/* <div style={{ display: 'flex', flexDirection: 'row' }}> */}
							<div className={styles.header}>
								<Label htmlFor={dateInputId} size="medium">Date</Label>
								<DatePicker id={dateInputId} value={props.date} size="medium" style={{ maxWidth: 'none', width: '100%' }} onSelectDate={(date) => {
									const propCopy: MeetingData = PropsToMeetingData(props);
									propCopy.date = (date && date !== null) ? date : new Date(Date.now());
									console.log("CHANGING");
									if (props.onChange) {
										props.onChange(propCopy);
									}
								}} placeholder="Select a date..."></DatePicker>
							</div>
							<div className={styles.header}>
								<Label htmlFor={timeInputId} size="medium">Time</Label>
								<TimePicker id={timeInputId} style={{ maxWidth: 'none', width: '100%' }} size="medium"
									defaultValue={props.time.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true })}
									selectedTime={props.time}
									onTimeChange={(e, data) => {
										const propCopy: MeetingData = PropsToMeetingData(props);
										// console.log("SELECTED");
										// console.log(data.selectedTimeText);
										// console.log(data.selectedTime);
										propCopy.time = (data.selectedTime && data.selectedTime !== null) ? data.selectedTime : new Date((new Date(Date.now())).getTime());
										if (props.onChange) {
											props.onChange(propCopy);
										}
									}}
									onChange={(e) => {
										const propCopy: MeetingData = PropsToMeetingData(props);
										console.log("CHANGE");
										console.log(e.currentTarget.value);
										// //propCopy.time = (data.selectedTime && data.selectedTime !== null) ? data.selectedTime : new Date((new Date(Date.now())).getTime());
										if (props.onChange) {
											props.onChange(propCopy);
										}
									}}

									placeholder="Select a time..." ></TimePicker>
							</div>
						{/* </div> */}
						<div className={styles.header}>
							<Label htmlFor={registerLinkInputId} size="medium">Registration Link Source</Label>
							<Input id={registerLinkInputId} defaultValue={props.registerLink} size='medium' style={{ maxWidth: 'none', width: '100%' }}
								onChange={(e) => {
									const propCopy: MeetingData = PropsToMeetingData(props);
									propCopy.registerLink = e.currentTarget.value;
									if (props.onChange) {
										props.onChange(propCopy);
									}
								}}></Input>
						</div>



						<div className={styles.header}>
							<Label htmlFor={imagePickerId} size="medium">Image Upload</Label>
							<FilePicker id={imagePickerId}
								onChange={async (file) => {
									//immediate upload
									if (props.uploadImageAsync) {
										const result = await props.uploadImageAsync(file);
										if (result == null && imgSrcRef.current) {
											const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
												window.HTMLInputElement.prototype,
												'value')!.set;
											nativeInputValueSetter!.call(imgSrcRef.current, "https://2yc3.org/meetings_files/"+ file.name);
												// previous 2 lines required because we're using React.  
											var event = new Event('change',{ bubbles: true });											
											imgSrcRef.current.dispatchEvent(event);
										}
									}
								}}
							>Choose an image to upload...</FilePicker>
						</div>
						<div className={styles.header}>
							<Label htmlFor={imgSrcInputId} size="medium">Image Source</Label>
							<Input id={imgSrcInputId} defaultValue={props.imgSrc} size='medium' style={{ maxWidth: 'none', width: '100%' }}
								placeholder='Enter an image url manually or use the FilePicker button above to upload your own.'
								ref={imgSrcRef}
								onChange={(e) => {
									const propCopy: MeetingData = PropsToMeetingData(props);
									propCopy.imgSrc = e.currentTarget.value;
									if (props.onChange) {
										props.onChange(propCopy);
									}
								}}></Input>
						</div>

						{props.imgSrc === "" ? <></> :
							<div style={{ justifyContent: 'space-around', display: 'flex' }}>
								<Image style={{ width: 320, height: 300 }} fit='contain' src={props.imgSrc} />
							</div>
						}
						{props.presenters.map((v, i) => (
							<div key={i} className={styles.header}>
								<div className={styles.header}>
									<Label htmlFor={nameInputId} size="medium">Presenter Name</Label>
									<Input id={nameInputId} defaultValue={v.name} size='medium' style={{ maxWidth: 'none', width: '100%' }}
										onChange={(e) => {
											const propCopy: MeetingData = PropsToMeetingData(props);
											propCopy.presenters[i].name = e.currentTarget.value;
											if (props.onChange) {
												props.onChange(propCopy);
											}
										}}></Input>
								</div>
								<div className={styles.header}>
									<Label htmlFor={detailsInputId} size="medium">Presenter Details</Label>
									<Input id={detailsInputId} defaultValue={v.details} size='medium' style={{ maxWidth: 'none', width: '100%' }}
										onChange={(e) => {
											const propCopy: MeetingData = PropsToMeetingData(props);
											propCopy.presenters[i].details = e.currentTarget.value;
											if (props.onChange) {
												props.onChange(propCopy);
											}
										}}></Input>
								</div>

							</div>
						))}

						<div className={styles.header}>
							<Label htmlFor={abstractInputId} size="medium">Description/Abstract</Label>
							<Textarea id={abstractInputId} defaultValue={props.content} size='medium' style={{ maxWidth: 'none', width: '100%', height: 400 }}
								onChange={(e) => {
									const propCopy: MeetingData = PropsToMeetingData(props);
									propCopy.content = e.currentTarget.value;
									if (props.onChange) {
										props.onChange(propCopy);
									}
								}}></Textarea>
						</div>


					</Card>
				</>

				:

				<Card className={styles.card}>
					<CardHeader header={
						<Subtitle1>{props.title}</Subtitle1>
					} />
					<div>
						<Body1Strong color="text.secondary">
							{(props.date && props.time) ? formatDateTime(props.date, props.time) : "null"}
						</Body1Strong>
					</div>
					<CardFooter>
						<Button as="a" target='_blank' size="large" color="primary" href={props.registerLink}>
							Register Here
						</Button>
					</CardFooter>
					<div>
						{props.imgSrc === "" ? <></> :
							<div style={{ justifyContent: 'space-around', display: 'flex' }}>
								<Image style={{ width: 320, height: 300 }} fit='contain' src={props.imgSrc} />
							</div>
						}
						{props.presenters.map((v, i) => (
							<div key={i}>
								<Text className={generalStyles.body2} >
									{v.name}<div></div>
								</Text>
								<Text className={generalStyles.caption} >
									{v.details}
								</Text>
							</div>
						))}

						<br></br>
						<Text className={generalStyles.body1} color="text.secondary">
							{props.content}
						</Text>
						{/* <Parallax  bgImage={props.imgSrc} strength={-200}>
						<div style={{ height: 400 }}>
							<div >HTML inside the parallax</div>
						</div>
					</Parallax> */}

					</div>

				</Card>
			}

		</>
	);
};

export default SinglePresenterMeeting;
