import { BehaviorSubject } from 'rxjs';
import { phpServer } from '../constants';
import React from 'react';

interface AccessTokenInfo {
	accessToken: string | null;
	refreshToken?: string;
	expiration: number | null;
}

const oauthClientUsername: string = "testclient";
const oauthClientPassword: string = "testpass";
let timeOutToken: any;

async function expires() {
	// need to get refresh token.  
	timeOutToken = null;

	const result = await refreshAsync();

	if (!result && isAuthorized$.value) {
		isAuthorized$.next(false);
	}

}


export async function loginAsync(username: string = "", password: string = ""): Promise<boolean> {
	var p = new URLSearchParams();
	p.append("grant_type", "password");
	p.append("username", username ?? "");
	p.append("password", password ?? "");
	let headers = new Headers();
	headers.append('Content-Type', 'application/x-www-form-urlencoded');
	headers.append('Authorization', 'Basic ' + btoa(oauthClientUsername + ":" + oauthClientPassword));
	try {
		const tokenResponse = await fetch(phpServer + 'token.php', { method: 'POST', body: p.toString(), headers: headers });
		const token = await tokenResponse.json();
		console.log(token);

		let tokenInfo = {
			accessToken: token.access_token,
			refreshToken: token.refresh_token,
			expiration: (+token.expires_in * 1000) + Date.now()
		};
		accessTokenInfo$.next(tokenInfo);
		storeOAuthToken(tokenInfo);
		return true;
	} catch (e){
		console.log(e);
		console.log("ERROR LOGIN");
		return false;
	}
}

export async function logoutAsync() {
	if (timeOutToken != null) {
		clearTimeout(timeOutToken);
	}

	localStorage.clear();
	if (isAuthorized$.value) {
		isAuthorized$.next(false);
	}
	accessTokenInfo$.next(null);
	userId$.next('');
}

export async function refreshAsync(): Promise<boolean> {
	if (accessTokenInfo$.value != null && accessTokenInfo$.value.refreshToken != null) {
		try {
			var p = new URLSearchParams();
			p.append("grant_type", "refresh_token");
			p.append("refresh_token", accessTokenInfo$.value.refreshToken);
			let headers = new Headers();
			headers.append('Content-Type', 'application/x-www-form-urlencoded');
			headers.append('Authorization', 'Basic ' + btoa(oauthClientUsername + ":" + oauthClientPassword));
			const tokenResponse = await fetch(phpServer + 'token.php', { method: 'POST', body: p.toString(), headers: headers });
			if (!tokenResponse.ok) {
				return false;
			}
			const token = await tokenResponse.json();
			let tokenInfo = {
				accessToken: token.access_token,
				refreshToken: token.refresh_token,
				expiration: (+token.expires_in * 1000) + Date.now()
			};
			accessTokenInfo$.next(tokenInfo);
			storeOAuthToken(tokenInfo);
			return true;
		} catch {
			return false;
		}
	} else {
		return false;
	}
}

async function getUserIdAsync(abortController: AbortController) {
	if (accessTokenInfo$.value != null && accessTokenInfo$.value.accessToken != null) {
		try {
			var p = new URLSearchParams();
			p.append("access_token", accessTokenInfo$.value?.accessToken);
			let headers = new Headers();
			headers.append('Content-Type', 'application/x-www-form-urlencoded');
			const signal = abortController.signal;

			const tokenResponse = await fetch(phpServer + 'user.php', { method: 'POST', body: p.toString(), headers: headers, signal: signal });
			const user = await tokenResponse.json();
			console.log(user);
			userId$.next(user.user_id);

		} catch (err) {
			console.log(err);
			userId$.next("");
			return false;
		}
	} else {
		userId$.next("");
	}
}

function retrieveOAuthToken(): AccessTokenInfo {
	const accessToken = localStorage.getItem('access_token');
	const expiration = localStorage.getItem('auth_expiration');
	const refreshToken = localStorage.getItem('refresh_token');
	if (expiration != null) {
		timeOutToken = setTimeout(expires, (+expiration - Date.now()) * 1);
	}
	if (refreshToken == null) {
		return {
			accessToken: accessToken,
			expiration: expiration == null ? null : +expiration
		};
	} else {
		return {
			accessToken: accessToken,
			refreshToken: refreshToken,
			expiration: expiration == null ? null : +expiration
		};
	}
}

function storeOAuthToken(accessTokenInfo: AccessTokenInfo): void {
	if (accessTokenInfo.accessToken != null)
		localStorage.setItem('access_token', accessTokenInfo.accessToken);
	if (accessTokenInfo.expiration != null)
		localStorage.setItem('auth_expiration', accessTokenInfo.expiration.toString());
	if (accessTokenInfo.refreshToken != null)
		localStorage.setItem('refresh_token', accessTokenInfo.refreshToken);

	if (timeOutToken != null) {
		clearTimeout(timeOutToken);
	}
	if (accessTokenInfo.accessToken != null && accessTokenInfo.expiration != null && accessTokenInfo.expiration > Date.now()) {
		setTimeout(expires, (+accessTokenInfo.expiration - Date.now()) * 1);
		isAuthorized$.next(true);
	} else {
		isAuthorized$.next(false);
	}
}

let accessTokenInitial = retrieveOAuthToken();

export const accessTokenInfo$ = new BehaviorSubject<AccessTokenInfo | null>(accessTokenInitial);

export const isAuthorized$ = new BehaviorSubject<boolean>(accessTokenInitial.accessToken != null && accessTokenInitial.expiration != null && accessTokenInitial.expiration > Date.now());

export const userId$ = new BehaviorSubject<string>("");

let abortController: AbortController | null;
isAuthorized$.subscribe(async x => {
	if (x) {
		if (abortController != null) {
			abortController.abort();
		}
		abortController = new AbortController();
		await getUserIdAsync(abortController);
		abortController = null;

	}
});

export let setLoginOpen: React.Dispatch<React.SetStateAction<boolean>> | null = null;
export function registerLoginSwitch(setOpenParameter: React.Dispatch<React.SetStateAction<boolean>>): void {
	setLoginOpen = setOpenParameter;
};

