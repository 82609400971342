import React, { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Text } from '@fluentui/react-components';
import { useGeneralStyles } from './styles';

interface MailPaymentProps {
    amount?: number;
}
//export const queryClient = new QueryClient();

const MailPayment: FC<MailPaymentProps> = (props) => {

    const generalStyles = useGeneralStyles();
    let [searchParams] = useSearchParams();
    let amount = searchParams.get("amount");

    return (
        <Text className={generalStyles.body2}>Make checks payable to 2YC3.
                                <br /><br />
                                {amount !== null ? `Amount: $${amount}` : ""}
								<br /><br />
								Mail check to:<br />
								Jonathan Gittins<br />
								Delta College<br />
								1961 Delta Road<br />
								University Center, MI 48710
							</Text>
    );

}

export default MailPayment;