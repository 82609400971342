import { Button, Input, makeStyles } from '@fluentui/react-components';
import React, { FC, useRef } from 'react';

//'http://beta.2yc3.org/'; //

interface FilePickerProps {
    children?: any;
    id?:string;
    onChange?: (file:File)=>void;
}

const useComponentStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',

    },
    button: {
        position: 'relative',
        overflow: 'hidden',
        cursor: 'pointer',
        // textAlign: 'center',
        left: 0,
        display: 'block',
        borderTopLeftRadius: 'var(--borderRadiusMedium)',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 'var(--borderRadiusMedium)'

    },
    fileInput: {
        zIndex: 999,
        lineHeight: 0,
        //fontSize: '50px',
        position: 'absolute',
        opacity: 0,
        //filter: 'alpha(opacity = 0)',
        cursor: 'pointer',
        _cursor: 'hand',
        margin: 0,
        padding: 0,
        left: 0,
        top: 0,
        height: '32px',
        backgroundColor: 'yellow'
    },
    textInput: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 'var(--borderRadiusMedium)',
        borderBottomRightRadius: 'var(--borderRadiusMedium)',
        borderBottomLeftRadius: 0
        //        ...shorthands.margin('10px', '0px', '10px', '0px')
    }
});

const FilePicker: FC<FilePickerProps> = (props) => {
    const componentStyles = useComponentStyles();

    const fileInputRef = useRef<HTMLInputElement>(null);
    const [file, setFile] = React.useState<File|null>(null);
    const [fileName, setFileName] = React.useState<string>("No file chosen");

    return (
        <div className={componentStyles.root} id={props.id}>
            <Button className={componentStyles.button} onClick={e => {
                if (fileInputRef.current) {
                    fileInputRef.current.click();
                }
            }}>
                {props.children || "Choose File"}
                <span>
                    <input ref={fileInputRef} className={componentStyles.fileInput} tabIndex={-1}
                        type='file' onChange={e => {
                            
                            if (e.currentTarget.files && e.currentTarget.files.length > 0){
                                const file = e.currentTarget.files.item(0);
                                setFile(file!);
                                setFileName(file!.name);
                                if (props.onChange){
                                    props.onChange(file!);
                                }
                            }
                            console.log(e.currentTarget.files);
                        }}
                        onClick={e => e.stopPropagation()} />
                </span>
            </Button>

            <Input className={componentStyles.textInput} value={fileName} readOnly />
        </div>
    );
}

export default FilePicker;
