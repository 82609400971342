import React, { FC } from 'react';
import { QueryClient, useQuery } from '@tanstack/react-query'
import {Document28Regular as DocumentRegular, Image28Regular as ImageRegular} from '@fluentui/react-icons';
import { Link, Text } from '@fluentui/react-components';
import { useGeneralStyles } from './styles';


interface LinksProps {

}
export const queryClient = new QueryClient();

interface FormsFilesData {
	component: string;
	link: string;
	content: string;
	type: string;
}

const FormsFiles: FC<LinksProps> = () => {

	const { data: formsfilesData } = useQuery({queryKey: ['formsfilesJson'], refetchOnWindowFocus:true, queryFn: async () => {
		const res = await fetch("/data/formsfiles.json");
		if (!res.ok) {
			throw new Error('Network response was not ok')
		}
		const formsfilesData: FormsFilesData[] = await res.json();

		return formsfilesData;
	}});


	const icon = (type: string) => {
		switch (type) {
			case "document":
				return <DocumentRegular />;
			case "image":
				return <ImageRegular />;
			default:
				return <DocumentRegular />;
		}
	}

	const generalStyles = useGeneralStyles();

	return (
		<>
			<div style={{ display: 'flex', flexDirection: 'column', rowGap: '1em' }}>
					{formsfilesData === undefined ? (<></>) : formsfilesData.map((v, i) => {
						switch (v.component) {
							case "DefaultFile":
								return (
									<Link as="a" key={i} onClick={e => window.open(v.link, '_blank')} >
										<div style={{display:'flex', flexDirection: 'row', alignItems:'center'}}>
										{icon(v.type)}
										<Text className={generalStyles.body2} style={{ wordBreak: 'break-word', marginLeft: '10px' }}>
											{v.content}
										</Text>
										</div>
									</Link>
								);
							default:
								return "NO MATCH";
						}
					})}

			</div>

		</>
	);
};

export default FormsFiles;
