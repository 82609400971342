import React, { useState, FC } from 'react';
import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query'
import { phpServer } from './constants';
import { Link, Text, Input, Label, Button, useId, Combobox, Option, makeStyles, Radio, RadioGroup } from '@fluentui/react-components';
import { bind } from '@react-rxjs/core';
import { accessTokenInfo$, isAuthorized$ } from './oauth/oauth';
import { states } from './states';
import { useGeneralStyles } from './styles';
import { userStore } from './store/userStore';

interface CollegeSponsorsProps {

}
export const queryClient = new QueryClient();

const [useIsAuthorized] = bind(isAuthorized$, isAuthorized$.value);

const usePageStyles = makeStyles({
	listbox: {
		maxHeight: '300px'
	}
})

const CollegeSponsorshipPayment: FC<CollegeSponsorsProps> = () => {
	const queryClient = useQueryClient();
	const authorized = useIsAuthorized();
	const [firstName, setFirstName] = useState<string>("");
	const [lastName, setLastName] = useState<string>("");
	const [institution, setInstitution] = useState<string>("");
	const [address1, setAddress1] = useState<string>("");
	const [address2, setAddress2] = useState<string>("");
	const [city, setCity] = useState<string>("");
	const [state, setState] = useState<string | null>(null);
	const [zip, setZip] = useState<string>("");
	const [phone, setPhone] = useState<string>("");
	const [phoneExt, setPhoneExt] = useState<string>("");
	const [email, setEmail] = useState<string>("");
	const [payBy, setPayBy] = useState<string | null>(null);
	const [errorPayBy, setErrorPayBy] = useState<boolean>(false);
	const [errorEmail, setErrorEmail] = useState<boolean>(false);

	const [submitted, setSubmitted] = useState<boolean>(false);

	const [isWaiting, setIsWaiting] = useState<boolean>(false);
	const [finalUrl, setFinalUrl] = useState<string>("");
	//const finalUrl = "";

	let firstTimeSubscribed = true;
	isAuthorized$.subscribe(x => {
		if (x && !firstTimeSubscribed) {
			queryClient.invalidateQueries({queryKey: ['userData']});
		}
		firstTimeSubscribed = false;
	});

	const { data: userData } = useQuery({queryKey: ['userData'],refetchOnWindowFocus: false , queryFn: async () => {
		if (accessTokenInfo$.value != null && accessTokenInfo$.value.accessToken != null) {
			// var p = new URLSearchParams();
			// p.append("access_token", accessTokenInfo$.value.accessToken);
			// let headers = new Headers();
			// headers.append('Content-Type', 'application/x-www-form-urlencoded');
			// //headers.append('Authorization', 'Basic ' + btoa(oauthClientUsername + ":" + oauthClientPassword));
			// const res = await fetch(phpServer + 'userData.php', { method: 'POST', body: p.toString(), headers: headers });
			// if (!res.ok) {
			// 	throw new Error('Network response was not ok')
			// }
			// const userJson: any = await res.json();
			const currentUser = userStore.currentState();
			if (currentUser != null) {

				// console.log(userJson[0]);
				setFirstName(currentUser.first);
				setLastName(currentUser.last);
				setInstitution(currentUser.institution);
				setAddress1(currentUser.addr1);
				setAddress2(currentUser.addr2);
				setCity(currentUser.city);
				setZip((currentUser.zip1 == null ? "" : currentUser.zip1) + (currentUser.zip2 == null ? "" : '-' + currentUser.zip2));
				setPhone((currentUser.phone1 == null ? "" : currentUser.phone1) + (currentUser.phone2 == null ? "" : "-" + currentUser.phone2) + (currentUser.phone3 == null ? "" : "-" + currentUser.phone3));
				setPhoneExt((currentUser.phone4 == null ? "" : currentUser.phone4.toString()));
				setEmail(currentUser.email);
				console.log('here');
				console.log(currentUser);
				return currentUser;
			}
			return null;

			// setFirstName(userJson[0].first);
			// setLastName(userJson[0].last);
			// setInstitution(userJson[0].institution);
			// setAddress1(userJson[0].address1);
			// setAddress2(userJson[0].address2);
			// setCity(userJson[0].city);
			// setZip((userJson[0].zip1 == null ? "" : userJson[0].zip1) + (userJson[0].zip2 == null ? "" : '-' + userJson[0].zip2));
			// setPhone((userJson[0].phone1 == null ? "" : userJson[0].phone1) + (userJson[0].phone2 == null ? "" : "-" + userJson[0].phone2) + (userJson[0].phone3 == null ? "" : "-" + userJson[0].phone3));
			// setPhoneExt((userJson[0].phone4 == null ? "" : userJson[0].phone4));
			// setEmail(userJson[0].email);
			// return userJson[0];
		}
	}});

	const stateItems = [];
	for (let key in states) {
		//		stateItems.push({label:key, short: key})
		stateItems.push(key);
	}

	const handleSubmit = async () => {
		setIsWaiting(true);
		//reset errors
		setErrorEmail(false);
		setErrorPayBy(false);
		//const emailRegex=/[\w!#$%&'*+\-/=?\^_`{|}~]+(\.[\w!#$%&'*+\-/=?\^_`{|}~]+)*@((([\-\w]+\.)+[a-zA-Z]{2,4})|(([0-9]{1,3}\.){3}[0-9]{1,3}))/;
		const emailRegex = /[\w!#$%&'*+\-/=?^_`{|}~]+(\.[\w!#$%&'*+\-/=?^_`{|}~]+)*@((([-\w]+\.)+[a-zA-Z]{2,4})|(([0-9]{1,3}\.){3}[0-9]{1,3}))/;
		let emailMatch = email.match(emailRegex);
		if (emailMatch == null) {
			setErrorEmail(true);
			setIsWaiting(false);
			return;
		}
		if (payBy == null) {
			setErrorPayBy(true);
			setIsWaiting(false);
			return;
		}

		const splitzip = zip.split('-');
		const phoneRegex = /\(?([0-9]{3})\)?[-.\s]?([0-9]{3})[-.\s]?([0-9]{4})/;
		let phoneMatch = phone.match(phoneRegex);
		if (phoneMatch == null) {
			phoneMatch = [phone];
		}

		const currentUser = userStore.currentState();
		console.log(currentUser);
		const data = {
			"member": authorized ? 'member' : 'not a member',
			"other": '',
			"first": firstName,
			"last": lastName,
			"institution": institution,
			"address1": address1,
			"address2": address2,
			"city": city,
			"st": state,
			"zip1": +splitzip[0],
			"zip2": splitzip.length > 1 ? +splitzip[1] : null,
			"phone1": phoneMatch.length > 1 ? phoneMatch[1] : phoneMatch[0],
			"phone2": phoneMatch.length > 2 ? phoneMatch[2] : null,
			"phone3": phoneMatch.length > 3 ? phoneMatch[3] : null,
			"phone4": phoneExt,
			"email": email,
			"weblist": userData?.weblist,
			"amount": 50,
			"payby": payBy,
			"username1": currentUser?.username1,
			"emailconsent": userData?.emailconsent,
			"extra3": userData?.extra3
		}

		try {
			console.log(data);
			const response = await fetch(phpServer + "submitCollegeSponsorship.php", { method: 'POST', body: JSON.stringify(data) });
			if (response.ok) {
				const result = await response.json();
				console.log(result);
				if (result.error) {
					//There was an error.
				} else {
					setSubmitted(true);
					if (result.response === "OK"){
						// show thank you message and go back.
						//console.log("OK");
					} else {
						setFinalUrl(result.response)
						//window.open(result.response, '_blank');
					}
				}

			} else {
				console.log(response);
			}
			setIsWaiting(false);
		} catch (ex){
			console.log(ex);
			setIsWaiting(false);
		}
	};


	const firstId = useId('input');
	const lastId = useId('input');
	const institutionId = useId('input');
	const addr1Id = useId('input');
	const addr2Id = useId('input');
	const cityId = useId('input');
	const stateId = useId('select');
	const zipId = useId('input');
	//const zipErrorId = useId('text');
	const phoneId = useId('input');
	const phoneExtId = useId('input');
	//const phoneErrorId = useId('text');
	const emailId = useId('input');
	const emailErrorId = useId('text');

	const payById = useId('radiogroup');

	const generalStyles = useGeneralStyles();
	const pageStyles = usePageStyles();

	return (
		<>

			<>
				{!submitted ?
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<Text className={generalStyles.body2}>
							Please consider supporting the 2YC3 by having your college become College Sponsor.<br /><br />

							The annual fee is only $50. You will receive a certificate suitable for framing to display your sponsorship.
							Please note that your college sponsorship is not complete until your payment is received.<br /><br />

							Please contact the college sponsor chair at <Link href="mailto:collegesponsors@2yc3.org">collegesponsors@2yc3.org</Link> if
							you have any questions<br />

						</Text>
						{/* <FormControl fullWidth > */}

						<Label required htmlFor={firstId}>First Name</Label>
						<Input
							id={firstId}
							value={firstName}
							onChange={(ev) => setFirstName(ev.currentTarget.value)}
							required
						/>
						<Label required htmlFor={lastId}>Last Name</Label>
						<Input
							id={lastId}
							value={lastName}
							onChange={(ev) => setLastName(ev.currentTarget.value)}
							required
						/>
						<br />
						<Label required htmlFor={institutionId}>Institution</Label>
						<Input
							id={institutionId}
							value={institution}
							onChange={(ev) => setInstitution(ev.currentTarget.value)}
							required
						/>
						<Label required htmlFor={addr1Id}>Address 1</Label>
						<Input
							id={addr1Id}
							value={address1}
							onChange={(ev) => setAddress1(ev.currentTarget.value)}
							required
						/>
						<Label htmlFor={addr2Id}>Address 2</Label>
						<Input
							id={addr2Id}
							value={address2}
							onChange={(ev) => setAddress2(ev.currentTarget.value)}
						/>
						<Label required htmlFor={cityId}>City</Label>
						<Input
							id={cityId}
							value={city}
							onChange={(ev) => setCity(ev.currentTarget.value)}
							required
						/>
						<Label required id={stateId}>State</Label>
						<Combobox aria-labelledby={stateId}
							listbox={{ className: pageStyles.listbox }}
							value={state ?? undefined}
							onInput={(ev: React.ChangeEvent<HTMLInputElement>) => setState(ev.target.value)}>
							{stateItems.map((state) => (
								<Option key={state} >
									{state}
								</Option>
							))}
						</Combobox>
						{/* <Autocomplete
						options={stateItems}
						onChange={(ev, newValue: string | null) => setState(newValue)}
						sx={{ width: 210, display: "inline-flex", margin: "8px 0 4px 0" }}
						renderInput={(params) => <Input required {...params}  />} /> */}
						{/* <TextField 	
								margin='dense'							
								id="state"
								value={state}
								label="State"
								onChange={(ev)=>setState(ev.currentTarget.value)}
								required
							/> */}
						<Label required htmlFor={zipId}>Zip</Label>
						<Input
							id={zipId}
							value={zip}
							type="number"
							onChange={(ev) => setZip(ev.currentTarget.value)}
							required
						/>
						<br />
						<Label htmlFor={phoneId}>Work Phone</Label>
						<Input
							id={phoneId}
							value={phone}
							type="tel"
							onChange={(ev) => setPhone(ev.currentTarget.value)}
						/>
						<Label htmlFor={phoneExtId}>Extension</Label>
						<Input
							id={phoneExtId}
							value={phoneExt}
							type="number"
							onChange={(ev) => setPhoneExt(ev.currentTarget.value)}
						/>
						<br />
						<Label required htmlFor={emailId}>Email</Label>
						<Input
							id={emailId}
							value={email}
							type="email"
							onChange={(ev) => setEmail(ev.currentTarget.value)}
							required
						/>
						{errorEmail && (
							<Text id={emailErrorId}>{errorEmail ? "Need valid email address." : ""}</Text>
						)}
						<br />

						<Label id={payById}>Total: $50 - Pay by:</Label>
						<RadioGroup
							value={payBy ?? undefined}
							onChange={(_, data) => setPayBy(data.value)}
							aria-labelledby={payById}
							name="row-radio-buttons-group"
						>
							<Radio value="Online" label="Online" />
							<Radio value="Check" label="By check" />
						</RadioGroup>
						{!errorPayBy ? <></> :
							<Text color='red'>You need to select a payment option.</Text>}
						<br />
						<Button disabled={isWaiting} appearance='primary' type="submit" onClick={() => handleSubmit()}>Submit</Button>

						{/* </FormControl> */}

					</div>
					:
					<div>
						<Text className={generalStyles.body2}>
							Your data has been successfully submitted.
							<br />
							<br />
							Thank you!
							<br />
							<br />
						</Text>
						{payBy === 'Check' ?
							<Text className={generalStyles.body2}>Make checks payable to 2YC3.
								<br /><br />
								Mail check to:<br />
								Jonathan Gittins<br />
								Delta College<br />
								1961 Delta Road<br />
								University Center, MI 48710
							</Text>
							:
							<>
								<Text className={generalStyles.body2}>Please click the following link to securely pay using paypal:
								</Text>
								<Button style={{ marginLeft: '10px' }} as="a" href={finalUrl} target="_blank" appearance='primary'>Pay with PayPal</Button>
							</>
						}
					</div>
				}
			</>

		</>
	);
};

export default CollegeSponsorshipPayment;
