import { FC } from 'react';
import { bind, } from '@react-rxjs/core';
import { isAuthorized$ } from '../oauth/oauth';
import { QueryClient} from '@tanstack/react-query'
import { Text } from '@fluentui/react-components';
import { useGeneralStyles } from '../styles';
import Notifications from './notification/Notifications';
import { isAdmin$ } from '../store/userStore';


interface AdminProps {

}
export const queryClient = new QueryClient();
const [useIsAuthorized] = bind(isAuthorized$, isAuthorized$.value);
const [useIsAdmin] = bind(isAdmin$, isAuthorized$.value);

const Admin: FC<AdminProps> = () => {
    const authorized = useIsAuthorized();
    const isAdmin = useIsAdmin();

    // const { data: transactionsData } = useQuery('transactionsData', async () => {
    //     if (accessTokenInfo$.value != null && accessTokenInfo$.value.accessToken != null) {
    //         //const userName = await firstValueFrom(userId$.pipe(filter(userId => userId != null && userId !== '')));
    //         const res = await fetch(phpServer + 'getTransactions.php?access_token=' + accessTokenInfo$.value.accessToken);
    //         if (!res.ok) {
    //             throw new Error('Network response was not ok')
    //         }
    //         const d = await res.json();
    //         if (d.error) {
    //             console.log(d.error);
    //             return [];
    //         } else {
    //             console.log(d.response);
    //             return d.response;
    //         }
    //     }
    // }, { refetchOnWindowFocus: false });


    

    const generalStyles = useGeneralStyles();

    return (
        <>
            {authorized && isAdmin ?
                <div style={{ marginBottom: '20px', }}>
                    <Text className={generalStyles.groupTitle}>Admin</Text>
                    <br/>
                    <Notifications />
                </div>
                :
                <Text className={generalStyles.groupTitle} >You are not authorized to view this page.  You must be logged in with an admin account.</Text>
            }
        </>
    );
};

export default Admin;
