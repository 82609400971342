import React, { FC, useContext, useState } from 'react';
import { useIsRestoring, useQuery } from '@tanstack/react-query'
import {
	Card, CardPreview, Text, Link, makeStyles, shorthands, Switch, CardHeader, CardFooter, Button, Select, useId, Input,
	Textarea, InfoLabel, Skeleton, SkeletonItem
} from '@fluentui/react-components';
// import { motionTokens, createPresenceComponent, PresenceGroup } from '@fluentui/react-motions-preview';
import { useGeneralStyles } from './styles';
import { bind } from '@react-rxjs/core';
import { accessTokenInfo$, isAuthorized$ } from './oauth/oauth';
import { isAdmin$ } from './store/userStore';
import { phpServer } from './constants';
import { DeleteRegular } from "@fluentui/react-icons";
import Enumerable from 'linq';
import { ToastContext } from './context/ToastContext';
import { AdminContext } from './context/AdminContext';

interface MainProps {

}

interface IOfficer {
	id: number | undefined;
	title: string;
	details: string;
	email: string;
	photo: string;
	order: number;
}

interface IOfficerDatabase {
	id: number | undefined;
	memberId: number | null;
	data: string;
	active: boolean;
}


function convertIOfficer(iofficer: IOfficer): IOfficerDatabase {
	let copy = JSON.parse(JSON.stringify(iofficer));
	if (copy.id < 0) {
		copy.id = null;
	}
	return {
		id: copy.id,
		memberId: null,
		data: JSON.stringify(copy),
		active: true
	}
}

const usePageStyles = makeStyles({
	cards: {
		...shorthands.margin('5px'),
		width: '180px'
	},
	cardPreview: {
		height: '180px',
		width: '180px'
	},
	cardImage: {
		objectFit: 'cover'
	}
}
)

// const ItemMotion = createPresenceComponent({
// 	enter: {
// 		keyframes: [
// 			{ opacity: 0, transform: "scaleY(0) translateX(-30px)", height: 0 },
// 			{ opacity: 1, transform: "scaleY(1) translateX(0)", height: "auto" },
// 		],

// 		easing: motionTokens.curveEasyEase,
// 		duration: motionTokens.durationUltraSlow,
// 	},
// 	exit: {
// 		keyframes: [
// 			{ opacity: 1, transform: "scaleY(1) translateX(0)", height: "auto" },
// 			{ opacity: 0, transform: "scaleY(0) translateX(-30px)", height: 0 },
// 		],

// 		easing: motionTokens.curveEasyEase,
// 		duration: motionTokens.durationUltraSlow,
// 	},
// });

//const [useIsAuthorized] = bind(isAuthorized$, isAuthorized$.value);
const [useIsAdmin] = bind(isAdmin$, isAuthorized$.value);


function compareChanges(original: IOfficer[], edited: IOfficer[]) {
	if (original.length !== edited.length) {
		return false;
	}
	let same = true;
	const sortedEdited = edited.sort((a, b) => a.order - b.order);

	for (let i = 0; i < original.length; i++) {
		if (original[i].title !== sortedEdited[i].title)
			same = false;
		if (original[i].details !== sortedEdited[i].details)
			same = false;
		if (original[i].photo !== sortedEdited[i].photo)
			same = false;
		if (original[i].order !== sortedEdited[i].order)
			same = false;
		if (!same)
			break;
	}
	return same;
}



const Officers: FC<MainProps> = () => {

	const isAdmin = useIsAdmin();
	//const isAuthorized = useIsAuthorized();
	const isRestoring = useIsRestoring();
	const toastContext = useContext(ToastContext);
	const adminModeContext = useContext(AdminContext);

	const { data: officerData } = useQuery({
		queryKey: ['officerData'], refetchOnWindowFocus: false,
		queryFn: async () => {
			//if (accessTokenInfo$.value != null && accessTokenInfo$.value.accessToken != null) {

			let url = phpServer + `getOfficers.php`;
			const res = await fetch(url);
			if (!res.ok) {
				throw new Error('Network response was not ok')
			}
			const raw = await res.json();
			const officerData: IOfficer[] = [];
			const editedOfficerData: IOfficer[] = [];
			for (const line of raw) {
				let item: IOfficer = JSON.parse(line.data);
				item.id = line.id;
				officerData.push(item);
				editedOfficerData.push(structuredClone(item));
			}

			//setEditedOfficerData(JSON.parse(JSON.stringify(officerData)));
			setEditedOfficerData(editedOfficerData);
			return officerData;
			//}
		}
	});

	const [editedOfficerData, setEditedOfficerData] = useState<IOfficer[]>([]);

	const [editMode, setEditMode] = useState<boolean>(false);
	const onChangeEditMode = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setEditMode(e.currentTarget.checked);
		},
		[setEditMode]
	);

	const [changed, setChanged] = useState<boolean>(false);

	const onSave = async (e: React.MouseEvent<HTMLButtonElement>) => {
		//get changes
		const enumEdited = Enumerable.from(editedOfficerData);
		const enumOriginal = Enumerable.from(officerData!);
		const adds = enumEdited.except(enumOriginal, v => v.id);
		const removes = enumOriginal.except(enumEdited, v => v.id);
		const potentialChanged = enumOriginal.except(removes);
		const removeAndAdd = enumEdited.except(adds).where(x => potentialChanged.any(y => y.id === x.id && (y.title !== x.title || y.details !== x.details || y.photo !== x.photo || y.email !== x.email)))
		const updates = enumEdited.except(adds).except(removeAndAdd).where(x => potentialChanged.any(y => y.id === x.id && (y.order !== x.order || y.details !== x.details || y.photo !== x.photo || y.email !== x.email)))
		// console.log("Adds");
		// console.log(adds.select(x => convertIOfficer(x)).toArray());
		// console.log("Removes");
		// console.log(removes.select(x => convertIOfficer(x)).toArray());
		// console.log("UpdateByReplacing");
		// console.log(removeAndAdd.select(x => convertIOfficer(x)).toArray());
		// console.log("UpdateInPlace");
		// console.log(updates.select(x => convertIOfficer(x)).toArray());

		if (accessTokenInfo$.value !== null && accessTokenInfo$.value.accessToken !== null) {
			let headers = new Headers();
			headers.append('Content-Type', 'application/x-www-form-urlencoded');
			var p = new URLSearchParams();
			p.append("access_token", accessTokenInfo$.value.accessToken);
			p.append("adds", JSON.stringify(adds.concat(removeAndAdd).select(x => convertIOfficer(x)).toArray()));
			p.append("removes", JSON.stringify(removes.concat(removeAndAdd).select(x => convertIOfficer(x)).toArray()));
			p.append("updates", JSON.stringify(updates.select(x => convertIOfficer(x)).toArray()));

			const response = await fetch(phpServer + "setOfficers.php", { method: 'POST', body: p.toString(), headers: headers });
			const json = await response.json();
			console.log(json);
			if (json.error) {
				toastContext.notifyError(json.error);

			} else {
				setChanged(false);
				toastContext.notifySaveSuccess("The changes to the officers page have been saved.")
			}
		}

	};

	const onAddOfficerClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		const officer: IOfficer = {
			id: (Math.random() * -1),
			title: 'Title',
			details: 'Name\nOrganization\nPhone',
			email: 'email',
			photo: 'https://www.2yc3.org/Officers/photos/vacant.jpg',
			order: editedOfficerData.length
		}
		setEditedOfficerData([...editedOfficerData, officer]);

		setChanged(true);
	};

	const onDeleteOfficerClick = (e: React.MouseEvent<HTMLButtonElement>, v: IOfficer) => {
		let copy = [...editedOfficerData];
		const index = copy.indexOf(v);
		copy.splice(index, 1);

		copy = copy.sort((a, b) => a.order - b.order);
		copy.forEach((v, i) => {
			v.order = i;
		});

		setEditedOfficerData(copy);
		setChanged(true);
	};

	const onTitleInputChanged = function (e: React.ChangeEvent<HTMLInputElement>, i: number) {
		editedOfficerData[i].title = e.currentTarget.value;
		setEditedOfficerData([...editedOfficerData]);
		const same = compareChanges(officerData!, editedOfficerData);
		setChanged(!same);
	};
	const onDetailsTextareaChanged = function (e: React.ChangeEvent<HTMLTextAreaElement>, i: number) {
		editedOfficerData[i].details = e.currentTarget.value;
		setEditedOfficerData([...editedOfficerData]);

		const same = compareChanges(officerData!, editedOfficerData);
		setChanged(!same);
	};
	const onEmailInputChanged = function (e: React.ChangeEvent<HTMLInputElement>, i: number) {
		editedOfficerData[i].email = e.currentTarget.value;
		setEditedOfficerData([...editedOfficerData]);
		const same = compareChanges(officerData!, editedOfficerData);
		setChanged(!same);
	};

	const onPhotoInputChanged = function (e: React.ChangeEvent<HTMLInputElement>, i: number) {
		editedOfficerData[i].photo = e.currentTarget.value;
		setEditedOfficerData([...editedOfficerData]);

		const same = compareChanges(officerData!, editedOfficerData);
		setChanged(!same);
	};

	const onOrderSelectChanged = function (e: React.ChangeEvent<HTMLSelectElement>, i: number) {
		const newOrder = +e.currentTarget.value;
		const oldOrder = editedOfficerData[i].order;
		const editedItem = editedOfficerData[i];
		editedItem.order = newOrder;
		if (newOrder < oldOrder) {
			console.log("new is lower");
			for (const item of editedOfficerData) {
				if (item !== editedItem && (item.order >= newOrder && item.order <= oldOrder)) {
					item.order += 1;
				}
				console.log(item);
			}
		} else {
			console.log("new is higher");
			for (const item of editedOfficerData) {
				if (item !== editedItem && (item.order > oldOrder && item.order <= newOrder)) {
					item.order -= 1;
				}
				console.log(item);
			}
		}

		setEditedOfficerData([...editedOfficerData]);

		const same = compareChanges(officerData!, editedOfficerData);
		setChanged(!same);
	};

	const selectOrderId = useId("selectOrder");
	const titleInput = useId("titleInput");
	const detailsInput = useId("detailsInput");
	const emailInput = useId("emailInput");
	const imgUrlInput = useId("imgUrlInput");

	const generalStyles = useGeneralStyles();
	const pageStyles = usePageStyles();



	return (
		<div>
			{isAdmin && adminModeContext &&
				<Card>
					<CardHeader header={<InfoLabel info={"This box only appears to admins when Admin Mode is checked."}>Admin Tools</InfoLabel>} />

					<Switch label="Edit Mode" checked={editMode} onChange={onChangeEditMode}></Switch>
					{editMode &&
						<>
							<Button appearance='secondary' onClick={e => onAddOfficerClick(e)}>Add New Officer</Button>
							<Button appearance='primary' disabled={!changed} onClick={onSave}>Save</Button>

						</>
					}
				</Card>
			}
			<div style={{ display: "flex", flexDirection: 'row', flexWrap: "wrap" }}>

				{isRestoring ?
					(<>

						{[...Array(9)].map((e, i) =>

							<Card className={pageStyles.cards} key={i} >
								<Skeleton aria-label="Loading Content">


									<SkeletonItem shape='rectangle' size={128} style={{ marginTop: 5 }} />
									<SkeletonItem shape='rectangle' size={24} style={{ marginTop: 5 }} />
									<SkeletonItem shape='rectangle' size={16} style={{ marginTop: 5 }} />
									<SkeletonItem shape='rectangle' size={16} style={{ marginTop: 5 }} />
									<SkeletonItem shape='rectangle' size={16} style={{ marginTop: 5 }} />
									<SkeletonItem shape='rectangle' size={12} style={{ marginTop: 5 }} />
								</Skeleton>
							</Card>
						)}

					</>)
					:

					editedOfficerData.sort((a, b) => a.order - b.order).map((v, i, a) =>
						// <ItemMotion key={v.id ? v.id : Math.random().toString()} visible={true}  unmountOnExit>
						<Card className={pageStyles.cards} key={v.id}>
							<CardPreview
								className={pageStyles.cardPreview}
							>
								<img className={pageStyles.cardImage}
									src={v.photo}
									alt={`Headshot of ${v.title}`} />
							</CardPreview>
							<div style={{ display: "flex", flexDirection: 'column' }}>
								{editMode ?
									<>
										<Button appearance='secondary' style={{ color: 'red' }} icon={<DeleteRegular />} onClick={e => onDeleteOfficerClick(e, v)}>Delete</Button>

										<label htmlFor={titleInput}>Title</label>
										<Input id={titleInput} value={editedOfficerData[i].title} onChange={e => onTitleInputChanged(e, i)}></Input>
									</>
									:
									<Text className={generalStyles.cardTitle}>
										{v.title}
									</Text>
								}
								{editMode ?
									<>
										<label htmlFor={detailsInput}>Details</label>
										<Textarea id={detailsInput} value={editedOfficerData[i].details}
											resize="vertical" size="small" style={{ minHeight: 120 }}
											onChange={e => onDetailsTextareaChanged(e, i)}></Textarea>
									</>
									:
									<Text >
										{v.details.split("\n").map((v2, i2) => {
											return (
												<span key={i2} className={i2 === 0 ? generalStyles.secondary : generalStyles.caption}>
													{v2} <br />
												</span>
											)
										})}
									</Text>
								}
								{editMode ?
									<>
										<label htmlFor={emailInput}>Email</label>
										<Input id={emailInput} value={editedOfficerData[i].email} onChange={e => onEmailInputChanged(e, i)}></Input>
									</>
									:
									<Link href={"mailto:" + v.email} className={generalStyles.secondary}>{v.email}</Link>
								}



							</div>
							{editMode &&
								<CardFooter>
									<div>
										<label htmlFor={imgUrlInput}>Image Url</label>
										<Input id={imgUrlInput} value={editedOfficerData[i].photo} onChange={e => onPhotoInputChanged(e, i)} size='small'></Input>

										<label htmlFor={selectOrderId} >Order</label>
										<Select id={selectOrderId} value={editedOfficerData[i].order.toString()} onChange={e => onOrderSelectChanged(e, i)}>
											{editedOfficerData.map((v2, i2, a2) =>
												<option key={i2} value={i2}>{i2}</option>
											)}
										</Select>
									</div>
								</CardFooter>
							}
						</Card>
						// </ItemMotion>

					)}


			</div>

		</div>
	);
};

export default Officers;
