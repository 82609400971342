import React, { createRef, FC, useContext, useEffect, useState } from 'react';
import { bind } from '@react-rxjs/core';
import { accessTokenInfo$, isAuthorized$ } from '../oauth/oauth';
import { useIsRestoring, useQuery } from '@tanstack/react-query'
import SinglePresenterMeeting from './SinglePresenterMeeting';
import { Alert } from '@fluentui/react-components/unstable';
import LinkRouter from '../LinkRouter';
import { phpServer } from '../constants';
import { Button, Card, CardHeader, Field, InfoLabel, Spinner, Switch, Text } from '@fluentui/react-components';
import { isAdmin$ } from '../store/userStore';
import Enumerable from 'linq';
import { ToastContext } from '../context/ToastContext';
import { Collapse } from "@fluentui/react-motion-components-preview";
import { compareMeetingChanges, convertMeetingData, MeetingData } from './meetingUtils';
import { AdminContext } from '../context/AdminContext';

interface MeetingsProps {

}

//export const queryClient = new QueryClient();

const [useIsAuthorized] = bind(isAuthorized$, isAuthorized$.value);
const [useIsAdmin] = bind(isAdmin$, isAuthorized$.value);

export const Meetings: FC<MeetingsProps> = () => {

    const authorized = useIsAuthorized();
    const isAdmin = useIsAdmin();
    const isRestoring = useIsRestoring();
    const toastContext = useContext(ToastContext);
    const adminModeContext = useContext(AdminContext);

    const containerRef = createRef<HTMLDivElement>();

    const { data: meetingData, status } = useQuery({
        queryKey: ['meetingData'], refetchOnWindowFocus: true, queryFn: async () => {

            let url = phpServer + `getMeetings.php?`;
            const res = await fetch(url);
            if (!res.ok) {
                throw new Error('Network response was not ok')
            }
            const raw = await res.json();
            const meetingData: MeetingData[] = [];
            const oldMeetingData: MeetingData[] = [];
            const editedMeetingData: MeetingData[] = [];
            for (const line of raw) {
                //assumes type = 0
                let item: MeetingData = JSON.parse(line.data);
                item.id = line.id;
                item.date = new Date(item.date);
                item.time = new Date(item.time);

                if (item.date < new Date(Date.now() - (24 * 60 * 60 * 1000))) {
                    oldMeetingData.push(item);
                } else {
                    meetingData.push(item);
                    editedMeetingData.push(structuredClone(item));
                }
            }

            setOldMeetingData(oldMeetingData);

            setEditedMeetingData(e => {
                setPreviousEditedMeetingData(e);
                return editedMeetingData;
            });

            return meetingData;
        }
    });

    const uploadImageAsync = async (file: File): Promise<string | null> => {
        if (accessTokenInfo$.value !== null && accessTokenInfo$.value.accessToken !== null) {
            let headers = new Headers();
            //headers.append('Content-Type', 'multipart/form-data');
            //headers.append('Content-Type', "");
            //headers.append('Authorization', "Bearer " + accessTokenInfo$.value.accessToken);
            var form_data = new FormData();
            form_data.append('file', file);
            form_data.append('location', 'meetings');
            //            form_data.append("access_token", accessTokenInfo$.value.accessToken);
            const response = await fetch(phpServer + "uploadImage.php?access_token=" + accessTokenInfo$.value.accessToken, { method: 'POST', body: form_data, headers: headers });
            const json = await response.json();
            if (json.error) {
                return json.error;
            } else {
                return null;
            }
        } else {
            return "Not authenticated!";
        }
    };

    const [oldMeetingData, setOldMeetingData] = useState<MeetingData[]>([]);


    const [previousEditedMeetingData, setPreviousEditedMeetingData] = useState<MeetingData[]>([]);
    const [editedMeetingData, setEditedMeetingData] = useState<MeetingData[]>([]);

    const [editMode, setEditMode] = useState<boolean>(false);
    const onChangeEditMode = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setEditMode(e.currentTarget.checked);
        },
        [setEditMode]
    );

    const [changed, setChanged] = useState<boolean>(false);

    const [oldMeetingsVisible, setOldMeetingsVisible] = React.useState<boolean>(false);

    const handleChange = (p: MeetingData) => {
        // console.log(p);
        // console.log(p.time.toLocaleTimeString('en-US',{hour:'numeric',minute:'2-digit',hour12:true}));
        const foundIndex = editedMeetingData.findIndex(v => v.id === p.id);
        if (foundIndex !== -1) {
            const edited = editedMeetingData.map((v, i) => {
                if (i === foundIndex) {
                    return p;
                } else {
                    return v;
                }
            });
            //setEditedMeetingData(edited);
            setEditedMeetingData(e => {
                setPreviousEditedMeetingData(e);
                return edited;
            });

        } else {
            console.log("This should never be -1.");
        }

    };

    const onAddMeeting = (e: React.MouseEvent<HTMLButtonElement>) => {
        const meeting: MeetingData = {
            id: (Math.random() * -1),
            title: "",
            date: new Date(Date.now()),
            time: new Date(Date.now()),
            component: "SinglePresenterMeeting",
            imgSrc: "",
            registerLink: "",
            presenters: [],
            content: ""

        }
        setEditedMeetingData(e => {
            setPreviousEditedMeetingData(e);
            return [...editedMeetingData, meeting];
        });

        setChanged(true);




    };

    useEffect(() => {
        if (meetingData) {
            const same = compareMeetingChanges(meetingData!, editedMeetingData);
            setChanged(!same);
        }
    }, [editedMeetingData]);


    useEffect(() => {
        if (editedMeetingData.length > previousEditedMeetingData.length && previousEditedMeetingData.length !== 0) {
            const parentElement = containerRef.current?.parentElement;
            if (parentElement)
                parentElement.scrollTo(0, parentElement.scrollHeight - 500);
        }
    }, [editedMeetingData, previousEditedMeetingData, containerRef]);

    const onDeleteMeeting = (v: MeetingData) => {
        let copy = [...editedMeetingData];
        const index = copy.findIndex(x => x.id === v.id);
        copy.splice(index, 1);

        //const same = compareMeetingChanges(meetingData!, copy);
        //setEditedMeetingData(copy);
        setEditedMeetingData(e => {
            setPreviousEditedMeetingData(e);
            return copy;
        });

        //setChanged(!same);
    };


    const onSave = async (e: React.MouseEvent<HTMLButtonElement>) => {
        //get changes
        const enumEdited = Enumerable.from(editedMeetingData);
        const enumOriginal = Enumerable.from(meetingData!);
        const adds = enumEdited.except(enumOriginal, v => v.id);
        const removes = enumOriginal.except(enumEdited, v => v.id);
        const potentialChanged = enumOriginal.except(removes);
        // const removeAndAdd = enumEdited.except(adds).where(x => potentialChanged.any(y => y.id == x.id && (y.title !== x.title || y.details !== x.details || y.photo !== x.photo || y.email !== x.email)))
        const updates = enumEdited.except(adds).where(x => potentialChanged.any(y => y.id === x.id && (y.title !== x.title || y.date !== x.date || y.time !== x.time || y.registerLink !== x.registerLink || y.imgSrc !== x.imgSrc || y.content !== x.content)))
        // console.log("Adds");
        // console.log(adds.select(x => convertMeetingData(x)).toArray());
        // console.log("Removes");
        // console.log(removes.select(x => convertMeetingData(x)).toArray());
        // // console.log("UpdateByReplacing");
        // // console.log(removeAndAdd.select(x => convertIOfficer(x)).toArray());
        // console.log("UpdateInPlace");
        // console.log(updates.select(x => convertMeetingData(x)).toArray());

        if (accessTokenInfo$.value !== null && accessTokenInfo$.value.accessToken !== null) {
            let headers = new Headers();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            var p = new URLSearchParams();
            p.append("access_token", accessTokenInfo$.value.accessToken);
            p.append("adds", JSON.stringify(adds.select(x => convertMeetingData(x)).toArray()));
            p.append("removes", JSON.stringify(removes.select(x => convertMeetingData(x)).toArray()));
            p.append("updates", JSON.stringify(updates.select(x => convertMeetingData(x)).toArray()));

            const response = await fetch(phpServer + "setMeetings.php", { method: 'POST', body: p.toString(), headers: headers });
            const json = await response.json();
            if (json.error) {
                toastContext.notifyError(json.error);

            } else {
                setChanged(false);
                toastContext.notifySaveSuccess("The changes to the meetings page have been saved.")
            }
        }

    };

    return (
        <>
            {isAdmin && adminModeContext &&
                <Card>
                    <CardHeader header={<InfoLabel info={"This box only appears to admins when Admin Mode is checked."}>Admin Tools</InfoLabel>} />

                    <Switch label="Edit Mode" checked={editMode} onChange={onChangeEditMode}></Switch>
                    {editMode &&
                        <>
                            <Button appearance='secondary' onClick={e => onAddMeeting(e)}>Add New Meeting</Button>
                            <Button appearance='primary' disabled={!changed} onClick={onSave}>Save</Button>
                            <Text>Only delete mistakes or cancellations.  Do NOT delete old meetings.
                                They should gracefully disappear one day after their expiration date is set.  </Text>
                        </>
                    }
                </Card>
            }
            <div ref={containerRef} style={{ display: 'flex', flexDirection: 'column' }}>
                {
                    status !== "success" || isRestoring || editedMeetingData === undefined ? (
                        <>
                            <Spinner size='extra-large' />
                        </>)
                        :
                        editedMeetingData.length === 0 ?

                            (<div>
                                <Text size={500}>There are no scheduled meetings for the near future yet. </Text>
                            </div>)

                            :
                            editedMeetingData.map((v, i) => {
                                switch (v.component) {
                                    case "SinglePresenterMeeting":
                                        return (<SinglePresenterMeeting
                                            isEditing={editMode}
                                            onChange={handleChange}
                                            uploadImageAsync={uploadImageAsync}
                                            onDeleteMeeting={onDeleteMeeting}
                                            key={i}
                                            {...v} />);
                                    default:
                                        return "NO MATCH";
                                }
                            })
                }

                <Field >
                    <Switch
                        label="Show Recent Meetings"
                        checked={oldMeetingsVisible}
                        onChange={() => setOldMeetingsVisible((v) => !v)}
                    />
                </Field>
                <Collapse visible={oldMeetingsVisible}>
                    <div >
                        {
                            status !== "success" || isRestoring || oldMeetingData === undefined ? (
                                <>
                                    <Spinner size='extra-large' />
                                </>)
                                :

                                oldMeetingData.map((v, i) => {
                                    switch (v.component) {
                                        case "SinglePresenterMeeting":
                                            return (<SinglePresenterMeeting key={i} {...v} />);
                                        default:
                                            return "NO MATCH";
                                    }
                                })
                        }
                    </div>
                </Collapse>
                <LinkRouter as="ButtonLink" href={"/archives"} disabled={!authorized} appearance='secondary' >Meeting Archives</LinkRouter>
                {!authorized &&
                    <Alert intent="warning">Archives are reserved for members of 2YC3.</Alert>
                }
            </div>

        </>
    );
};

