import React, { FC } from 'react';
import { bind, } from '@react-rxjs/core';
import { accessTokenInfo$, isAuthorized$ } from './oauth/oauth';
import { QueryClient, useQuery } from '@tanstack/react-query'
import { phpServer } from './constants';
import { DataGrid, DataGridRow, DataGridHeader, DataGridBody, DataGridHeaderCell, DataGridCell, TableColumnDefinition, createTableColumn, TableCellLayout, Text } from '@fluentui/react-components';
import { useGeneralStyles } from './styles';


interface TransactionsProps {

}
export const queryClient = new QueryClient();
const [useIsAuthorized] = bind(isAuthorized$, isAuthorized$.value);

const Transactions: FC<TransactionsProps> = () => {
    const authorized = useIsAuthorized();

    const { data: transactionsData } = useQuery({queryKey:['transactionsData'],refetchOnWindowFocus: false, queryFn: async () => {
        if (accessTokenInfo$.value != null && accessTokenInfo$.value.accessToken != null) {
            //const userName = await firstValueFrom(userId$.pipe(filter(userId => userId != null && userId !== '')));
            const res = await fetch(phpServer + 'getTransactions.php?access_token=' + accessTokenInfo$.value.accessToken);
            if (!res.ok) {
                throw new Error('Network response was not ok')
            }
            const d = await res.json();
            if (d.error) {
                console.log(d.error);
                return [];
            } else {
                console.log(d.response);
                return d.response;
            }
        }
    }});


    const columns: TableColumnDefinition<string>[] = [
        createTableColumn<any>({
            columnId: 'item',
            renderHeaderCell: () => "Item",
            renderCell: (item) => (<TableCellLayout style={{ overflowX: 'hidden' }} >{item.item}</TableCellLayout>),
            compare: (a, b) => a.item.localeCompare(b.item)
        }),
        createTableColumn<any>({
            columnId: 'total',
            renderHeaderCell: () => "Total",
            renderCell: (item) => (<TableCellLayout>${item.total}</TableCellLayout>),
            compare: (a, b) => a.total > b.total ? 1 : (a.total < b.total ? -1 : 0)
        }),
        createTableColumn<any>({
            columnId: 'payby',
            renderHeaderCell: () => "Pay by",
            renderCell: (item) => (<TableCellLayout>{item.payby}</TableCellLayout>),
            compare: (a, b) => a.payby.localeCompare(b.payby)
        }),
        createTableColumn<any>({
            columnId: 'status',
            renderHeaderCell: () => "Status",
            renderCell: (item) => (<TableCellLayout>{item.status}</TableCellLayout>),
            compare: (a, b) => a.status.localeCompare(b.status)
        }),
        createTableColumn<any>({
            columnId: 'timestamp',
            renderHeaderCell: () => "Timestamp",
            renderCell: (item) => (<TableCellLayout>{item.timestamp}</TableCellLayout>),
            compare: (a, b) => a.timestamp.localeCompare(b.timestamp)
        }),
        createTableColumn<any>({
            columnId: 'fridaylunch',
            renderHeaderCell: () => "Friday Lunch",
            renderCell: (item) => (<TableCellLayout>{item.fridaylunch}</TableCellLayout>),
            compare: (a, b) => a.fridaylunch.localeCompare(b.fridaylunch)
        }),
        createTableColumn<any>({
            columnId: 'fridaydinner',
            renderHeaderCell: () => "Friday Dinner",
            renderCell: (item) => (<TableCellLayout>{item.fridaydinner}</TableCellLayout>),
            compare: (a, b) => a.fridaydinner.localeCompare(b.fridaydinner)
        }),
        createTableColumn<any>({
            columnId: 'saturdaylunch',
            renderHeaderCell: () => "Saturday Lunch",
            renderCell: (item) => (<TableCellLayout>{item.saturdaylunch}</TableCellLayout>),
            compare: (a, b) => a.saturdaylunch.localeCompare(b.saturdaylunch)
        }),
        createTableColumn<any>({
            columnId: 'preference',
            renderHeaderCell: () => "Preference",
            renderCell: (item) => (<TableCellLayout>{item.preference}</TableCellLayout>),
            compare: (a, b) => a.preference.localeCompare(b.preference)
        })
    ];

    const generalStyles = useGeneralStyles();

    return (
        <>
            {authorized ?
                <div style={{ marginBottom: '20px', }}>
                    <Text className={generalStyles.groupTitle}>Transactions</Text>
                    {transactionsData !== undefined &&
                        <DataGrid
                            items={transactionsData}
                            columns={columns}
                            sortable
                            selectionMode="single"
                            focusMode="composite"
                            subtleSelection={true}
                            style={{ minWidth: '640px' }}
                        >
                            <DataGridHeader>
                                <DataGridRow selectionCell={{ "aria-label": "Select all rows" }}>
                                    {({ renderHeaderCell }) => (
                                        <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                                    )}
                                </DataGridRow>
                            </DataGridHeader>
                            <DataGridBody<any>>
                                {({ item, rowId }) => (
                                    <DataGridRow<any>
                                        key={rowId}
                                        selectionCell={{ "aria-label": "Select row" }}
                                    >
                                        {({ renderCell, columnId }) => (
                                            <DataGridCell >
                                                {renderCell(item)}
                                            </DataGridCell>
                                        )}
                                    </DataGridRow>
                                )}
                            </DataGridBody>
                        </DataGrid>
                    }
                </div>
                :
                <Text className={generalStyles.groupTitle} >You are not authorized to view this page.</Text>
            }
        </>
    );
};

export default Transactions;
