import { BehaviorSubject, Subscription, Observer, map } from 'rxjs';
import { accessTokenInfo$, isAuthorized$ } from '../oauth/oauth';
import { phpServer } from '../constants';


const memberSubject = new BehaviorSubject<IMember|null>(null);

export interface IMember {
    id: number;
    acs: string; //empty or ACS
    divc: string; //empty or DivCHED
    teacher: string;
    first: string;
    last: string;
    institution: string;
    addr1: string;
    addr2: string;
    city: string;
    st: string;
    zip1: number;
    zip2: number;
    phone1: number;
    phone2: number;
    phone3: number;
    phone4: number;
    email: string;
    username1: string;
    psw: string;
    weblist: string; //yes or no
    emailconsent: string; //yes or no
    expires: number; // year
    other: string;
    extra3: string;
    admin: boolean;
}

let state: IMember | null = null;

interface IUserStore {
    currentState: () => IMember | null;
    refresh: () => void;
    erase: () => void;
    subscribe: (x: Partial<Observer<IMember | null>> | ((value: IMember | null) => void) | undefined) => Subscription;
}

export const isAdmin$ = memberSubject.pipe(map(x=>x != null ? x.admin : false));
export const email$ = memberSubject.pipe(map(x=>x != null ? x.email : false));

export const userStore : IUserStore = {
    currentState: () => state,
    refresh: () => {
        if (accessTokenInfo$.value != null && accessTokenInfo$.value.accessToken != null) {
            var p = new URLSearchParams();
            p.append("access_token", accessTokenInfo$.value.accessToken);
            let headers = new Headers();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            //headers.append('Authorization', 'Basic ' + btoa(oauthClientUsername + ":" + oauthClientPassword));
            fetch(phpServer + 'userData.php', { method: 'POST', body: p.toString(), headers: headers }).then(res=>{
                if (!res.ok) {
                    throw new Error('Network response was not ok')
                }
                return res.json();                
            }).then(json=>{
                console.log(json);
                const user = json;
                state = {...user};
                
                memberSubject.next(state);
            });
        }
    },
    erase: () =>{
        state = null;
        memberSubject.next(state);
    },
    subscribe: (x: Partial<Observer<IMember | null>> | ((value: IMember | null) => void) | undefined) => memberSubject.subscribe(x)

};

isAuthorized$.subscribe((isAuthorized) => {
    if (isAuthorized){
        userStore.refresh();
        
    } else {
        userStore.erase();
    }
});
