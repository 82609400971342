import React, { FC } from 'react';
import { useQuery } from '@tanstack/react-query'
import { Card, Text, } from '@fluentui/react-components';
import { useGeneralStyles } from './styles';
import { InteractionTag, InteractionTagPrimary } from "@fluentui/react-tags-preview";

interface MainProps {

}

interface NewsletterItem {
	label: string;
	url: string;
}
interface NewsletterYear {
	year: string;
	issues: NewsletterItem[];
}

const Newsletter: FC<MainProps> = () => {

	const { data: newsletterData } = useQuery({queryKey: ['newsletterData'], refetchOnWindowFocus: true, queryFn: async () => {
		const res = await fetch("/data/newsletter.json");
		if (!res.ok) {
			throw new Error('Network response was not ok')
		}
		const newsletterData: NewsletterYear[] = await res.json();

		return newsletterData;
	}});

	const generalStyles = useGeneralStyles();

	return (
		<>
			<div style={{ display: "flex", flexDirection: 'row', flexWrap: "wrap" }}>
				{newsletterData === undefined ? (<></>) : newsletterData.map(v =>
					<Card style={{ width: 'auto', margin: '5px' }}>
						<div style={{ display: "flex", flexDirection: 'column' }}>
							<Text className={generalStyles.cardTitle}>
								{v.year}
							</Text>
							{v.issues.map(v2 =>
								<InteractionTag shape='circular' style={{ marginTop: '2px', marginBottom: '2px' }}>
									<InteractionTagPrimary onClick={(e) => {
										window.open(v2.url, '_blank');
									}}>{v2.label}</InteractionTagPrimary>
								</InteractionTag>
							)}
						</div>
					</Card>
				)}
			</div>
		</>
	);
};

export default Newsletter;
