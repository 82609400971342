import React, { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Link, Text } from '@fluentui/react-components';
import { useGeneralStyles } from './styles';

interface OnlinePaymentProps {
}
//export const queryClient = new QueryClient();

const OnlinePayment: FC<OnlinePaymentProps> = (props) => {

    const generalStyles = useGeneralStyles();
    let [searchParams] = useSearchParams();
    let amount = searchParams.get("amount");

    return (
        <Text className={generalStyles.body2}>Your payment is completed and your registration is now pending, your membership will be updated as soon as out treasurer confirms your payment.
                                <br /><br />
								A receipt for your purchase has been emailed to you. You may log into your PayPal account at 
                                <Link href="https://www.paypal.com" target="_blank" color="text.secondary">www.paypal.com</Link> 
                                to review the details of this transaction.
							</Text>
    );

}

export default OnlinePayment;