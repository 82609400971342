import React, { useEffect, useState, FC } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { phpServer } from './constants';
import {useId, Spinner, Select, Label, Text, Table, TableHeader, TableRow, TableHeaderCell, TableBody, TableCell} from '@fluentui/react-components';
import LinkRouter from './LinkRouter';
import { useGeneralStyles } from './styles';

interface CollegeSponsorsProps {

}
//export const queryClient = new QueryClient();

const CollegeSponsors: FC<CollegeSponsorsProps> = () => {
	const queryClient = useQueryClient();

	const [year, setYear] = useState<number>(new Date().getFullYear());

	const { data: yearsData } = useQuery({queryKey: ['yearsData'],refetchOnWindowFocus: false , queryFn: async () => {
		const res = await fetch(phpServer + 'getCollegeSponsorYears.php');
		if (!res.ok) {
			throw new Error('Network response was not ok')
		}
		const yearsJson: any[] = await res.json();
		const years = yearsJson.map(v => v.year);
		if (years.length > 0) {
			setYear(years[0]);
		}
		return years;
	}});

	const { data: collegeData } = useQuery({queryKey: ['collegeData', year], queryFn: async () => {
		console.log("Getting data for: " + year);
		const res = await fetch(phpServer + 'getCollegeSponsors.php?year=' + year);
		if (!res.ok) {
			throw new Error('Network response was not ok')
		}
		const d = await res.json();
		return d;
	}, enabled: !!yearsData, refetchOnWindowFocus: false });

	const handleChange = (e: any) => {
		setYear((s) => e.target.value);

	};

	useEffect(() => {
		queryClient.invalidateQueries({queryKey: ['collegeData']});
	}, [year, queryClient]);

	const generalStyles = useGeneralStyles();

	const selectId = useId('select');

	return (
		<>
			{yearsData != null ?
				<>
					<div >
						<Text className={generalStyles.body2}>
							<LinkRouter as="Link" href="/college/payment"> Become or renew</LinkRouter> your college sponsorship today!<br />
							<LinkRouter as="Link" href="/college/payment">Secure electronic payments</LinkRouter> are accepted for your college sponsorship dues!<br />
							<ul>
								<li>
									College sponsors demonstrate support of the 2YC3 mission and help keep the costs for individual memberships low.
								</li>
								<li>
									College sponsors can advertise open positions for free on our website.
								</li>
								<li>
									Faculty at college sponsor institutions can apply for travel grants to attend a 2YC3 conference.
								</li>
							</ul>
						</Text>

						<Label htmlFor={selectId}>Year</Label>
						<Select
							id={selectId}
							value={year}
							onChange={handleChange}
						>
							{yearsData.map(v => {
								return <option key={v} value={v}>{v}</option>
							})}

						</Select>

						{collegeData != null ?
							
								<Table style={{marginTop: '20px'}} aria-label={"Sponsoring Colleges in " + year}>
									<TableHeader>
										<TableRow>
											<TableHeaderCell>College</TableHeaderCell>
											<TableHeaderCell align="right">State</TableHeaderCell>
											<TableHeaderCell align="right">Year</TableHeaderCell>
										</TableRow>
									</TableHeader>
									<TableBody>
										{collegeData.map((v: any) => {
											return (
												<TableRow key={v.id}>
													<TableCell >
														{v.college}
													</TableCell>
													<TableCell align='right'>{v.state}</TableCell>
													<TableCell align='right'>{v.year}</TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
						
							: <></>
						}
					</div>
				</>
				: 
				<>
					<Spinner />
				</>}

		</>
	);
};

export default CollegeSponsors;
