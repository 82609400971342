import React, { FC } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import './App.css';
import { FluentProvider, webLightTheme } from '@fluentui/react-components';
import LinkRouter from './LinkRouter';

 //'http://beta.2yc3.org/'; //

interface AppProps {

}
export const queryClient = new QueryClient();


const ErrorElement: FC<AppProps> = () => {
  //const auth = useAuth();

 
  return (
    <FluentProvider theme={webLightTheme}>
        <QueryClientProvider client={queryClient} >
        <div >
            <div style={{ margin: '2px', opacity:0.3, backgroundImage: "url('/images/menubkg.jpg')", backgroundSize: 'contain', height:'100vh' }}></div>
            <div style={{position:'absolute', top: 100, left:0}}>
                <h1>This link doesn't exist!</h1>

                <h4>Return to <LinkRouter href='/'>https://www.2yc3.org</LinkRouter></h4>
            </div>
        </div>
        </QueryClientProvider>
    </FluentProvider>
  );
}

export default ErrorElement;
