import React, { FC } from 'react';
import { bind, } from '@react-rxjs/core';
import { firstValueFrom, filter } from 'rxjs';
import { accessTokenInfo$, isAuthorized$, userId$ } from '../oauth/oauth';
import { useQuery } from '@tanstack/react-query'
import {
    Spinner, Input, Label, Button, Text, ProgressBar, Card, CardHeader, CardFooter,
    Toaster, useToastController, Toast, ToastTitle, ToastBody, ToastTrigger, useId,
    Radio, RadioGroup, Textarea, Link, Checkbox
} from '@fluentui/react-components';

import { Alert } from '@fluentui/react-components/unstable';
import { phpServer } from '../constants';
import { Controller, useForm } from 'react-hook-form';
import { categoryItems, topicItems } from './resources';

import { DeleteRegular, LinkRegular, AddRegular, DismissRegular } from '@fluentui/react-icons';
import { useGeneralStyles } from '../styles';
import { InfoLabel } from '@fluentui/react-components/unstable';

interface ResourcesProps {

}


interface IUploadInfo {
    operation: 'add' | 'edit' | 'delete';
    id?: string;
    link: string;
    title: string;
    description: string;
    category: string;
    copyright: boolean;
    advertisement: boolean;
    username: string;
    intro: number;
    gen: number;
    gob: number;
    org: number;
    bio: number;
    trans: number;
    pro: number;
    equ: number;
    sus: number;
    inter: number;
    series: string;
}

const defaultUploadInfo: IUploadInfo = {
    operation: 'add',
    id: undefined,
    link: '',
    title: '',
    description: '',
    category: 'link',
    copyright: false,
    advertisement: false,
    username: '',
    intro: 0,
    gen: 0,
    gob: 0,
    org: 0,
    bio: 0,
    trans: 0,
    pro: 0,
    equ: 0,
    sus: 0,
    inter: 0,
    series: ''
};

const [useIsAuthorized] = bind(isAuthorized$, isAuthorized$.value);

// const fabstyle = {
//     margin: 0,
//     top: 'auto',
//     right: 20,
//     bottom: 20,
//     left: 'auto',
//     position: 'fixed',
// };

const UploadResource: FC<ResourcesProps> = () => {
    const authorized = useIsAuthorized();

    const { handleSubmit, control, setValue } = useForm<IUploadInfo>({
        defaultValues: defaultUploadInfo
    });

    const setAllValues = (resource: IUploadInfo) => {
        console.log(resource);
        setValue('id', resource.id)
        setValue('link', resource.link);
        setValue('title', resource.title);
        setValue('description', resource.description);
        setValue('category', resource.category);
        setValue('intro', +resource.intro);
        setValue('gen', +resource.gen);
        setValue('gob', +resource.gob);
        setValue('org', +resource.org);
        setValue('bio', +resource.bio);
        setValue('trans', +resource.trans);
        setValue('pro', +resource.pro);
        setValue('equ', +resource.equ);
        setValue('sus', +resource.sus);
        setValue('inter', +resource.inter);
        setValue('series', resource.series);

    };

    const [pageCount, setPageCount] = React.useState(0);
    const itemsPerPage = 10;
    //const [itemsPerPage, setItemsPerPage] = React.useState(10);
    const [duplicateLink, setDuplicateLink] = React.useState("");
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [isQuerying, setIsQuerying] = React.useState(false);
    const [selected, setSelected] = React.useState<IUploadInfo | null>(null);
    const [successfulType, setSuccessfulType] = React.useState<"add" | "edit" | "delete">("add");


    const successMessage = (type: "add" | "edit" | "delete") => {
        switch (type) {
            case "add":
                return "Resource added successfully.";
            case "edit":
                return "Resource updated successfully.";
            case "delete":
                return "Resource deleted successfully.";
            default:
                return "";
        }
    }

    const deleteItem = async (itemToDelete: IUploadInfo) => {
        if (accessTokenInfo$.value != null && accessTokenInfo$.value.accessToken != null) {
            setIsSubmitting(true);
            setDuplicateLink("");

            const values = defaultUploadInfo;
            values.id = itemToDelete.id;

            const userName = await firstValueFrom(userId$.pipe(filter(userId => userId != null && userId !== '')));
            values.username = userName;
            values.operation = 'delete';

            let headers = new Headers();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            var p = new URLSearchParams();
            p.append("access_token", accessTokenInfo$.value.accessToken);
            for (const v in values) {
                p.append(v, (values as any)[v]);
            }
            const response = await fetch(phpServer + "uploadResource.php", { method: 'post', body: p.toString(), headers: headers });
            const result = await response.json();
            if (result.error) {
                // if (result.error.startsWith('Duplicate entry')) {
                //     setDuplicateLink('This link has already been uploaded. Please try another one.');
                // }
            } else {
                if (selected === itemToDelete) {
                    setSelected(null);
                    setAllValues(defaultUploadInfo);
                }
                refetch();
                setSuccessfulType("delete");
                notify();
            }
            setIsSubmitting(false);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };


    const onSubmit = async (values: IUploadInfo) => {
        if (accessTokenInfo$.value != null && accessTokenInfo$.value.accessToken != null) {
            console.log(values);
            setIsSubmitting(true);
            setDuplicateLink("");

            const userName = await firstValueFrom(userId$.pipe(filter(userId => userId != null && userId !== '')));
            values.username = userName;
            if (selected != null && values.operation !== 'delete') {
                values.operation = 'edit';
            }

            let headers = new Headers();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            var p = new URLSearchParams();
            p.append("access_token", accessTokenInfo$.value.accessToken);
            for (const v in values) {
                p.append(v, (values as any)[v]);
            }
            const response = await fetch(phpServer + "uploadResource.php", { method: 'post', body: p.toString(), headers: headers });
            const result = await response.json();
            if (result.error) {
                if (result.error.startsWith('Duplicate entry')) {
                    setDuplicateLink('This link has already been uploaded. Please try another one.');
                }
            } else {
                refetch();
                if (values.operation === 'add') {
                    setSuccessfulType("add");
                    notify();
                } else {
                    setSuccessfulType("edit");
                    notify();
                }
                setSelected(null);
                setAllValues(defaultUploadInfo);
            }
            setIsSubmitting(false);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            values.operation = 'add';
        }
    };


    const { data: resourcesJson, refetch } = useQuery({queryKey: ['resourcesData', itemsPerPage],refetchOnWindowFocus: false, queryFn: async () => {
        const userName = await firstValueFrom(userId$.pipe(filter(userId => userId != null && userId !== '')));
        if (accessTokenInfo$.value != null && accessTokenInfo$.value.accessToken != null) {
            setIsQuerying(true);
            var p = new URLSearchParams();
            //p.append("access_token", accessTokenInfo$.value.accessToken);
            let headers = new Headers();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            let query = '?page=1';
            query += '&items=' + itemsPerPage;
            query += '&category=all';
            query += '&topic=all';
            query += '&user=' + userName;

            //headers.append('Authorization', 'Basic ' + btoa(oauthClientUsername + ":" + oauthClientPassword));
            const res = await fetch(phpServer + 'getResources.php' + query, { method: 'POST', body: p.toString(), headers: headers });
            if (!res.ok) {
                setIsQuerying(false);
                throw new Error('Network response was not ok')
            }
            const resources = await res.json();
            if (pageCount !== Math.ceil(resources[0] / itemsPerPage)) {
                setPageCount(Math.ceil(resources[0] / itemsPerPage));
            }
            setIsQuerying(false);
            console.log(resources);
            return resources[1];
        }
    }});

    const toasterId = useId("toaster");

    const linkId = useId('input');
    const titleId = useId('input');
    const descriptionId = useId('textarea');
    const categoryId = useId('label');
    const topicId = useId('controller');
    const copyrightId = useId('checkbox');

    const { dispatchToast } = useToastController(toasterId);
    const notify = () =>
        dispatchToast(
            <Toast >
                <ToastTitle action={
                    <ToastTrigger>
                        <Button icon={<DismissRegular />} />
                    </ToastTrigger>
                }>Success</ToastTitle>
                <ToastBody>{successMessage(successfulType)}</ToastBody>
            </Toast>,
            { intent: "success" }
        );

    const generalStyles = useGeneralStyles();


    return (
        <>
            {authorized ?
                <>
                    <div style={{ height: '100%' }}>
                        <form onSubmit={handleSubmit(onSubmit)} >
                            {resourcesJson !== undefined && resourcesJson.length > 0 && (
                                <Card >
                                    <CardHeader style={{ padding: '10px' }} header={<Text className={generalStyles.groupTitle}>Your uploaded resources:</Text>}
                                        description={<Text>Select one to edit it or delete it using the delete icon.</Text>}
                                    />
                                    <div >

                                        {isQuerying ?? <ProgressBar />}
                                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', maxHeight: "250px", overflowY: 'auto' }}>

                                            {resourcesJson && resourcesJson.sort((a: IUploadInfo, b: IUploadInfo) => {
                                                if (a.title > b.title) { return 1; }
                                                else if (a.title < b.title) { return -1; }
                                                else { return 0; }
                                            }).map((resource: IUploadInfo) => (
                                                <Card key={resource.link} size="small" style={{ width: 200, margin: '2px' }} onClick={e => {
                                                    e.preventDefault();
                                                    if (selected === resource) {
                                                        setSelected(null);
                                                        setAllValues(defaultUploadInfo);
                                                    } else {
                                                        setAllValues(resource);
                                                        setSelected(resource);
                                                        setDuplicateLink("")
                                                    }
                                                }} selected={resource === selected}>
                                                    <CardHeader image={<LinkRegular />}
                                                        header={<Text className={generalStyles.cardTitle}>{resource.title}</Text>}
                                                        description={<Text className={generalStyles.caption}>{resource.link}</Text>}
                                                    />
                                                    <CardFooter>
                                                        <Button icon={<DeleteRegular />} aria-label="delete"
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                deleteItem(resource);
                                                            }}>
                                                        </Button>
                                                    </CardFooter>
                                                </Card>
                                            ))}

                                        </div>
                                    </div>
                                </Card>
                            )}

                            <div style={{ display: 'flex', flexDirection: 'column' }}>

                                {/* <Collapse in={successfulAdd}><Alert severity="success" sx={{ marginTop: 1 }}><AlertTitle>Added</AlertTitle>Your resource has been successfully added!</Alert></Collapse>
                            <Collapse in={successfulEdit}><Alert severity="success" sx={{ marginTop: 1 }}><AlertTitle>Updated</AlertTitle>Your resource has been successfully updated!</Alert></Collapse>
                            <Collapse in={successfulDelete}><Alert severity="success" sx={{ marginTop: 1 }}><AlertTitle>Deleted</AlertTitle>Your resource has been successfully deleted!</Alert></Collapse> */}

                                <Controller name="id"
                                    control={control}
                                    render={({ field }) =>
                                        <>
                                            {field.value === undefined || field.value === "" ?
                                                <Alert intent="info" style={{ marginTop: '10px' }}>Enter information for a new resource below.</Alert>
                                                :
                                                <>
                                                    <Alert intent="warning" style={{ marginTop: '10px' }}>Currently editing selected upload.</Alert>
                                                    <Button
                                                        style={{ margin: '10px', alignSelf: 'flex-start' }}
                                                        onClick={e => { e.preventDefault(); setAllValues(defaultUploadInfo); setSelected(null); }}
                                                        {...field}

                                                    >Clear Selection</Button>
                                                </>
                                            }</>
                                    }

                                />

                                <InfoLabel info="Once you have created and saved a link, you can no longer edit it.  If you wish to edit it, please delete it and create a new one.">
                                    <Label required htmlFor={linkId}>Link</Label>
                                </InfoLabel>
                                <Controller name="link"
                                    control={control}
                                    rules={{
                                        required: true
                                    }}
                                    render={({ field }) =>
                                        <Input
                                            {...field}
                                            disabled={selected !== null}
                                            id={linkId}
                                            required
                                        />

                                    }
                                />
                                {duplicateLink !== "" ?? (
                                    <Text>{duplicateLink !== "" ? duplicateLink : selected ? "Can't edit link.  Please delete and re-add." : ""}</Text>
                                )}
                                <Card style={{ padding: '20px', margin: '10px' }}>
                                    <Text>
                                        If you only have a file, please <Link href="https://drive.google.com/drive/folders/1sT3jUod5k3_Kf1RYW6_7YRF5xOugGLgv?usp=sharing" target="blank">
                                            upload it to our google drive folder</Link> and select "Share", then "Copy Link".  Paste that link to the box above.
                                    </Text>
                                </Card>


                                <Label required htmlFor={titleId}>Title</Label>
                                <Controller name="title"
                                    control={control}
                                    rules={{
                                        required: true
                                    }}
                                    render={({ field }) =>
                                        <Input
                                            {...field}
                                            id={titleId}
                                            required
                                        />
                                    }
                                />

                                <Label htmlFor={descriptionId}>Description</Label>
                                <Controller name="description"
                                    control={control}
                                    render={({ field }) =>
                                        <Textarea id={descriptionId}

                                            {...field}
                                        />
                                    }
                                />


                                <Label id={categoryId}>Category</Label>
                                <Controller
                                    name="category"
                                    control={control}
                                    rules={{
                                        required: true
                                    }}
                                    render={({ field }) =>
                                        <RadioGroup {...field} aria-labelledby={categoryId}>
                                            {categoryItems.filter((item) => item.key !== 'all').map((item) => (
                                                <Radio value={item.key} label={item.name} />
                                            ))}
                                        </RadioGroup>
                                    }
                                />



                                <Label htmlFor={topicId}>Topics:</Label>

                                <div id={topicId} style={{ display: 'flex', flexDirection: 'column' }}>
                                    {topicItems.filter((item) => item.key !== 'all').map((item) => (
                                        <Controller name={item.key as any} key={item.key}
                                            control={control}
                                            render={({ field: { onChange, value } }) =>
                                                <Checkbox checked={value === 1} onChange={e => { onChange(e.target.checked ? 1 : 0); }} label={item.name} />
                                            }



                                        />
                                    ))}

                                </div>

                                {/* <FormLabel sx={{ marginTop: 3 }} id="topic-radio-buttons-group">Topic</FormLabel>
                            <FormGroup aria-labelledby="topic-radio-buttons-group">

                                {topicItems.filter((item) => item.key !== 'all').map((item) => (
                                    <Controller name={item.key as any} key={item.key}
                                        control={control}
                                        render={({ field }) =>

                                            <FormControlLabel
                                                value={item.key}
                                                control={<Checkbox checked={field.value === 1}
                                                    value={item.key}
                                                    onChange={e => { field.onChange(e.target.checked ? 1 : 0); }} />}
                                                label={item.name}
                                            />
                                        }
                                    />
                                ))}
                            </FormGroup> */}


                                <Label htmlFor={copyrightId}>Does NOT contain:</Label>

                                <div id={copyrightId} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Controller name="copyright"
                                        control={control}
                                        rules={{
                                            required: true
                                        }}
                                        render={({ field: { onChange, value } }) =>
                                            <Checkbox checked={value} onChange={onChange} required label='Copyright' />
                                        }
                                    />
                                    <Controller name="advertisement"
                                        control={control}
                                        rules={{
                                            required: true
                                        }}
                                        render={({ field: { onChange, value } }) =>
                                            <Checkbox checked={value} onChange={onChange} required label='Advertisement' />
                                        }
                                    />

                                </div>

                                <Button appearance="primary" aria-label="add" type='submit'
                                    icon={<AddRegular />}
                                    size="large"
                                    onClick={e => {
                                        //e.preventDefault();
                                        //handleSubmit(onSubmit);
                                    }}>
                                    {selected ? "Update" : "Add"} Resource
                                </Button>
                            </div>
                        </form>

                    </div>
                    <div style={{ position: 'fixed', width: '100vw', height: '100vh', top: 0, left: 0, right: 0, bottom: 0, background: '#00000044', zIndex: 99999, display: (isSubmitting ? 'block' : 'none') }}>
                        <Spinner style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }} />
                    </div>

                    <Toaster toasterId={toasterId} />
                </>
                :
                <>
                    <Text >You are not authorized to view this page.</Text>
                </>
            }
        </>
    );

};

export default UploadResource;
